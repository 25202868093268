import { toJS } from 'mobx';

const minusArr = (array1 = [], array2 = []) => {
  const array2Obj: any = {};
  array2.forEach(value => {
    array2Obj[value] = true;
  });
  const diffArray = [];
  array1.forEach(value => {
    if (!array2Obj[value]) {
      diffArray.push(value);
    }
  });
  return diffArray;
};

const arrayEqual = (arr1: any[], arr2: any[]) =>
  arr1.length === arr2.length && arr1.every((item1, index) => item1 === arr2[index]);

const syncValuesThunk = (store, prevFormData, currFormData) => rule => {
  const { from, to } = rule;
  if (!from || !to) {
    return;
  }
  let addValues = [];
  let deleteValues = [];
  from.forEach(name => {
    addValues = [...addValues, ...minusArr(currFormData[name], prevFormData[name])];
    deleteValues = [...deleteValues, ...minusArr(prevFormData[name], currFormData[name])];
  });
  to.forEach(name => {
    const formArray = toJS(currFormData[name] || []);
    const targetArray = formArray.slice();
    if (!targetArray) {
      return;
    }
    addValues.forEach(value => {
      if (!targetArray.includes(value)) {
        targetArray.push(value);
      }
    });
    deleteValues.forEach(value => {
      const index = targetArray.indexOf(value);
      if (index > -1) {
        targetArray.splice(index, 1);
      }
    });
    if (!arrayEqual(formArray, targetArray)) {
      store.changeElementData({ values: targetArray, value: targetArray }, name);
    }
  });
};

/**
 *
 * @param {*} config
 */

function ElementValuesSync(config) {
  const { scripts: rules = [] } = config;
  return {
    name: 'ReducerSync',
    init: store => {
      store.pluginfyMethod(store, 'changeElementData');
    },
    changeElementData: async (store, next) => {
      const prevFormData = toJS(store.formData);
      await next();
      const { formData } = store;
      const currFormData = toJS(formData);
      const syncValues = syncValuesThunk(store, prevFormData, currFormData);
      rules.forEach(syncValues);
    },
  };
}

export default ElementValuesSync;
