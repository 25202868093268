import { isObservableArray } from 'mobx';
import { ARRAY, BOOLEAN, OBJECT, STRING, UNDEFINED } from '../constants';
import { TypeElement, TypeSchema } from '../interfaces';
import { replaceObjectToken, hasToken } from './regex';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import get from 'lodash.get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isFunction from 'lodash/isFunction';
import once from 'lodash/once';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

const Tools = {
  set,
  get,
  cloneDeep,
  hasToken,
  isEqual,
  pickBy,
  pick,
  once,
  isFunction,
  copy(targetObject: object, fromObject: object): void {
    if (!(typeof targetObject === 'object') || !(typeof targetObject === 'object')) {
      return;
    }
    Object.keys({ ...targetObject }).forEach(key => {
      if (!(key in fromObject)) {
        delete targetObject[key];
      }
    });
    Object.entries(fromObject).forEach(([key, value]) => {
      targetObject[key] = value;
    });
  },
  isEmpty(value) {
    if (isNumber(value)) {
      return !value;
    }

    return isEmpty(value);
  },
  replaceObjectToken,
  /**
   * 判断数组
   * @param {*} value
   */
  isArray(value) {
    return isObservableArray(value) || Object.prototype.toString.call(value) === ARRAY;
  },
  /**
   * 判断对象
   * @param {*} value
   */
  isObject(value) {
    return Object.prototype.toString.call(value) === OBJECT;
  },
  /**
   * 判断布尔
   * @param {*} value
   */
  isBoolean(value) {
    return Object.prototype.toString.call(value) === BOOLEAN;
  },
  /**
   * 判断undefined
   * @param {*} value
   */
  isUndefined(value) {
    return Object.prototype.toString.call(value) === UNDEFINED;
  },
  /**
   * 判断字符串
   * @param {*} value
   */
  isString(value) {
    return Object.prototype.toString.call(value) === STRING;
  },
  numFormat(value) {
    return value < 10 ? `0${value}` : value;
  },
  getDateTimeString(value) {
    const timestamp = +new Date(value);
    const curDate = new Date(timestamp);
    return `
      ${curDate.getFullYear()}-${Tools.numFormat(curDate.getMonth() + 1)}-${Tools.numFormat(
      curDate.getDate(),
    )} ${Tools.numFormat(curDate.getHours())}:${Tools.numFormat(curDate.getMinutes())}:${Tools.numFormat(
      curDate.getSeconds(),
    )}
    `;
  },
  getDateString(value) {
    const timestamp = +new Date(value);
    const curDate = new Date(timestamp);
    return `${curDate.getFullYear()}-${Tools.numFormat(curDate.getMonth() + 1)}-${Tools.numFormat(curDate.getDate())}`;
  },
  forEachDadaElement(schema: TypeSchema, fn: (typeItem: TypeElement, parentItem: TypeElement[]) => void) {
    if (!schema) {
      return;
    }
    for (const key in schema) {
      if (!schema.hasOwnProperty(key)) {
        continue;
      }
      const childItems = schema[key];
      if (this.isElementItems(key) && childItems && childItems instanceof Array) {
        childItems.forEach(childItem => {
          fn.call(null, childItem, childItems);
          this.forEachDadaElement(childItem, fn);
        });
      }
    }
  },
  isElementItems(name: string): boolean {
    return ['elements', 'modules', 'rightSidebar'].includes(name);
  },
  promiseTimeout: (milliseconds = 0) => new Promise(resolve => setTimeout(resolve, milliseconds)),
};

export default Tools;
