/*
 * @Author: Bob.gusu
 * @Date: 2019-11-20 17:15:58
 * @LastEditors: Bob.gusu
 * @LastEditTime: 2019-11-21 23:15:57
 * @Description:
 */
import { ERROR, ERROR_UNKNOW, LoadingState } from '../../constants/index';
import requestFunc from '../../tools/request';
// import { ActionMap } from '../action';
import { locale } from '../../i18n';

const commonText = locale.dada.common;

interface IActionInterface {
  eventType?: string;
  eventParams?: object;
  content?: string;
}

export const handleResponse = (store, res, isShowError = true) => {
  // 这边SDK定义过程，新扩展出 type: action|actions|page，但是老的结构有些业务自己实现了，兼容一下
  const { success, data = {}, type = '', error } = res;
  if (!success) {
    if (isShowError) {
      store.showMessage({
        ...res,
        type: 'error',
        content: error || commonText.systemError || 'Submit Failed',
        duration: 4000,
        closeable: true,
      });
    }
    return Promise.reject(res);
  } else if (type && type.includes('action')) {
    let actions = [] as IActionInterface[];
    if (type === 'actions') {
      actions = data;
    } else if (type === 'action') {
      actions = [data];
    }
    store.runAction(actions);
  } else if (data && data.action) {
    store.runAction({ eventType: data.action, eventParams: data });
  } else if (Array.isArray(data)) {
    store.runAction({ eventType: 'patchSchema', eventParams: { patchs: data } });
  }
  return res;
};

const sendRequest = (store, params) => {
  store.triggered = true;
  const { eventLoadingTarget, request = params, conditionActions = [] } = params; // 兼容 request 与 request 平铺两种方式调用
  const { data = {}, url, paramStringify = true } = request;
  eventLoadingTarget && store.changeElementDataSafely({ loadingState: LoadingState.runing }, eventLoadingTarget);
  const requestOption = {
    url,
    type: 'json',
    method: 'GET',
    ...request,
    data: store.getSendParams(data, paramStringify),
  };
  return requestFunc(requestOption)
    .then(
      (res = {}) => {
        store.triggered = false;
        eventLoadingTarget && store.changeElementDataSafely({ loadingState: LoadingState.success }, eventLoadingTarget); // close before response action
        return handleResponse(store, res, conditionActions.length === 0);
      },
      ex => {
        console.error(ex);
        const errorMessage = ex && ex.message ? JSON.stringify(ex.message) : ERROR_UNKNOW;
        store.triggered = false;
        store.setGlobalMessage(`Request faild: ${errorMessage}`, ERROR);
        return Promise.reject(ex);
      },
    )
    .finally(() => {
      eventLoadingTarget && store.changeElementDataSafely({ loadingState: LoadingState.failed }, eventLoadingTarget);
    });
};

export default sendRequest;
