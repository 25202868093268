import { compose } from '@alife/intl-util';

const scrollTo = compose(
  requestAnimationFrame,
  top => () => (document.documentElement.scrollTop = top),
);

class Helper {
  scrollTo;
  scrollToTop = () => scrollTo(0);
  scroll = dom => {
    requestAnimationFrame(() => {
      dom.scrollIntoView();
    });
  };
  scrollToElement(selector) {
    requestAnimationFrame(() => {
      const hasError = document.querySelector(selector);
      if (hasError) {
        this.scroll(hasError);
      }
    });
  }
}
const helper = new Helper();
export default helper;
export { Helper };
