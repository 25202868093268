
export function downloadURI(uri, name = '') {
    const link = document.createElement('a');
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function downloadContent(data, type = 'application/json', name = '') {
  let blob = new Blob([data], {type});
  let url = window.URL.createObjectURL(blob);
  downloadURI(url, name);
  window.URL.revokeObjectURL(url);
}
