import { TypePlugin } from '../interfaces';
import LayoutStore from '../stores';
import { set } from '@alife/intl-util';

const subscribe = (obj, key, callback) => {
  const func = obj[key];
  obj[key] = function(...args) {
    func.apply(this, args);
    callback.apply(this, args);
  };
  obj[key].origin = func.origin || func;
};

const unsubscribe = (obj, key) => {
  const func = obj[key];
  obj[key] = func.origin;
};

const parseJSON = val => {
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
};

function PageInfo(): TypePlugin {
  let updateUrlDelay;
  return {
    name: 'PageInfo',
    init: (store: LayoutStore) => {
      const updateUrl = () => {
        const url = new URL(window.location.href);
        const searchData = {};
        url.searchParams.forEach((value, key) => (searchData[key] = parseJSON(value)));
        set(url, 'searchData', searchData);
        store.changeElementData({
          name: '_pageInfo',
          url,
        });
      };
      updateUrlDelay = () => setTimeout(updateUrl, 0);
      subscribe(history, 'pushState', updateUrlDelay);
      subscribe(history, 'replaceState', updateUrlDelay);
      subscribe(history, 'back', updateUrlDelay);
      // subscribe(history, 'go', updateUrlDelay);
      window.addEventListener('hashchange', updateUrlDelay, false);
      updateUrl();
    },
    componentWillUnmount: store => {
      unsubscribe(history, 'pushState');
      unsubscribe(history, 'replaceState');
      unsubscribe(history, 'back');
      // unsubscribe(history, 'go');
      window.removeEventListener('hashchange', updateUrlDelay);
    },
  };
}

export default PageInfo;
