import LayoutStore from 'src/stores';
import { DadaEventTypes } from '../../stores/dada-event';
import { Validator } from './validator';
import { get } from '@alife/intl-util';
import spreadOutValidateComps from '../../tools/spreadOutComps';

const ActionList = ['validateAll', 'submit'];

/**
 * Validator Plugin
 *  {
 *    "type": "validator",
 *    "scripts": [
 *      {
 *        "name": "email",
 *        "regex": "email",
 *        "error": "邮箱格式错误"
 *      },
 *      {
 *        "name": "postcode",
 *        "rule": "^\\d{6}$",
 *        "error": "邮编格式错误"
 *      },
 *    ]
 *  }
 *
 */
function ValidatorPlugin(config) {
  let validator: Validator;
  return {
    name: 'Validator',
    init(store: LayoutStore) {
      store.pluginfyMethod(store, 'runAction');
      validator = new Validator(store, config);
      store.eventObservable.subscribe('store', DadaEventTypes.onBlur, {
        listener: dadaEvt => {
          const itemData = store.getElementData(dadaEvt.targetName);
          validator.validateSingleField(itemData);
        },
      });
    },
    runAction(store, next, { args }) {
      const [actions] = args;
      const actionData = Array.isArray(actions) ? actions[0] : actions;
      if (actionData && ActionList.includes(actionData.eventType)) {
        const validateCompNames = get(actionData, 'eventParams.validateCompNames', []);

        if (Array.isArray(validateCompNames) && validateCompNames.length > 0) {
          const validateErrors = validator.validateCompsFields(validateCompNames);

          if (!validateErrors || validateErrors.errorCount === 0) {
            return next();
          }

          const validateComps = spreadOutValidateComps(store.elementDataObj, validateCompNames);

          store.checkRequired(validateComps);

          return;
        }

        const errors = validator.validateAllFields();
        if (!errors || errors.errorCount === 0) {
          return next();
        }
        store.checkRequired(); // 检测到校验错误后，继续进行必填校验
      } else {
        return next();
      }
    },
  };
}

export default ValidatorPlugin;
