import ConvertData from './convert-data';
import { checkAndChangeNodeRecusiveThunk, FAILED_SYMBOL } from './util';

const convertNumber = value => {
  const tokens = value.match(/convertNumber\:(.+)/);
  if (!tokens || !tokens[1]) {
    return FAILED_SYMBOL;
  }
  try {
    return parseInt(tokens[1], 10);
  } catch (e) {
    console.error('Convert Number', e);
    return FAILED_SYMBOL;
  }
};

export default function ConvertNumberPlugin(config) {
  const func = checkAndChangeNodeRecusiveThunk(convertNumber, config.keyMap);
  const plugin = ConvertData(config, func);
  return plugin;
}
