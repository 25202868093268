/**
 * 监听元素是否可见的抽象类
 */
abstract class AVisibleObserver {
  /**
   * 监听元素的 DOM ID
   */
  protected targetDom: HTMLElement;

  /**
   * 可见范围根节点 DOM ID
   */
  protected rootDom: HTMLElement;

  /**
   * Active 变化回调
   */
  protected onActiveChange: (active?: boolean) => void;

  constructor(targetDom: HTMLElement, rootDom: HTMLElement, onActiveChange: (active?: boolean) => void) {
    this.targetDom = targetDom;
    this.rootDom = rootDom;
    this.onActiveChange = onActiveChange;
  }

  /**
   * 开始监听
   */
  abstract observe(): void;

  /**
   * 取消监听
   */
  abstract unobserve(): void;
}

class IntersectionVisibleObserver extends AVisibleObserver {
  /**
   * IntersectionObserver 实例
   */
  private intersectionObserver: IntersectionObserver;

  constructor(targetDom: HTMLElement, rootDom: HTMLElement, onActiveChange: (active: boolean) => void) {
    super(targetDom, rootDom, onActiveChange);
    if (!IntersectionObserver) {
      return;
    }
    this.intersectionObserver = new IntersectionObserver(changes => {
      const entry = changes[0];
      if (entry.intersectionRatio > 0) {
        onActiveChange(true);
      } else {
        onActiveChange(false);

        // 因为虚拟 dom 更新导致实际 dom 更新，也会在此触发，判断 dom 丢失则重新监听
        if (!document.body.contains(entry.target)) {
          this.intersectionObserver.unobserve(entry.target);
          this.intersectionObserver.observe(targetDom);
        }
      }
    });
  }

  observe() {
    if (this.targetDom) {
      this.intersectionObserver.observe(this.targetDom);
    }
  }

  unobserve() {
    this.intersectionObserver.disconnect();
  }
}

export { AVisibleObserver, IntersectionVisibleObserver };
