import { Store } from 'src/interfaces';
import { DadaEvent } from '../../interfaces/eventReaction';
import { DadaEventTypes } from '../../stores/dada-event';
// import { CONST_IOC } from '../../constants/ioc';
// import { inject } from '../../stores/container';

class StoreAction {
  name = 'storeAction';

  store: Store;

  init(store: Store) {
    this.store = store;
    this.store.eventObservable.subscribe('store', DadaEventTypes.onClick, {
      listener: this.handleOnClick,
    });
    this.store.eventObservable.subscribe('store', DadaEventTypes.onChange, {
      listener: this.handleOnChange,
    });
    this.store.eventObservable.subscribe('store', DadaEventTypes.changeElementData, {
      listener: this.handleChangeElementData,
    });
  }

  handleOnClick = (e: DadaEvent) => {
    const { itemData } = e;
    if (!itemData) {
      return;
    }
    this.store.onClick(itemData);
  };

  handleOnChange = async (e: DadaEvent, newValue) => {
    const { itemData } = e;
    if (!itemData) {
      return;
    }
    const { value: oldValue, actions, eventType } = itemData!;
    if (oldValue === newValue) {
      return;
    }
    try {
      await this.store.changeElementData({ value: newValue }, itemData!.componentKey, false);

      this.store.runAction((eventType && !actions && itemData) || actions);
    } catch (e) {
      if (e.message === 'UserCancel') {
        return;
      }
      throw e;
    }
  };

  handleChangeElementData = (e: DadaEvent, newData, componentKey) => {
    const { itemData } = e;
    if (!itemData) {
      return;
    }
    this.store.changeElementData(newData, componentKey || itemData!.componentKey);
  };
}

function storeActionPlugin() {
  return new StoreAction();
}

export default storeActionPlugin;
