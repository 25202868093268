import BaseLog from './BaseLog';
// @ts-ignore
import packages from '../../../package.json';

export default class CoreLog extends BaseLog {
  protected static styles = {
    mainColor: '#000000',
    backgroundColor: '#ffa21d',
  };
  protected static logType = 'Dada Render Version';

  static version() {
    this.log(packages.version);
  }
}
