import { attachScrollbar } from '@alife/intl-util';

/**
 * @param config
 */
function Scrollbar(config: any = {}) {
  const scrollbarHandlers: any = [];
  const selectors = config.selectors || [];
  return {
    name: 'Scrollbar',
    init: store => {
      store.pluginfyMethod(store, 'afterInit');
    },
    afterInit: (store, next) => {
      // attachScrollbar({
      //   scrollContentDom: document.body,
      //   direction: 'column',
      //   inside: true,
      //   mutationObserver: true,
      // });

      selectors.forEach(selector => {
        const scrollbarHandler = attachScrollbar({
          scrollContentDom: document.querySelector(selector),
          direction: 'column',
          mutationObserver: true,
        });
        scrollbarHandlers.push(scrollbarHandler);
      });
      return next();
    },
  };
}
export default Scrollbar;
