import React, { Component } from 'react';
import { IntersectionVisibleObserver } from './visible-observe';
import { logEvent, runBeforeLeave, cancelBeforeLeave } from '../tools/loginfo';
import { EventType } from '../constants/event';
import { classificationExpTime } from '../tools/time';
// import { observer } from 'mobx-react';

export interface ExposureHocState {
  visible: boolean;
}

// @observer
class ExposureHoc extends Component<any, ExposureHocState> {
  observer: IntersectionVisibleObserver;
  visibleLastTime: number = 0;
  visibleTotalTime: number = 0;
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { itemData, checkViewPort } = nextProps;
    if (!checkViewPort) {
      return true;
    }
    const propsVisible = itemData.visible;
    if (propsVisible && !this.props.itemData.visible) {
      return true;
    }
    const { visible } = nextState;
    const shouldUpdate = this.observer ? visible : this.props !== nextProps;
    return shouldUpdate;
  }
  componentDidMount() {
    try {
      const { componentKey, itemData } = this.props;
      const { checkViewPort } = itemData;
      const ele = document.getElementById(componentKey) || document.querySelector(`.${componentKey}`);
      if (!checkViewPort || !ele) {
        return;
      }
      if (!IntersectionVisibleObserver) {
        // for ie
        this.setState({ visible: true });
        return;
      }
      this.observer = new IntersectionVisibleObserver(ele, document.body, this.onActiveChange);
      this.observer.observe();
      runBeforeLeave(componentKey, this.logExpTime);
    } catch (err) {
      console.error(err);
    }
  }
  componentWillUnmount() {
    if (this.observer) {
      this.observer.unobserve();
    }
    cancelBeforeLeave(this.props.componentKey);
    this.logExpTime();
  }
  onActiveChange = isActive => {
    const { visible } = this.state;
    if (isActive === visible) {
      return;
    }
    this.setState({ visible: isActive });
    if (isActive) {
      // 移动游标
      this.visibleLastTime = Date.now();
    } else if (this.visibleLastTime > 0) {
      // 清算当前曝光时间
      this.updateExpTime();
    }
  };
  updateExpTime = () => {
    if (this.visibleLastTime > 0) {
      this.visibleTotalTime = this.visibleTotalTime + Date.now() - this.visibleLastTime;
    }
  };
  logExpTime = () => {
    if (this.state.visible) {
      // 补齐当前曝光时间
      this.updateExpTime();
    }
    if (this.visibleTotalTime < 1) {
      return;
    }
    const { componentKey } = this.props;
    logEvent(
      {
        eventTarget: componentKey,
        eventType: EventType.onExposure,
        c1: (this.visibleTotalTime / 1000).toFixed(2),
        c2: classificationExpTime(this.visibleTotalTime),
        extra: {
          k: 's',
          arms: this.props.arms,
        },
      },
      true,
    );
  };
  render() {
    const { TargetComp, ...restProps } = this.props;
    const { visible } = this.state;
    return <TargetComp {...restProps} inViewPort={visible} />;
  }
}

const wrapInView = TargetComp => {
  if (window.IntersectionObserver) {
    const ExposureHocWrapper = props => <ExposureHoc {...props} TargetComp={TargetComp} />;
    return ExposureHocWrapper;
  } else {
    return TargetComp;
  }
};
export default wrapInView;
