/**
 * ref from:
 * https://github.com/koajs/compose/blob/master/index.js
 */
import { pipe } from '@alife/intl-util';
import { Store } from 'src/interfaces';
// tslint:disable-next-line: no-empty
const emptyFunc = () => {};

export function isPromise(obj) {
  return obj && 'function' === typeof obj.then;
}

function composeMiddlewares(middleware) {
  return (store, option, hookPromise) => {
    let index = -1;
    return dispatch(0);
    function dispatch(i, ...others) {
      if (i === middleware.length) {
        const result = hookPromise(...others);
        return result;
      }
      if (i <= index) {
        return Promise.reject(new Error('next() called multiple times'));
      }
      index = i;
      const fn = middleware[i];
      if (!fn) {
        return dispatch(i + 1, ...others);
      }
      try {
        return fn(store, dispatch.bind(null, i + 1, ...others), option);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  };
}

const pluginfyMethod = (store: Store, methodName: string) => {
  const cacheMethod = store[methodName] || emptyFunc; // offer empty function for new command
  if (cacheMethod.pluginfyed) {
    return true;
  } // do nothing for method already pluginfyed before
  const getMiddlewares = pipe(
    plugins => plugins.filter(plugin => plugin[methodName] && typeof plugin[methodName] === 'function'),
    plugins => plugins.map(plugin => plugin[methodName].bind(plugin)),
  );
  store[methodName] = new Proxy(cacheMethod, {
    apply: (target, thisBindings, args) => {
      const middlewares = getMiddlewares(store.plugins);
      const dispatch = composeMiddlewares(middlewares);
      return dispatch(store, { args: [...args] }, target.bind(thisBindings, ...args));
    },
  });
  store[methodName].pluginfyed = true;
  return Promise.resolve(methodName);
};

const Pluginfy = () => ({
  name: 'Pluginfy',
  init(store: Store) {
    // @ts-ignore
    if (store.pluginfyMethod) {
      throw new Error('pluginfy failed, because pluginfyMethod is occupied');
    }
    store.pluginfyMethod = pluginfyMethod;
  },
});

export default Pluginfy;
