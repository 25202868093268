import { DadaEvent, DadaEventTypes, nextLocale, Request } from '@alife/dada-template-core';
import { generateUid, clearRequestFilter, clearResponseFilter } from '@alife/intl-util';
import { ConfigProvider } from '@alife/next';
import GlobalCompMap from 'dada-components';
import { toJS } from 'mobx';
import { Provider } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import wrapAdapter from './adapters';
import './index.scss';
import Layout from './layout/index';
import { buildinPlugins } from './plugins';
import * as stores from './stores';
import './styles/global.scss';

console.info('wlbwlbwlb');
const a = {wlbwlbwlb: 'wlbwlbwlb', a: 'wqqw', b: 'wq', c: `wqqwqw`};
const b = {...a, wlbwlbwlb: 'wlb1wlb1wlb1'};
console.info('wlb1wlb1wlb1', b);
class DadaRenderForm extends React.Component {
  static propTypes = {
    breadcrumb: PropTypes.any,
    pageName: PropTypes.any,
    formData: PropTypes.object,
    labelAlign: PropTypes.string,
    plugins: PropTypes.array,
    modules: PropTypes.array,
    success: PropTypes.bool,
    error: PropTypes.any,
    status: PropTypes.number,
    redirectUrl: PropTypes.string,
    componentMap: PropTypes.any,

    onChange: PropTypes.func,
    onRef: PropTypes.func,
    request: PropTypes.any,
    onInitStore: PropTypes.func,
    hotload: PropTypes.boolean,
  };

  static defaultProps = {
    breadcrumb: [],
    pageName: '',
    formData: {},
    labelAlign: 'left',
    plugins: [],

    onChange: null,
  };

  constructor(props) {
    super(props);
    // this.componentHandler.setOnError(onSchemaError);
    this.layoutStore = new stores.LayoutStore(props);
    this.layoutStore.appId = `form-${generateUid()}`;
    this.layoutStore.registerPlugins(buildinPlugins);
    this.layoutStore.registerWrapter(wrapAdapter);
    this.layoutStore.initData(props); // should run after default plugins
    this.layoutStore.registerPlugins(this.layoutStore.schema.plugins);
    this.layoutStore.registerComponentMap(props.componentMap);
    this.props.onInitStore && this.props.onInitStore(this.layoutStore);
    this.layoutStore.eventObservable.dispatch(new DadaEvent(this.layoutStore, 'store', DadaEventTypes.onWillMount));
  }

  componentDidMount() {
    const { onRef, request: option, hotload } = this.props;
    if (onRef) {
      onRef(this);
    }
    if (option && hotload) {
      this.layoutStore.reload();
    }
    this.layoutStore.afterInit();
    this.layoutStore.eventObservable.dispatch(new DadaEvent(this.layoutStore, 'store', DadaEventTypes.onDidMount));
  }

  componentWillUnmount() {
    // spa的场景下需要去掉requestFiler
    clearRequestFilter();
    clearResponseFilter();
  }

  checkFormRequired = () => {
    return this.layoutStore.checkRequired();
  };

  getSubmitFormData = () => {
    return {
      ...this.props.formData,
      ...toJS(this.layoutStore.getFormData(), { recurseEverything: true }),
    };
  };

  getFormData = () => ({
    ...this.props.formData,
    ...toJS(this.layoutStore.formData, { recurseEverything: true }),
  });

  cleanFormData = () => this.layoutStore.cleanFormData();

  render() {
    const { pageName = {}, breadcrumb = [], componentMap } = this.props;
    const BreadcrumbComp = GlobalCompMap ? GlobalCompMap.Breadcrumb : componentMap.Breadcrumb;
    const PagenameComp = GlobalCompMap ? GlobalCompMap.Pagename : componentMap.Pagename;
    return (
      <Provider layoutStore={this.layoutStore}>
        <ConfigProvider locale={nextLocale}>
          <div className="dada-form" id={this.layoutStore.appId}>
            {breadcrumb && breadcrumb.length && BreadcrumbComp ? <BreadcrumbComp data={breadcrumb} /> : null}
            {pageName.label && PagenameComp ? (
              <PagenameComp
                {...pageName}
                onClick={this.layoutStore.onClick}
                renderComponent={this.layoutStore.renderComponent}
              />
            ) : null}
            <Layout layoutStore={this.layoutStore} schema={this.layoutStore.schema} />
          </div>
        </ConfigProvider>
      </Provider>
    );
  }
}
if (!window.DadaForm) {
  console.info('beforeForm');
  window.DadaForm = DadaRenderForm;
  console.info('afterForm');
  Object.defineProperty(window, 'DadaForm', {
    writable: false,
  });
}
export default DadaRenderForm;
