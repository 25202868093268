export default class BaseLog {
  static get template() {
    return [
      // obj,
      ` %c %c %c ${this.logType} %c %c %c`,
      `background: ${this.styles.mainColor}; padding:5px 0;`,
      `background: ${this.styles.mainColor}; padding:5px 0;`,
      `color: ${this.styles.mainColor}; background: ${this.styles.backgroundColor}; padding:5px 0;`,
      `background: ${this.styles.mainColor}; padding:5px 0;`,
      `background: ${this.styles.mainColor}; padding:5px 0;`,
      `background: ${this.styles.mainColor}; padding:5px 0;`,
    ];
  }
  protected static styles = {
    mainColor: '#ff6900',
    backgroundColor: '#030307',
  };
  protected static logType = '';

  static log(...args) {
    console.warn.apply(console, [...this.template, ...args]);
  }
}
