import { IRunTimeAction } from '../interfaces';
import { ISandBoxCodeBlock } from '@alife/intl-util';

interface IActionRegistry {
  actions: Array<{
    actionName?: string;
    actionType: string;
    actionFunc: IRunTimeAction | ISandBoxCodeBlock;
  }>;
}

/**
 * action registry
 * @param config
 */
function CustomActions(config: IActionRegistry) {
  const { actions } = config;
  let layoutStore;
  let actionMap = {};
  return {
    name: 'action-registry',
    init(store) {
      if (!store.actionMap) {
        console.warn('Not found ActionMap');
        return;
      }

      layoutStore = store;
      actionMap = actions.reduce((prev, value) => {
        const { actionType, actionFunc } = value;
        let fn: IRunTimeAction = actionFunc as any;

        fn = store.sandBox(actionFunc);

        // 过滤真实Store，不允许外部定义Action操作真实Store
        const finalAction = function(realStore, ...args) {
          return fn.apply(this, args);
        };

        return Object.assign(prev, {
          [actionType]: finalAction,
        });
      }, {});

      Object.assign(store.actionMap, actionMap);
    },
    destroy() {
      Object.keys(actionMap).forEach(key => delete layoutStore.actionMap[key]);
    },
  };
}
export default CustomActions;
