import { toJS, isObservableArray, isObservable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';
import { ARRAY, OBJECT, BOOLEAN, UNDEFINED, STRING } from '../constants';

const Tools = {
  /**
   * url获取参数
   * @param {*} name
   * @param {*} url
   */
  getQueryParams(url = location.search) {
    const vars = {};
    let hash;
    const hashes = url.substr(1).split('&');
    for (let i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars[hash[0]] = hash[1];
    }
    return vars;
  },
  getQueryString(name, url = location.search) {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    const r = url.substr(1).match(reg);
    if (r) {
      return unescape(r[2]);
    }
    const { GLOBAL = {} } = window;
    if (GLOBAL[name]) {
      return GLOBAL[name];
    }
    return '';
  },
  /**
   * 数组深拷贝
   * @param {*} value
   */
  copyArray(value = []) {
    return value.slice(0);
  },
  /**
   * 对象深拷贝
   * @param {*} value
   */
  copyObject(value) {
    const deepObj = toJS(value);
    return cloneDeep(deepObj);
  },
  /**
   * 判断数组
   * @param {*} value
   */
  isArray(value) {
    return isObservableArray(value) || Object.prototype.toString.call(value) === ARRAY;
  },
  /**
   * 判断对象
   * @param {*} value
   */
  isObject(value) {
    return Object.prototype.toString.call(value) === OBJECT;
  },
  /**
   * 判断布尔
   * @param {*} value
   */
  isBoolean(value) {
    return Object.prototype.toString.call(value) === BOOLEAN;
  },
  /**
   * 判断undefined
   * @param {*} value
   */
  isUndefined(value) {
    return Object.prototype.toString.call(value) === UNDEFINED;
  },
  /**
   * 判断字符串
   * @param {*} value
   */
  isString(value) {
    return Object.prototype.toString.call(value) === STRING;
  },
  /**
   * 设置请求头
   */
  setUploadHeader() {
    const uploadHeader = {};
    const { csrfToken = {} } = window;
    const { tokenName = '', tokenValue = '' } = csrfToken;
    window.csrfToken && (uploadHeader[tokenName] = tokenValue);
    return uploadHeader;
  },
  /**
   * options处理，兼容后端返回数据的差异
   * @param {*} value
   */
  getOptions(value) {
    let options = [];
    if (value && value.length) {
      value.forEach(oItem => {
        if (oItem) {
          options.push({
            label: oItem.label,
            subLabel: oItem.subLabel,
            value: oItem.value,
            disabled: oItem.disabled,
          });
        }
      });
    }
    return options;
  },
  /**
   * 获取多种数据格式情况下，最底层的value
   * @param {*} data
   */
  getValue(data) {
    return Tools.isObject(data) ? data.value : data;
  },
  /**
   * 获取多种数据格式情况下，最底层的label
   * @param {*} data
   */
  getLabel(data) {
    return data ? (Tools.isObject(data) ? data.label : data) : '';
  },
  /**
   * 获取对象的 key
   * @param {*} value
   */
  getObjectKeys(value) {
    return Object.keys(value);
  },
  numFormat(value) {
    return value < 10 ? `0${value}` : value;
  },
  getDateTimeString(value) {
    const timestamp = +new Date(value);
    const curDate = new Date(timestamp);
    return `${curDate.getFullYear()}-${Tools.numFormat(curDate.getMonth() + 1)}-${Tools.numFormat(
      curDate.getDate(),
    )} ${Tools.numFormat(curDate.getHours())}:${Tools.numFormat(curDate.getMinutes())}:${Tools.numFormat(
      curDate.getSeconds(),
    )}`;
  },
  getDateString(value) {
    const timestamp = +new Date(value);
    const curDate = new Date(timestamp);
    return `${curDate.getFullYear()}-${Tools.numFormat(curDate.getMonth() + 1)}-${Tools.numFormat(curDate.getDate())}`;
  },
  toJSData(data) {
    if (isObservable(data)) {
      return toJS(data);
    }

    if (this.isArray(data)) {
      return data.map(item => {
        return this.toJSData(item);
      });
    } else if (this.isObject(data)) {
      const result = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          result[key] = isObservable(data[key]) ? toJS(data[key]) : data[key];
        }
      }
      return result;
    } else {
      return data;
    }
  },
  isEmpty(value) {
    return value === undefined || value === null;
  },
  camelToLowerCase(str = '', separator = '-') {
    str = str.replace(/([A-Z])/g, $1 => separator + $1.toLocaleLowerCase());
    return str[0] === separator ? str.substr(1) : str;
  },
};

export default Tools;
