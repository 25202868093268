import React from 'react';
import { locale, nextLocale } from '../i18n/index';

const mergedLocale = { ...locale, ...nextLocale };

const wrapLocale = TargetComponent => props => {
  const { locale: propsLocale = {} } = props || {};
  return (
    <TargetComponent
      {...props}
      locale={{
        ...mergedLocale,
        ...propsLocale,
      }}
    />
  );
};

export default wrapLocale;
