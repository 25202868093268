import { RULES } from './rules';
import Tools from '../../tools';

export const getRulesErrorMsg = (value = '', configRule) => {
  const rules = Array.isArray(configRule) ? configRule : [configRule];
  let errorMsg = '';
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  rules.every(rule => {
    let { regex } = rule;
    const { rule: ruleName } = rule;

    if (!regex && !!ruleName && !!RULES[ruleName]) {
      regex = RULES[ruleName];
    }

    if (!regex) {
      console.warn('There is no regex matched!');
      return true;
    }

    errorMsg = new RegExp(regex).test(value) ? '' : rule.error || rule.message;
    return !errorMsg;
  });
  return errorMsg;
};

export function getErrorMessage(itemData, rules) {
  if (!itemData || !Tools.get(itemData, 'visible', true)) {
    return false;
  }
  const value = Array.isArray(itemData.values) ? itemData.values.join(',') : itemData.value;
  if (!rules) {
    return false;
  }
  const errMsg = getRulesErrorMsg(value, rules);
  return errMsg ? errMsg : false;
}
