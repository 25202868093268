import { Button, Timeline, Icon } from '@alife/next';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import './index.scss';
import { downloadContent } from './download';
import { Uploader } from './upload';

const TimelineItem = Timeline.Item;

const Player = (props) => {
  const { timeline } = props;
  const timeLineState = timeline.state;
  const { recording, playing } = timeLineState;
  const exportData = () => {
    downloadContent(JSON.stringify(timeline.toJSON()));
  };
  const uploadData = (value) => {
    timeline.fromJSON(value);
    console.warn(value);
  };
  return (
    <div className="toolbar">
      {recording
        ? <Button onClick={timeline.stopRecord} size="small">stop record</Button>
        : <Button onClick={timeline.startRecord} size="small">record</Button>
      }
      {playing
        ? <Button onClick={timeline.stopPlay} size="small">stop play</Button>
        : <Button onClick={timeline.startPlay} size="small">play</Button>
      }
      <Button onClick={timeline.reset} size="small">reset</Button>
      <Icon type="download" className="clickable" onClick={exportData} />
      <Uploader onChange={uploadData} />
    </div>
  );
};

const PlayerOb = observer(Player);

const scrollToItem = (id) => {

  const ele = document.getElementById(id);
  if (ele) {
    ele.scrollIntoView({
      behavior: 'smooth'
    });
  }
};

const TimelineBlock = (props) => {
  const { timeline, isDisplay, toggleDisplay} = props;

  const { events = [], index } = timeline.state;
  setTimeout(() => scrollToItem(`evt-${index}`), 500);
  return (
    <div className="timeline-events">
      <div className="state-bar">
        <span>events {events.length}</span>
        <span className={`open-${isDisplay} clickable`} onClick={toggleDisplay}>unfold <i className="icon arrow-left">{'<'}</i></span>
      </div>
      {isDisplay &&
        <Timeline className="timeline">
          {events.map((evt, idx) => <TimelineItem
            key={evt.time}
            id={`evt-${idx}`}
            title={evt.operation}
            timeLeft={<span>{idx + 1}<br />at: {evt.time}</span>}
            state={evt.state}
            content={JSON.stringify(evt.payload)}
          />)}
        </Timeline>
      }
    </div>
  );
};

const TimelineBlockOb = observer(TimelineBlock);

const EventTimeline = (props) => {
  const { timeline } = props;
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => setIsDisplay(!isDisplay);
  return (
    <div className="dada-timeline">
      <PlayerOb timeline={timeline} />
      <TimelineBlockOb timeline={timeline} isDisplay={isDisplay} toggleDisplay={toggleDisplay} />
    </div>
  );
};

export default EventTimeline;
