import submitAction from './submit';

const request = (store, itemData): Promise<any> => {
  if (itemData.extData && itemData.extData.content) {
    const { title, content } = itemData.extData;
    return store
      .showMessage({
        needWrapper: false,
        content,
        title,
      })
      .then(() => submitAction(store, itemData));
  } else {
    return submitAction(store, itemData);
  }
};

export default request;
