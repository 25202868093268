

import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { observer } from 'mobx-react';
import Feedback from '../components/feed-back';
import EventTimeline from '../components/timeline';
import './index.scss';


class Container extends Component {
    constructor(props) {
        super(props);
        const { layoutStore, elements } = props;
        this.children = elements.map(layoutStore.renderComponent);
    }
    componentWillReceiveProps(nextProps) {
        const { elements, layoutStore } = nextProps;
        const isSame = elements.every((ele, index) => {
            const exist = this.props.elements[index];
            return exist && ele.componentKey === exist.componentKey;
        });
        if (!isSame) {
            this.children = elements.map(layoutStore.renderComponent);
        }
    }
    render() {
        return (
            <Fragment>
                {this.children}
            </Fragment>
        );
    }
}

Container.propTypes = {
    elements: PropTypes.array,
    layoutStore: PropTypes.object
};

const MainPage = observer(({ layoutStore, schema }) => {
    return (
        <div className="dada-main-page">
            <Feedback msg={layoutStore.globalFeedback || {}} />
            {layoutStore.timeline && <EventTimeline timeline={layoutStore.timeline} />}
            <Container elements={(schema.modules || [])} layoutStore={layoutStore} />
        </div>
    );
});
MainPage.propTypes = {
    layoutStore: PropTypes.object,
    schema: PropTypes.object
};

export default MainPage;
