/* eslint-disable react/prop-types */
import { Balloon, Icon } from '@alife/next';
import classnames from 'classnames';
import React from 'react';
import { observer } from 'mobx-react';

function htmlDecode(input) {
  const e = document.createElement('div');
  e.innerHTML = input;
  return e.innerHTML;
}

const wrapTips = TargetComp => {
  const TipsHoc = props => {
    const { itemData } = props;
    const { tips, tipsPosition = 'r', error } = itemData;
    const decodedTips = tips ? htmlDecode(tips) : '';
    const tipsNode = tips ? (
      <Balloon
          trigger={<Icon type="help" className="dada-help" />}
          closable={false}
          align={tipsPosition}
        >
          <span dangerouslySetInnerHTML={{ __html: decodedTips }} ></span>
      </Balloon>
    ) : null;
    const suffix = error ? (
      <span className="error-message">{error}</span>
    ) : null;
    return (
      <TargetComp
        {...props}
        className={classnames(props.className, {'has-error': error})}
        tipsNode={tipsNode}
        labelSuffix={suffix}
      />
    );
  };
  return observer(TipsHoc);
};
export default wrapTips;
