import { isEmpty } from '@alife/intl-util';

const replace = ({ newValue }) => {
  return newValue;
};
const mergeValues = ({ newValue, currData }) => {
  currData.value = newValue;
  if (currData.values && Array.isArray(newValue)) {
    currData.values = newValue;
  }
  return newValue;
};
const shallowMerge = ({ newValue, currentValue }) => {
  return { ...currentValue, ...newValue };
};
const mergeModules = ({ newValue, currentValue, startComponentKey, mergeNodeRecusive, depth = 0 }) => {
  if (startComponentKey === 'root' && depth < 1 && newValue) {
    return newValue.map(module => mergeNodeRecusive(module, startComponentKey));
  } else {
    return newValue;
  }
};
const mergeActions = ({ newValue, currentValue, startComponentKey, mergeNodeRecusive }) => {
  if (isEmpty(newValue)) return currentValue;
  return newValue.map(actionData => {
    return actionData.uiType || (actionData.type && !actionData.eventType)
      ? mergeNodeRecusive(actionData, startComponentKey)
      : actionData;
  });
};
const customMergeMap = new Map();
customMergeMap
  .set('actions', mergeActions)
  .set('values', mergeValues)
  .set('value', replace)
  .set('option', replace)
  .set('options', replace)
  .set('dataSource', replace)
  .set('columns', replace)
  .set('plugins', replace)
  .set('formData', replace) // should reset
  .set('eventParams', shallowMerge)
  .set('request', shallowMerge)
  .set('page', shallowMerge)
  .set('modules', mergeModules)
  .set('templates', replace)
  .set('template', replace);
export default customMergeMap;
