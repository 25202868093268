function ReplaceLink(config) {
  if (!config || !config.rules) {
    return;
  }
  const rules = config.rules;
  const checkAndReplaceLink = () => {
    const aTags = document.querySelectorAll('a');
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < aTags.length; index++) {
      const element = aTags[index];
      if (typeof element.href !== 'string') {
        return;
      }
      rules.every(rule => {
        const isMatch = element.href.match(rule.match);
        if (!isMatch) {
          return true;
        }
        element.href = element.href.replace(new RegExp(rule.replaceFrom, 'i'), rule.replaceTo);
        return false;
      });
    }
  };
  return {
    name: 'ReplaceLink',
    init: store => {
      const observer = new MutationObserver(checkAndReplaceLink);
      observer.observe(document.body, { childList: true, subtree: true });
      setTimeout(() => {
        checkAndReplaceLink();
      }, 100);
    },
  };
}

export default ReplaceLink;
