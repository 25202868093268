import LayoutStore from '../stores';
import Tools from '../tools';
import { replaceObjectToken } from '../tools/regex';
import { TypePlugin } from '../interfaces';
import { computed } from 'mobx';
import { getChildNames } from './util';
import Url from '@alife/intl-util/lib/pure-functions/urls';

interface ITableLogic {
  tableName: string;
  filterName: string | string[] | false;
  paginationName?: string;
  requestParams: {};
}

const DEFAULT_OPTIONS: ITableLogic = {
  tableName: 'table',
  filterName: '__*__',
  requestParams: {},
};

/**
 * eg.
 * {
 *   type: "tableLogic",
 *   targetName: "table",
 *   searchContainer: "search",
 *   paginationName: "pagination",
 * }
 */
class TableLogic {
  name = 'TableLogic';
  private options: ITableLogic;
  private store: LayoutStore;
  private paginationPlugin: TypePlugin;
  private prevParams: any;

  constructor(options: ITableLogic) {
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
  }

  get tableData() {
    const { tableName } = this.options;
    return this.store.getElementData(tableName);
  }

  init(store) {
    this.store = store;
    store.pluginfyMethod(store, 'changeElementData');
    store.pluginfyMethod(store, 'reloadComponent');
    this.initPagination(store);
  }

  // @Inject to store
  async changeElementData(store, next, { args }) {
    await next();
    const [newData, name] = args;
    if (!newData.hasOwnProperty('value')) {
      return;
    }
    const itemData = store.getElementData(name) || {};
    const { autoSearch } = itemData;
    if (!autoSearch || !this.isInSearchChildren(name)) {
      return;
    }
    this.onSearch();
  }

  // @Inject to store
  async reloadComponent(store, next, { args }) {
    const [itemData, params = {}] = args;
    const isAppend = params.isAppend;
    const fromPagination = params.fromPagination;
    const { componentKey, dataSource = [] } = itemData;
    if (componentKey !== this.options.tableName) {
      return await next();
    }

    const requestParams = this.getTableParams();

    const { current, total, pageSize, ...restProps } = params;
    // overwrite eventParams if exist
    // tslint:disable-next-line:no-unused-expression
    Object.assign(requestParams, restProps);
    Tools.copy(params, requestParams);

    const tableReloadResult = await next();

    this.prevParams = requestParams;

    if (isAppend && fromPagination) {
      const newDataSource = ((tableReloadResult && tableReloadResult.data) || {}).dataSource || [];
      store.changeElementData({ dataSource: dataSource.concat(newDataSource) }, componentKey);
    }
    return tableReloadResult;
  }

  @computed
  private get searchElementNames(): string[] {
    if (this.options.filterName) {
      return ([] as string[]).concat(this.options.filterName).reduce((previousValue, currentValue) => {
        const searchSchema = this.store.elementDataObj[currentValue];
        const names = getChildNames(searchSchema);
        return previousValue.concat(names);
      }, []);
    }

    return [];
  }

  /**
   * judge whether componentKey is child of searchContainer
   * @param componentKey
   * @returns {boolean | any}
   */
  private isInSearchChildren(componentKey: string): boolean {
    // didn't set searchContainer
    if (this.options.filterName === DEFAULT_OPTIONS.filterName) {
      return true;
    }
    if (this.options.filterName === false) {
      return false;
    }
    return this.searchElementNames.includes(componentKey);
  }

  private getTableParams() {
    const { paginationPlugin, options, store, tableData } = this;
    const { tableName, requestParams, paginationName } = options;

    const storeFormData = Tools.pickBy(
      store.getFormData(),
      (val, key) => val !== undefined && this.isInSearchChildren(key),
    );
    delete storeFormData[tableName];
    const paginationFormData = paginationPlugin && paginationPlugin.getPaginationParams(store);
    const sortFormData = tableData.sort ? Tools.pickBy(tableData.sort, val => val !== undefined) : {};
    try {
      const { sort } = JSON.parse(Url.getQueryString(tableName));
      Object.assign(sortFormData, sort, { ...sortFormData });
    } catch (e) {
      // no exception
    }

    const requestFormData = replaceObjectToken({ store, formData: storeFormData })(requestParams);

    const formData = {
      ...storeFormData,
      ...paginationFormData,
      ...requestFormData,
    };

    if (!Tools.isEmpty(sortFormData)) {
      formData.sort = sortFormData;
    }

    if (paginationPlugin && paginationName) {
      delete formData[paginationName];
    }

    const itemData = store.getElementData(tableName) || {};
    const paramsObject = store.getSendParams(formData, itemData?.request?.paramStringify);

    // query params whether change
    const filterInsideParams = Tools.pick(paramsObject, [
      ...Object.keys(storeFormData),
      ...Object.keys(requestFormData),
    ]);

    if (
      this.prevParams &&
      Object.keys(filterInsideParams).length &&
      // search params whether change
      Object.entries(filterInsideParams).some(([key, item]) => !Tools.isEqual(this.prevParams[key], item))
    ) {
      // reset pagination
      paramsObject.current = 1;
    }
    this.prevParams = paramsObject;
    return paramsObject;
  }

  private onSearch() {
    this.store.runAction({
      eventType: 'reload',
      eventTarget: this.options.tableName,
    });
  }

  private initPagination(store) {
    if (!store.getElementData(this.options.paginationName)) {
      return;
    }
    store.registerPlugins([
      {
        type: 'pagination',
        target: this.options.tableName,
        extendParams: () => this.getTableParams(),
        paginationName: this.options.paginationName,
      },
    ]);
    this.paginationPlugin = store.plugins[store.plugins.length - 1];
  }
}

function tableLogic(options: ITableLogic) {
  return new TableLogic(options);
}

export default tableLogic;
