
import PropTypes from 'prop-types';
import { Message } from '@alife/next';

const Feedback = ({msg}) => (
  msg.data && !!msg.data.length ?
  <Message className="global-feedback" {...msg}>
    {msg.data.map((item, index) => <div key={index} dangerouslySetInnerHTML={{__html: item}} />)}
  </Message> : null
);

Feedback.propTypes = {
  msg: PropTypes.object
};

export default Feedback;
