import { Plugins } from '@alife/dada-template-core';
import fixFormDataToValue from './history-adaptors/formdata-adaptors';

function HistoryDataConvert() {
  const historyPluginOpt = Plugins.PluginMap.historyDataConvert();
  historyPluginOpt.rules = [...historyPluginOpt.rules, fixFormDataToValue];

  return historyPluginOpt;
}

export default HistoryDataConvert;
