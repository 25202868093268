import { Store, DadaComponnet } from 'src/interfaces';
import { ISubscribeProps, IAction, CallBackFunction } from 'src/interfaces/eventReaction';
// import { inject } from '../stores/container';
// import { CONST_IOC } from '../constants/ioc';

type ITarget = Store | DadaComponnet | undefined;

class DadaEvent {
  store: Store;

  type: string;

  currentTarget: ITarget;

  targetName: string;

  currentTargetName: string;

  eventType: string;

  stopped: boolean = false;

  done: boolean = false;

  // tslint:disable-next-line: variable-name
  private _listeners: CallBackFunction[] = [];

  // tslint:disable-next-line: variable-name
  private _actions: IAction[] = [];

  params: any[];

  constructor(store: Store, targetName: string, eventType: string, params: any[] = []) {
    this.store = store;
    this.targetName = targetName;
    this.currentTargetName = targetName;
    this.eventType = eventType;
    this.params = params;
  }

  addSubscribe(subscribe: ISubscribeProps) {
    const { listener, actions = [] } = subscribe;
    this._actions = this._actions.concat(actions);
    if (listener) {
      this._listeners.push(listener);
    }
  }

  get target(): ITarget {
    const { targetName } = this;
    if (targetName === 'store') {
      return this.store;
    }
    return this.store.componentMap.get(targetName);
  }

  get itemData(): any {
    const { targetName } = this;
    if (targetName === 'store') {
      return this.store;
    }
    return this.store.schemaNode.getNode(targetName);
  }

  get actions(): IAction[] {
    return this._actions;
  }

  async listener() {
    const { params, _listeners } = this;
    for (const listener of _listeners) {
      await listener(this, ...params);
      if (this.stopped) {
        return;
      }
    }
  }

  stopPropagation() {
    this.stopped = true;
  }

  setCurrentTarget(current: string) {
    if (current === 'store') {
      this.currentTarget = this.store;
    } else {
      this.currentTarget = this.store.componentMap.get(current);
    }
    this.currentTargetName = current;
  }
}

export const DadaEventTypes = {
  onChange: 'onChange',
  onClick: 'onClick',
  onBlur: 'onBlur',
  onFocus: 'onFocus',
  changeElementData: 'changeElementData',
  onComponentError: 'onComponentError',
  onDidMount: 'onDidMount',
  onComponentDidMount: 'onComponentDidMount',
  onWillMount: 'onWillMount',
  recordTimeStart: 'recordTimeStart',
  recordTimeEnd: 'recordTimeEnd',
};

export const DefaultPropsEvents = [
  DadaEventTypes.onClick,
  DadaEventTypes.onChange,
  DadaEventTypes.onBlur,
  DadaEventTypes.onFocus,
  DadaEventTypes.changeElementData,
];

export default DadaEvent;
