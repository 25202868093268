import { logKeys } from '../constants';
import BaseLog from './logs/BaseLog';

export default function catchException(): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
    const method = descriptor.value;
    descriptor.value = function(...args): any {
      try {
        return method.apply(this, args);
      } catch (error) {
        BaseLog.log('Exception', propertyKey);
        window.postMessage(
          {
            event: logKeys.CATCH_EXCEPTION,
            payload: {
              method: propertyKey,
              callStack: error.stack,
            },
          },
          '/',
        );
      }
    };
  };
}

export const wrapCatchException = (wrapFunc: (...args: any) => any, that: any) => (...args) => {
  try {
    return wrapFunc.apply(that, args);
  } catch (error) {
    BaseLog.log('Exception', wrapFunc);
    window.postMessage(
      {
        event: logKeys.CATCH_EXCEPTION,
        payload: {
          method: null,
          callStack: error.stack,
        },
      },
      '/',
    );
  }
};
