export const classificationExpTime = milliamseconds => {
  const minutes = Math.floor(milliamseconds / 60000);
  if (minutes < 30) {
    return `${minutes}~${minutes + 1} mins`;
  } else if (minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    return `${hours < 1 ? 0.5 : hours}~${hours + 1} hours`;
  } else {
    const days = Math.floor(minutes / 1440);
    return `${days}~${days + 1} days`;
  }
};
