import { EventType } from '../constants/event';
import { get } from '@alife/intl-util';
import { classificationExpTime } from './time';
import { NOOP } from '../constants';

export const isDebug = (urlStr = window.location.href) => {
  const url = new URL(urlStr);
  return url.searchParams.get('debug') === 'true';
};

export interface EventLogProps {
  eventType?: string;
  eventTarget?: any;
  actionType?: string;
  actionTarget?: any;
  c1?: any;
  c2?: any;
  c3?: any;
  c4?: any;
  c5?: any;
  c6?: any;
  extra?: any;
}

let isDebugStatic = isDebug();
if (!isDebugStatic && window.parent) {
  // avoid Same-origin policy
  try {
    isDebugStatic = isDebug(window.parent.location.href);
  } catch (e) {
    console.error(e);
  }
}

export const logInfo = isDebugStatic ? console.warn : NOOP;

const doLogEvent = data => {
  const evt = new CustomEvent(EventType.onDadaEvent, {
    detail: data,
  });
  window.dispatchEvent(evt);
};

export const logEvent = (data: EventLogProps, immediatly: boolean = false) => {
  if (immediatly) {
    doLogEvent(data);
  }
  requestAnimationFrame(() => {
    doLogEvent(data);
  });
};

if (isDebugStatic) {
  const warnFunc = get(window, ['console', 'warn']);
  warnFunc('Dada: Debug mode detected, reporting event');
  window.addEventListener(EventType.onDadaEvent, (evt: CustomEvent) => {
    const { detail } = evt;
    warnFunc.call(null, EventType.onDadaEvent, detail);
  });
}

const endCallbackMap = new Map();
const runFactorsBeforeLeave = () => {
  for (const factor of endCallbackMap.values()) {
    try {
      factor.call();
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * 页面关闭时间，设置唯一key，避免重复注册
 * @param id
 * @param func
 */
export const runBeforeLeave = (id, func) => {
  endCallbackMap.set(id, func);
};

const fallbackStartTime = Date.now();

runBeforeLeave('pageStayTime', () => {
  const startTime = get(window, 'performance.timing.navigationStart', fallbackStartTime);
  const duration = Date.now() - startTime;
  const durationLevel = classificationExpTime(duration);
  const eventOption = {
    eventType: EventType.pageStay,
    eventTarget: `page-${window.location.pathname}`,
    c1: (duration / 1000).toFixed(2),
    c2: durationLevel,
    c3: window.location.href,
    extra: {
      k: 'c',
      arms: null,
    },
  };
  logEvent(eventOption, true);
});

export const cancelBeforeLeave = id => endCallbackMap.delete(id);

// window.addEventListener('beforeunload', runFactorsBeforeLeave)
window.onbeforeunload = runFactorsBeforeLeave;
