import ConvertData from './convert-data';
import { checkAndChangeNodeRecusiveThunk, FAILED_SYMBOL } from './util';

const convertFunc = value => {
  const tokens = value.match(/convertFunction\:.+\[(.+)\]/);
  if (!tokens || !tokens[1]) {
    return FAILED_SYMBOL;
  }
  try {
    return new Function(...tokens[1].split(', '));
  } catch (e) {
    console.error('ConvertFunc Failed', tokens[1]);
    return FAILED_SYMBOL;
  }
};

export default function ConvertFuncPlugin(config) {
  const func = checkAndChangeNodeRecusiveThunk(convertFunc, config.keyMap);
  const plugin = ConvertData(config, func);
  return plugin;
}
