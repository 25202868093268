import { replaceToken } from '../tools/regex';
import { cloneJson, get } from '@alife/intl-util';

/**
 * @param {*} formData // nessary form condition check
 */
const isMatchExpression = (prevFormData, formData, elementData, rule) => {
  try {
    const { expressionParams = {} } = rule;
    const expression = replaceToken(expressionParams)(rule.expression);
    const result = eval(expression);
    return result;
  } catch (e) {
    return false;
  }
};

/**
 * detect formData changed for expression var
 * @param prevFormData
 * @param formData
 * @param rule
 */
export const isDataChanged = (prevFormData, formData, rule, tokenKey = 'formData') => {
  const expression = rule.expression;
  const regex = new RegExp(`${tokenKey}\\.((\\w|\\.)+)`, 'g');
  const tokens = expression.match(regex);
  if (!tokens) {
    return false;
  }
  const formDataKeys = [...tokens].map(token => token.replace(`${tokenKey}.`, ''));
  const notChange = formDataKeys.every(formDataKey => {
    const prev = get(prevFormData, formDataKey);
    const curr = get(formData, formDataKey);
    return Array.isArray(prev) ? prev.join() === curr.join() : prev === curr;
  });
  return !notChange;
};

export const isDataChangedThunk = (prevFormData, formData) => rule => isDataChanged(prevFormData, formData, rule);

export const shouldUpdateElementThunk = (prevFormData, formData, prevElementData, elementData) => rule =>
  (isDataChanged(prevFormData, formData, rule, 'formData') ||
    isDataChanged(prevElementData, elementData, rule, 'elementData')) &&
  isMatchExpression(prevFormData, formData, elementData, rule);

const expressResultMap = new Map();
export const createComputedExpression = rule => {
  const expressFn = isMatchExpression.bind({});
  return (preFormData, formData, elementDataObj) => {
    const effectResult = expressFn(preFormData, formData, elementDataObj, rule);
    const isDiff = effectResult !== expressResultMap.get(expressFn);
    if (isDiff) {
      expressResultMap.set(expressFn, effectResult);
    }
    return effectResult && isDiff;
  };
};

export const runMultiExpression = value =>
  value.replace(/(fn\([^;]+\);)/g, token => {
    const matchs = token.match(/fn\(([^;]+)\);/);
    return matchs && matchs[1] ? eval(matchs[1]) : token;
  });

const evalExpression = value => {
  try {
    const express = value.match(/fn:(.+)/);
    return express && express[1] ? eval(express[1]) : value;
  } catch (e) {
    return value;
  }
};

const replaceTokenRecusive = (elementMap, newItemData) => {
  const strategy = {
    string: value => evalExpression(replaceToken(elementMap)(value)),
    object: value => replaceTokenRecusive(elementMap, value),
    function: value => value(elementMap),
    defalut: value => value,
  };
  Object.entries(newItemData).forEach(([key, value]) => {
    const func = strategy[typeof value];
    newItemData[key] = func === undefined ? value : func(value);
  });
  return newItemData;
};

export const updateTargetElementsThunk = (store, elementMap) => rule => {
  const { targetElements = {} } = rule;
  const newNodeMap: any = {};
  const targets = cloneJson(targetElements);
  Object.entries(targets).forEach(([name, itemData]) => {
    const targetItemData = elementMap[name];
    if (!targetItemData) {
      console.warn(`ElementReudcer => Can't find element by name: ${name}`);
      return;
    }
    newNodeMap[name] = replaceTokenRecusive(elementMap, itemData);
  });
  store.changeElementDataWithoutHookBatch(newNodeMap);
};

export const FAILED_SYMBOL = Symbol('ConvertFailed');

export const checkAndChangeNodeRecusiveThunk = (callback, keyMap) => {
  const checkAndChangeNodeRecusive = node => {
    let hasChanged = false;
    if (Array.isArray(node)) {
      node.forEach(item => {
        hasChanged = checkAndChangeNodeRecusive(item) || hasChanged;
      });
    } else if (typeof node === 'object') {
      Object.entries(node).forEach(([key, value]) => {
        if (keyMap && !keyMap[key]) {
          hasChanged = checkAndChangeNodeRecusive(value) || hasChanged;
        } else if (typeof value === 'string') {
          const newValue = callback(value);
          if (newValue !== FAILED_SYMBOL) {
            node[key] = newValue;
          }
          hasChanged = true;
        } else if (typeof value === 'object') {
          hasChanged = checkAndChangeNodeRecusive(value) || hasChanged;
        }
      });
    }
    return hasChanged;
  };
  return checkAndChangeNodeRecusive;
};

export interface IChildElement {
  elements?: IChildElement[];
  componentKey: string;
}

export const getChildNames = (schema: IChildElement) =>
  schema && Array.isArray(schema.elements)
    ? schema.elements.reduce((prev, next) => prev.concat(getChildNames(next)), [schema.componentKey])
    : [schema.componentKey];
