import { IOldDataAdaptor } from './interface';
import { generateUid, cloneJson } from '@alife/intl-util';
import Tools from '../../tools';
import { HistoryLog } from '../../tools/logs/HistroyLog';

type IBefore = {
  type: 'DialogContainer';
  label: string;
  style: string;
} & any;

const fixDialogData: IOldDataAdaptor = schema => {
  Tools.forEachDadaElement(schema, (dataModule: IBefore, dataModules: IBefore[]) => {
    // step 1, fix dialog request
    if (/DialogContainer/i.test(dataModule.type) && !!dataModule.request) {
      const beforeData = cloneJson(dataModule);
      const { request } = dataModule;
      dataModule.page = {
        ...(dataModule.page || {}),
        request,
        hotload: true,
      };

      delete dataModule.request;
      delete dataModule.hotload;

      HistoryLog.compare("DialogContainer option 'request' should be in 'page'", beforeData, dataModule);
    }

    const foundOldDialogs = dataModules.filter(item => item.type === 'DialogContainer' && !!item.label);

    foundOldDialogs.forEach((dialogData, index) => {
      if (dialogData.label && !dialogData.trigger) {
        const { label, style } = dialogData;

        const buttonName = `dialog_button_${generateUid()}`;
        const addButtonData = {
          uiType: 'Button',
          name: buttonName,
          text: label,
          style,
        };

        delete dialogData.style;
        delete dialogData.label;
        delete dialogData.title;

        dialogData.trigger = buttonName;

        dataModules.splice(index + 1, 0, addButtonData);
      }
    });
  });

  return schema;
};

export default fixDialogData;
