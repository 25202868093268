import BaseLog from './BaseLog';

export class HistoryLog extends BaseLog {
  protected static logType = 'HistoryDataWarning';

  static compare(msg: string, beforeData, afterData) {
    this.log(msg, '\n\n', 'Please change the part of history data , like this: ', '\n\n', JSON.stringify(afterData));
    // console.table([{ name: 'oldData', data: beforeData }, { name: 'newData', data: afterData }]);
  }
}
