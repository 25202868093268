import { wrapCatch } from '@alife/intl-util';
import { observer } from 'mobx-react';
import React from 'react';
import { LoadingState } from '../constants';
import { Store } from '../interfaces';
import { IAction } from '../interfaces/action';
import DadaEvent, { DadaEventTypes, DefaultPropsEvents } from '../stores/dada-event';
import { logEvent } from '../tools/loginfo';
import { getHookMap } from './utils/hook';
import { reload } from './utils/reload';

interface RegisterHocProps {
  layoutStore: Store;
  itemData: any;
  className: string;
  loadingState: LoadingState;
  TargetComponent: any;
  hotload?: boolean;
  actions: IAction[];
  runAction: (arg: IAction[]) => void;
  componentKey: string;
  visible?: boolean;
  refParams?: any;
}

interface RegisterHocState {
  cacheValue?: any;
}

@observer
class Register extends React.Component<RegisterHocProps, RegisterHocState> {
  compRef: any;
  compClass: any;
  events: any = {};
  actionHookMap;

  constructor(props) {
    super(props);
    const { layoutStore, TargetComponent, itemData } = props;
    this.compClass = TargetComponent;
    layoutStore.registerComponent(this);
    this.registerEvents(props);
    const { hotload } = itemData;
    if (hotload) {
      reload(props);
    }
  }

  componentWillUnmount() {
    const { layoutStore } = this.props;
    layoutStore.unInstallComponent(this);
  }

  onClick = () => {
    const { layoutStore, itemData } = this.props;
    layoutStore.onClick(itemData);
  };

  onChange = newValue => {
    const { layoutStore, itemData } = this.props;
    layoutStore.onChange(itemData, newValue);
  };

  changeElementData = (data, componentKey) => {
    const { layoutStore, itemData } = this.props;
    layoutStore.changeElementData(data, componentKey || itemData.componentKey);
  };

  registerEvents(props) {
    const { itemData } = props;
    this.actionHookMap = getHookMap(itemData.actions);
    [...DefaultPropsEvents, ...this.actionHookMap.keys()].forEach(eventKey => {
      // 优先使用属性里的事件函数，否则自动注入通用事件
      const customFunc = this.props[eventKey];
      this.events[eventKey] = this.runEventCurry(eventKey, customFunc ? wrapCatch(customFunc) : undefined);
    });
  }

  runEventCurry = (eventKey, customFunc?: (data: any) => any) => (...args) => {
    const { runAction } = this.props;
    customFunc && customFunc.apply(this, args);
    this.dispatchEvent(eventKey, args);
    this.runStoreAction(eventKey, args);
    this.runHookAction(eventKey, args, runAction); // hook 事件，需要用到变更事件后的值，必须在 dispatchEvent 之后执行
    this.logEvent(eventKey, args);
  };

  runStoreAction(eventKey, args) {
    // 核心事件处理逻辑，为保证稳定性单独执行，请勿使用事件订阅机制
    const actionFunc = this[eventKey];
    if (!actionFunc || actionFunc.refFunc) return;
    actionFunc.apply(this, args);
  }

  runHookAction = (eventKey, args, runAction) => {
    const actionsMap = getHookMap(this.props.itemData.actions);
    const actions = actionsMap.get(eventKey);
    if (!actions) {
      return;
    }
    const [actionData] = args;
    const ctx = { args, ...(typeof actionData === 'object' ? actionData : {}) };
    const actionDatas = actions.map(actionItem => ({
      ...actionItem,
      ctx,
      eventHook: false, // 带有eventHook会被拦截
    }));
    runAction(actionDatas);
  };

  dispatchEvent = (eventKey, args) => {
    const { layoutStore, itemData } = this.props;
    const { componentKey, [eventKey]: originEvent } = itemData;
    if (originEvent) {
      originEvent(...args);
    }
    layoutStore.eventObservable.dispatch(new DadaEvent(layoutStore, componentKey, eventKey, args));
  };

  logEvent = (eventKey, args) => {
    const { itemData } = this.props;
    const [evt] = args;
    const isWindowEvt = evt && evt.stopPropagation;
    logEvent({
      eventType: eventKey,
      eventTarget: itemData.componentKey,
      c1: isWindowEvt ? itemData.value : evt,
      extra: {
        k: 'a',
        arms: itemData.arms,
      },
    });
  };

  componentDidMount() {
    const { layoutStore, itemData } = this.props;
    const { componentKey } = itemData;
    layoutStore.eventObservable.dispatch(new DadaEvent(layoutStore, componentKey, DadaEventTypes.onComponentDidMount));
  }

  saveRef = name => ref => {
    if (ref && !ref.onRef) {
      this[name] = ref;
      const compRef = this.compRef;
      if (!compRef) {
        return;
      }
      this.registerWrappedCompMethod();
    }
  };

  reload = (params: any = {}) => reload(this.props, params);

  registerWrappedCompMethod = () => {
    const compRef = this.compRef;
    Object.keys(compRef).forEach(key => {
      if (!this[key] && typeof compRef[key] === 'function') {
        this[key] = compRef[key];
        this[key].refFunc = true;
      }
    });
  };

  render() {
    const { className, loadingState = LoadingState.none, TargetComponent, itemData } = this.props;

    return (
      <TargetComponent
        ref={this.saveRef('compRef')}
        onRef={this.saveRef('compRef')}
        {...itemData}
        {...this.events} // 必须在 itemData 之后，避免虚拟组件透传自身容器事件
        {...this.props} // 必须在 events 之后，保留属性原有事件
        className={`${className} reload-${loadingState}`}
        clazzName={itemData.clazzName || className}
        layoutStore={false}
        label={false}
      />
    );
  }
}

const wrapRegister = TargetComponent => {
  const compName = TargetComponent ? TargetComponent.name : '';
  // 对于容器，可能会用到子组件属性，需要进行 observer; 对于非容器，使用 react hooks 的组件不能 observer（mobx-react 不兼容）
  const TagComp = compName && /ontainer/.test(compName) ? observer(TargetComponent) : TargetComponent;
  const RegisterHoc = props => <Register {...props} TargetComponent={TagComp} />;
  return RegisterHoc;
};

export default wrapRegister;
