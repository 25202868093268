import { createSandBox, ISandBoxCodeBlock, set } from '@alife/intl-util';
import get from 'lodash/get';
import { action, computed, observable, toJS, isObservable } from 'mobx';
import { IPluginMeta } from 'src/interfaces/plugin';
import { getRefParamsPathMap, getValueByPath } from '../tools/ref-params';
// @ts-ignore
import packages from '../../package.json';
import { defaultSchema, ERROR, LoadingState, MergeStrategy } from '../constants';
import { locale } from '../i18n/index';
import { DadaComponnet, IToast, Store, TypeElement, TypePluginConfig, TypeSchema } from '../interfaces';
import { PluginMap } from '../plugins';
import { handleResponse } from '../plugins/actions/send-request';
import helper from '../tools/helper';
import Tools from '../tools/index';
import CoreLog from '../tools/logs/CoreLog';
import MapNode from '../tools/map-node';
import { replaceObjectToken } from '../tools/regex';
import { renderComponentThunk } from '../tools/render-component';
import request from '../tools/request';
import AdapterManager, { IAdapterMeta } from './adapterManager';
import customMergeMap from './custom-merge';
import EventObservable from './event-observable';
import PluginManager from './pluginManager';
import { isInnerKey } from './store-tool';
import { generateComponentKey, isAnonymousNode } from '../tools/node';
import { warpCatchRequest } from '@alife/intl-util';

const cacheRequest = warpCatchRequest(request);

const localeCommonText = locale.dada.common;
// configure({ enforceActions: true }) // 不允许在动作之外进行状态修改

class LayoutStore {
  version: packages.version;
  appId: string;
  componentMap: Map<string, DadaComponnet> = new Map();
  componentMapOrgin;
  componentMapWrapped;

  pluginMap = PluginMap;
  autoNameMap = {};

  props: any;

  schemaSubRootBlackList = {
    // can merge in root level
    modules: true,
  };

  schemaIgnoreList = {
    componentMap: true,
  };

  pluginManager: PluginManager;

  adapterManager: AdapterManager;

  sandBox: (block: ISandBoxCodeBlock) => any;

  get plugins(): IPluginMeta[] {
    return this.pluginManager.getAllMeta();
  }

  schemaNode: MapNode;

  @observable globalFeedback;

  @observable appDom;

  loadingState: LoadingState;

  eventObservable: EventObservable;

  get schema(): TypeSchema {
    return this.schemaNode.root;
  }

  get elementDataMap() {
    return this.schemaNode.nodeMap;
  }

  getElementData = (componentKey: string, subNodeId?: string) => {
    return this.schemaNode.getNode(componentKey, subNodeId);
  };

  getComponentByName = ({ name }) => {
    return this.componentMap.get(name);
  };

  getComponentMethod = (comp, methodName) => {
    return get(comp, [methodName]) || get(comp, ['compClass', methodName]);
  };

  getElementDataByName = ({ name }) => {
    return this.schemaNode.getNode(name);
  };

  getElementDataAction = ({ componentKey, subNodeId }) => {
    // run Action 输入一个对象
    return this.schemaNode.getNode(componentKey, subNodeId);
  };

  getFormData() {
    const formData = toJS(this.formData, { recurseEverything: true });
    Object.keys(formData).forEach(key => {
      const component = this.componentMap.get(key);
      const fn = this.getComponentMethod(component, 'getSubmitValue');
      if (typeof fn === 'function') {
        try {
          formData[key] = fn.call(component, this.getElementData(key));
        } catch (e) {
          console.error(key, e);
        }
      }
    });
    return formData;
  }

  getSendParams(data, paramStringify: boolean = true) {
    if (!paramStringify) {
      return data;
    }
    return Object.entries(data)
      .filter(([, val]) => val !== undefined)
      .reduce((obj, [key, returnValue]) => {
        if (returnValue instanceof Object) {
          returnValue = JSON.stringify(returnValue);
        }
        return { ...obj, [key]: returnValue };
      }, {});
  }

  @computed get formData() {
    const formData = {};
    for (const itemData of Array.from(this.elementDataMap.values())) {
      if (!itemData.name || isAnonymousNode(itemData)) {
        continue;
      }
      formData[itemData.name] = itemData.value !== undefined ? itemData.value : itemData.values;
    }
    return formData;
  }

  set formData(value) {
    console.warn('formData', value);
  }

  @computed get elementDataObj() {
    return this.getElementDataObj();
  }

  getElementDataObj = () => {
    const obj: any = {};
    for (const [key, itemData] of this.elementDataMap) {
      if (isInnerKey(key)) continue;
      obj[key] = itemData;
    }
    return obj;
  };

  constructor(props) {
    this.schemaNode = new MapNode({
      customMergeMap,
      autoNameMap: this.autoNameMap,
      ignoreList: this.schemaIgnoreList,
      defaultNode: defaultSchema,
      mergeStrategy: props.mergeStrategy || MergeStrategy.remoteFirst,
    });

    this.props = props;

    CoreLog.version();
    this.version = packages.version;

    this.initSandBox();

    this.eventObservable = new EventObservable(this);
    this.pluginManager = new PluginManager({ store: this });
    this.adapterManager = new AdapterManager({ store: this, adapters: props.adapters });
  }

  initSandBox() {
    const instance = this;
    this.sandBox = createSandBox({
      get runAction() {
        return instance.runAction;
      },
      get model() {
        return instance.elementDataObj;
      },
    });
  }

  initData = (schema: TypeSchema) => {
    const vmPlugins = get(window, 'dadaConfig.schema.plugins', []);
    const { success = false, status = 200, redirectUrl, error, plugins: schemaPlugins = [] } = schema;
    const plugins = [...vmPlugins, ...schemaPlugins];
    if (status === 302 && redirectUrl) {
      location.href = redirectUrl;
    } else if (success) {
      this.setData({ ...schema, plugins });
    } else {
      const showError = error || localeCommonText.systemError;
      this.setGlobalMessage(showError);
    }
  };

  registerPlugins = (pluginConfigs: TypePluginConfig[] = []) => {
    pluginConfigs.forEach(config => {
      const func = typeof config === 'function' ? config : this.pluginMap[config.type];
      const plugin = typeof func === 'function' ? func(config) : config;
      if (!plugin) {
        return;
      }
      if (plugin.init) {
        plugin.init(this);
      }
      this.pluginManager.install({
        name: plugin.name,
        instance: plugin,
      });
    });
  };

  resetPlugin(pluginConfigs: TypePluginConfig[] = []) {
    this.destroyPlugins();
    this.registerPlugins(pluginConfigs);
  }

  registerWrapter = (adapter: IAdapterMeta) => {
    this.adapterManager.install(adapter);
  };

  registerComponent = component => {
    if (!component) {
      return '';
    }
    const { itemData = {} } = component.props || {};

    itemData.componentKey = itemData.name || itemData.componentKey || generateComponentKey();
    this.componentMap.set(itemData.componentKey, component);
    return itemData.componentKey;
  };

  registerData = itemData => {
    if (!itemData) {
      return itemData;
    }
    if (isObservable(itemData) && itemData.componentKey) {
      // 已注册，跳过
      return itemData;
    } else {
      // 未注册节点
      const key = itemData._innerComponentKey || itemData.componentKey || generateComponentKey();
      const registedNode = this.schemaNode.getNode(key); // 如果存在同名的节点，说明已经注册过了
      return registedNode
        ? this.schemaNode.mergeNode(itemData, key) // 虚拟组件命中节点扫描，但是可能修改了itemData数据
        : this.schemaNode.addNode(key, itemData); // 虚拟组件未命中节点扫描，需要新建
    }
  };

  unRegisterData = key => {
    this.schemaNode.deleteNode(key);
  };

  unInstallComponent(component) {
    if (!component) {
      return;
    }
    const { itemData } = component.props;
    this.componentMap.delete(itemData.componentKey);
  }

  destroyPlugins() {
    this.pluginManager.destroyAll();
  }

  // TODO: dispose all memory && plugins
  destroy() {
    this.resetData();
    this.destroyPlugins();
  }

  mergeSchema(data: TypeSchema) {
    this.schemaNode.merge(data);
  }

  changeParentElementData(newData, componentKey?: string) {
    if (this.props && this.props.changeParentElementData) {
      return this.props.changeParentElementData(newData, componentKey);
    }
  }

  // set ui render data
  @action setData = (data: TypeSchema) => {
    this.schemaNode.reset(data);
    this.updateNodeRefParams();
  };

  updateNodeRefParams = () => {
    this.schemaNode.nodeMap.forEach(this.observeNodeExpression);
  };

  observeNodeExpression = node => {
    const refMap = getRefParamsPathMap(node);
    const updateNode = (keyName, target, value) => {
      const keyArr = keyName.split('.');
      const key = keyArr[0];
      if (keyArr.length > 1) {
        set(target, keyArr, value); // 强制更新监听对象
        target[key] = toJS(target[key]);
      } else {
        target[keyName] = value;
      }
    };
    refMap.forEach((valuePath, keyName) => {
      updateNode(keyName, node, getValueByPath(this.elementDataObj, valuePath, node));
      const compVal = computed(() => getValueByPath(this.elementDataObj, valuePath, node));
      compVal.observe(change => {
        updateNode(keyName, node, change.newValue);
      });
    });
  };

  @action resetData = () => {
    this.schemaNode.reset();
    this.globalFeedback = {};
  };

  reset(schema: TypeSchema) {
    const { plugins = [] } = schema;
    this.schemaNode.reset();
    this.componentMap.clear();
    // to make sure recreate all component
    this.setData({ success: true, status: 200, modules: [] });
    this.setData(schema);

    setTimeout(() => {
      // need to run in next event loop
      this.resetPlugin(plugins);
    });
  }

  changeElementData = (newData, componentKey?: string, recursive = true) => {
    this.changeElementDataWithoutHook(newData, componentKey, recursive);
  };

  changeElementDataSafely = (newData, componentKey?: string, recursive?: boolean) => {
    try {
      this.changeElementData(newData, componentKey, recursive);
    } catch (e) {
      console.error('changeElementData failed', e);
    }
  };

  @action private changeElementDataWithoutHook = (newData, componentKey?: string, recursive = true) => {
    // don't hook this method
    if (!newData) {
      return;
    }
    newData.componentKey = componentKey || newData.componentKey;
    this.schemaNode.mergeNode(newData, newData.componentKey, recursive);
  };

  @action updateNode = (newData, componentKey) => this.schemaNode.updateNode(componentKey, newData);

  @action changeElementDataWithoutHookBatch = newDataMap => {
    if (!newDataMap) {
      return;
    }
    Object.entries(newDataMap).forEach(([key, newData]) => {
      const itemData: any = newData;
      Object.assign(itemData, {
        componentKey: itemData.componentKey || key,
      });
      this.schemaNode.mergeNode(itemData, itemData.componentKey);
    });
    if (newDataMap.forceUpdateRefParams) {
      this.updateNodeRefParams();
    }
  };

  replaceToken = objWithToken => {
    return objWithToken ? replaceObjectToken(this)(objWithToken) : objWithToken;
  };

  reloadComponent = (itemData, params) => {
    const { componentKey } = itemData;
    if (!itemData || itemData.loadingState === LoadingState.runing || !itemData.request) {
      return Promise.resolve();
    }
    const option = itemData.request;
    if (!option || !option.url) {
      return Promise.reject();
    }
    this.changeElementData({ loadingState: LoadingState.runing }, componentKey);

    const data = replaceObjectToken({ store: this, formData: this.getFormData(), ...this.elementDataObj })(
      option.data || {},
    );

    const requestParams = Object.assign({}, data, params);
    const requestFunc = option.useCache ? cacheRequest : request;
    return requestFunc({
      ...option,
      data: { ...requestParams, timestamp: Date.now() },
    })
      .then(res => {
        handleResponse(this, res);
        if (!res.success || !res.data) {
          throw new Error('Request Failed');
        }
        if (typeof res.data === 'object' && !Array.isArray(res.data)) {
          this.changeElementData({ ...res.data, loadingState: LoadingState.success, name: componentKey }, componentKey); // avoid change name
        } else {
          this.changeElementData({ loadingState: LoadingState.success, name: componentKey }, componentKey); // avoid change name
        }
        return Promise.resolve(res);
      })
      .catch(e => {
        this.changeElementData({ loadingState: LoadingState.failed }, componentKey);
        return Promise.reject();
      });
  };

  reload = params => {
    if (this.loadingState === LoadingState.runing) {
      return Promise.resolve();
    }
    this.loadingState = LoadingState.runing;
    const { request: option } = this.schema;
    if (!option) {
      return Promise.reject(`Can't find request from schema`);
    }
    return request({
      ...option,
      data: { ...option.data, ...params, timestamp: Date.now() },
    })
      .then(res => {
        handleResponse(this, res);
        if (!res.success || res.type === 'actions') {
          return;
        }
        this.schemaNode.reset(res.data);
      })
      .catch(err => {
        this.showMessage('Reload failed :(');
        console.error(`Failed to load page data by url: ${option.url}`, err);
      });
  };

  @action setGlobalMessage = (error, type = ERROR, title = localeCommonText.systemError) => {
    this.globalFeedback = {
      title,
      type,
      data: Tools.isArray(error) ? error : [error],
    };
    helper.scrollToTop();
  };

  @action resetGlobalMessage = () => {
    this.setGlobalMessage([]);
  };

  afterInit() {
    console.info('init success');
  }

  componentWillUnmount() {
    console.info('dada componentWillUnmount');
  }

  renderComponent = (element: TypeElement, index: number): JSX.Element | string => `Not implement yet`;

  runAction = (eventOptionArr: any) => `Not implement yet`;

  forceUpdate = () => `Not implement yet`;

  registerComponentMap = (inputMap = {}) => {
    this.componentMapOrgin = inputMap;
    const localMap = Object.assign({}, inputMap);
    Object.keys(localMap).forEach(key => {
      localMap[key] = this.adapterManager.get(localMap[key]);
    });
    this.componentMapWrapped = localMap;
    this.renderComponent = renderComponentThunk(this.componentMapWrapped);
  };

  showMessage(data: string | IToast) {
    if (!data) {
      return;
    } else if (typeof data === 'string') {
      this.toast({ content: data });
    } else if (data.content) {
      this.toast({
        ...data,
        type: data.type,
        content: data.content || 'Success',
        duration: data.duration || 3000,
      });
    } else {
      console.warn("Can't show message by: ", data);
    }
  }

  showConfirm(data): Promise<any> {
    console.info('showDialog', data);
    return Promise.resolve();
  }

  @action
  validateItemRequired = item => {
    const value = this.formData[item.name] || item.value; // 虚拟组件的值不会注册到FormData中，但是会执行校验
    const component = this.componentMap.get(item.name);
    // @ts-ignore
    const fn = component && component.checkRequired;
    let isEmpty;
    if (fn) {
      isEmpty = fn && !fn.call(component);
    }
    if (isEmpty === undefined) {
      isEmpty = (!value && value !== 0 && value !== false) || (value && value.length === 0);
    }
    const isValidated = !isEmpty || !item.required || item.readOnly || item.visible === false;
    item.error = isValidated ? item.validateError : get(item, 'locale.required', localeCommonText.required);
    return isValidated;
  };

  @action
  checkRequired = (validateComps?: any[]) => {
    let hasError = false;
    if (Array.isArray(validateComps) && validateComps.length > 0) {
      validateComps.forEach(comp => {
        const isValidated = this.validateItemRequired(comp);
        hasError = hasError || !isValidated;
      });
      return !hasError;
    }

    for (const [, item] of this.elementDataMap) {
      const isValidated = this.validateItemRequired(item);
      hasError = hasError || !isValidated;
    }
    return !hasError;
  };

  onSubmit = data => Promise.resolve();

  jump = href => {
    location.href = href;
  };

  onClick(itemData) {
    if (!itemData) {
      return Promise.reject();
    }
    const { actions } = itemData;
    const actionArr = actions ? actions : [itemData];
    return this.runAction(actionArr);
  }

  onChange(itemData, newValue) {
    const { value: oldValue, actions, eventType } = itemData!;
    if (oldValue === newValue) return;
    try {
      this.changeElementData({ value: newValue }, itemData!.componentKey, false);
      this.runAction((eventType && !actions && itemData) || actions);
    } catch (e) {
      if (e.message === 'UserCancel') return;
      throw e;
    }
  }

  toast(data: IToast) {
    // Message.show
    console.info('showMessage', data);
  }

  pluginfyMethod: (store: Store, methodName: string) => void;
}

export default LayoutStore;
