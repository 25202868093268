import { TypeSchema } from 'src/interfaces';

export const ARRAY = '[object Array]';
export const OBJECT = '[object Object]';
export const BOOLEAN = '[object Boolean]';
export const UNDEFINED = '[object Undefined]';
export const STRING = '[object String]';

export const ERROR = 'error';
export const WARNING = 'warning';
export const SUCCESS = 'success';

export const ERROR_UNKNOW = 'Error Unknow';

export enum LoadingState {
  none = 'none',
  pending = 'pending',
  runing = 'runing',
  success = 'success',
  failed = 'failed',
}

export const defaultSchema: TypeSchema = {
  success: true,
  status: 200,
  modules: [],
};

export enum MergeStrategy {
  remoteFirst = 'remoteFirst',
  localFirst = 'localFirst',
}

export * from './log';

export default {};
export const NOOP = () => void 0;
export const EmptyObj = {};
export const EmptyFunc = NOOP;
