import { locale, Stores, Tools as TemplateTool } from '@alife/dada-template-core';
import { Message, Dialog } from '@alife/next';
import { action, observable, toJS } from 'mobx';
import get from 'lodash/get';
import Tools from '../../tools';
import helper from '../../tools/helper';
import { PluginMap } from '../../plugins';
import wrapAdapter from '../../adapters';

const CoreStore = Stores.default;

const localeCommonText = locale.dada.common;

class LayoutStore extends CoreStore {
  pluginMap = PluginMap;
  componentWrapAdapter = wrapAdapter;

  @observable extraParams = {};

  constructor(props = {}) {
    super(props);
    this.extraParams = props.extraParams;
  }

  @action reRender = () => {
    const data = Tools.copyArray(this.modules);
    this.modules = data;
  };

  // set item error
  @action setItemError = (error = {}) => {
    const errors = Tools.getObjectKeys(error);
    errors.forEach(key => {
      this.changeElementData({ error: error[key] }, key);
    });
    this.reRender();
    this.scrollToError();
  };

  scrollToError = () => {
    helper.scrollToError();
  };

  @action
  cleanFormData() {
    for (const [key, item] of this.schemaNode.nodeMap) {
      item.value = '';
      item.values = [];
    }
  }

  getSendParams(dataTemplate = {}, paramStringify = true) {
    const replaceTokenByFromData = TemplateTool.replaceObjectToken(this.formData);
    return Object.entries(super.getSendParams(dataTemplate, paramStringify)).reduce((obj, [key, value]) => {
      let returnValue = value;
      if (typeof value === 'string') {
        returnValue = replaceTokenByFromData(value);
        if (paramStringify && returnValue instanceof Object) {
          returnValue = JSON.stringify(returnValue);
        }
      }
      return { ...obj, [key]: returnValue };
    }, {});
  }

  jump = href => {
    location.href = href;
  };

  showConfirm = option => {
    const { icon = 'help', cancelActions = [], closeActions = [] } = option || {};
    const contentNode = <div dangerouslySetInnerHTML={{ __html: option.content || option.value || '' }} />;
    const NextclassName = icon === '' ? 'show-confirm-normal' : '';
    return new Promise((resolve, reject) => {
      Dialog.confirm({
        className: NextclassName,
        ...option,
        content: contentNode,
        messageProps: {
          type: icon,
        },
        onClose: () => {
          if (closeActions.length) {
            this.runAction(closeActions);
          }
        },
        onOk: resolve,
        onCancel: () => {
          if (cancelActions.length) {
            this.runAction(cancelActions);
          }
          reject('Confirm dialog canceled');
        },
      });
    });
  };

  showAlert = option => {
    return new Promise((resolve, reject) => {
      Dialog.alert({
        ...option,
        onOk: resolve,
      });
    });
  };

  showMessage = data => {
    if (!data) {
      return;
    } else if (typeof data === 'string') {
      Message.show({ content: data });
    } else if (data.content) {
      Message.show({
        type: data.type || 'success',
        content: data.content || data.value || 'Success', // 新结构返回的是 content， 老结构是 value
        duration: data.duration || 1000,
        hasMask: true,
        ...data,
      });
    } else {
      console.warn('Can not show meesage by: ', data);
    }
  };

  toast = Message.show;
  confirm = Dialog.confirm;
}

export default LayoutStore;
export { LayoutStore };
