import goldlog from '../tools/goldlog';

const getByPath = (target, path) => {
  if (!target) {
    return null;
  }
  try {
    const newTarget = target[path.shift()];
    return path.length ? getByPath(newTarget, path) : newTarget;
  } catch (e) {
    return null;
  }
};

const logOnClick = triggerMap => (store, next, option: { args: [] }) => {
  try {
    const { args } = option;
    const [, itemData = {} as any] = args.length > 1 ? args : [{}, ...args];
    const config = triggerMap[itemData.name];
    if (!config) {
      return next();
    }
    goldlog(
      {
        key: config.goldlogKey,
        data: config.eventParams,
        method: config.method,
        type: config.type,
      },
      store.elementDataObj,
    );
  } catch (e) {
    console.error(e);
  }
  return next();
};

const logOnChange = triggerMap => (store, next, option: any = {}) => {
  try {
    const { args } = option;
    const [newData, componentKey = {}] = args;
    const config = triggerMap[componentKey];
    if (!config) {
      return next();
    }
    goldlog(
      {
        key: config.goldlogKey,
        data: {
          ...config.eventParams,
          value: newData.value,
        },
        method: config.method,
        type: config.type,
      },
      store.elementDataObj,
    );
  } catch (e) {
    console.error(e);
  }
  return next();
};

const logOnInit = triggerMap => (store: any, next, option: any = {}) => {
  try {
    Object.keys(triggerMap).forEach(key => {
      const config = triggerMap[key];
      const ctx = { ...store, ...store.elementDataObj };
      if (config) {
        console.info(
          'goldlog',
          {
            key: config.goldlogKey,
            data: config.eventParams,
          },
          store,
        );
        goldlog(
          {
            key: config.goldlogKey,
            data: config.eventParams,
            method: config.method,
            type: config.type,
          },
          ctx,
        );
      }
    });
  } catch (e) {
    console.error(e);
  }
  return next();
};

const logOnPageLeave = logOnInit;

// const demoConfig = {
//   "type": "goldlog",
//   "scripts": [{
//     "eventType": "onClick",
//     "triggerEleName": "elementData",
//     "goldlogKey": "aaa"
//     "eventPrarms": {
//       "BU": "AE"
//     }
//   }],
// };

function GoldLog(config) {
  const { scripts = [] } = config;
  const hookTypeMap: any = {};
  scripts.forEach(script => {
    script.eventType = script.eventType || 'onClick';
    const triggerMap = hookTypeMap[script.eventType] || {};
    triggerMap[script.triggerEleName] = script;
    hookTypeMap[script.eventType] = triggerMap;
  });
  const Plugin = {
    name: 'GoldLog',
    init: store => {
      Object.keys(hookTypeMap).forEach(hookName => {
        store.pluginfyMethod(store, hookName);
      });
      store.sendLog = goldlog;
      if (hookTypeMap.onPageLeave && Object.keys(hookTypeMap.onPageLeave).length) {
        window.addEventListener('beforeunload', store.onPageLeave);
      }
    },
    onClick: logOnClick(hookTypeMap.onClick || {}),
    onPageLeave: logOnPageLeave(hookTypeMap.onPageLeave || {}),
    changeElementData: logOnChange(hookTypeMap.onChange || {}),
    afterInit: logOnInit(hookTypeMap.afterInit || {}),
  };
  return Plugin;
}

export default GoldLog;
