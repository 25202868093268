function RemoveError(config) {
    return {
        name: 'RemoveError',
        init(store) {
            if (config && config.open) {
                store.pluginfyMethod(store, 'changeElementData');
            }
        },
        async changeElementData(store, next, option = {}) {
            const {
                args
            } = option;
            await next();
            const [{
                value
            }, name] = args;
            const itemData = store.getElementData(name);
            if (itemData && itemData.error && value) {
                store.checkRequired();
            }
        }
    };
}

export default RemoveError;
