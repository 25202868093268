import goldlog from '../../tools/goldlog';
import { Store } from '../../interfaces';
import DadaEvent, { DadaEventTypes } from '../../stores/dada-event';

export default function log(params) {
  const { goldlogKey, ...rest } = params;
  goldlog({
    key: goldlogKey,
    data: rest,
  });
}

interface IRecordTaskParams {
  taskName: string;
  taskId: string;
  [props: string]: string;
}

export function recordTimeStart(store: Store, data: IRecordTaskParams) {
  store.eventObservable.dispatch(new DadaEvent(store, 'store', DadaEventTypes.recordTimeStart, [data]));
}

export function recordTimeEnd(store: Store, data: IRecordTaskParams) {
  store.eventObservable.dispatch(new DadaEvent(store, 'store', DadaEventTypes.recordTimeEnd, [data]));
}
