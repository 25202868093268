import Tools from '../../tools';
import { IAction } from '../../interfaces';
import isNill from 'lodash/isNil';
import isEqual from 'lodash/isEqual';

interface Rule {
  name: string;
  required?: boolean;
  regex?: string;
  message?: string;
  error?: string;
}

interface IValidatorAction extends IAction {
  rules: Rule[];
  autoScroll?: boolean;
}

/**
 *
 * @example
 * {
 * "eventType": "validator",
 * "eventParams": {
 *    "rules": [{
 *         "name": "companyEmail",
 *          "required": true,
 *          "regex": "^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$",
 *         "message": `$i18nTool.getByAppAndlang("global-merchant-onboarding-s", "alert@FillValidEmailAddress", "$!{lang}")`
 *     }]
 *  },
 * "async": false
 * }
 */

const checkRule = (itemData, rule: Rule) => {
  let value = Array.isArray(itemData.values) ? itemData.values.join(',') : itemData.value;

  // filter empty
  if (isNill(value) || isEqual(value, '') || isEqual(value, [])) {
    return true;
  }

  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  const hasError = !new RegExp(rule.regex!).test(value);
  if (hasError) {
    itemData.error = rule.error || rule.message || 'invalid';
  }
  return !hasError;
};

/**
 *
 * @param store
 * @param itemData
 * @param ruleMap
 *
 * true ->  valid
 * false ->  invalid
 */
const checkValidator = (store, itemData, ruleMap): boolean => {
  const rules: Rule[] = ruleMap[itemData.name];

  for (const rule of rules) {
    const { required, regex } = rule;

    if (required) {
      const isRequiredValid = store.validateItemRequired(itemData);
      if (!isRequiredValid) {
        if (rule.message || rule.error) {
          itemData.error = rule.error || rule.message;
        }
        return false;
      }
    }

    if (regex) {
      const isRuleValid = checkRule(itemData, rule);
      if (!isRuleValid) {
        return false;
      }
    }
  }
  return true;
};

export default function Validator(store, data: IValidatorAction) {
  const { rules = [], autoScroll = true } = data;
  const ruleMap = {};
  rules.forEach(rule => {
    const exist: Rule[] = ruleMap[rule.name] || [];
    exist.push(rule);
    ruleMap[rule.name] = exist;
  });

  const validatorResult: boolean[] = [];
  Object.entries(ruleMap).forEach(([key, rule]) => {
    const elementData = store.elementDataMap.get(key);
    if (!elementData || !Tools.get(elementData, 'visible', true)) {
      return;
    }
    const result = checkValidator(store, elementData, ruleMap);
    validatorResult.push(result);
  });

  if (validatorResult.every(validator => validator)) {
    return Promise.resolve();
  }

  if (store.scrollToError && autoScroll) {
    store.scrollToError();
  }

  return Promise.reject({
    message: 'Validate Failed',
  });
}
