import { Store } from 'src/interfaces';
import { IAction, IRule, IEventReaction } from '../../interfaces/eventReaction';
import { IPluginMeta } from 'src/interfaces/plugin';
// import { CONST_IOC } from '../../constants/ioc';
// import { inject } from '../../stores/container';

type IDisposeFunction = () => void;

class EventReaction implements IPluginMeta {
  name = 'eventReaction';
  actions: IAction[];
  scripts: Array<{
    name: string;
    rules: IRule[];
    actions: IAction[];
  }>;

  private dispose: IDisposeFunction[] = [];

  store: Store;

  constructor(options: IEventReaction) {
    const { actions = [], scripts = [] } = options;
    this.actions = actions;
    this.scripts = scripts;
  }

  mergeActions(actionStr: string = '', actions: IAction[]): IAction[] {
    const names = actionStr.split(',').map(name => name.trim());
    const mergeAction = this.actions.concat(actions);
    return names
      .filter(name => name)
      .map(key => mergeAction.find(action => action.name && action.name.trim() === key))
      .filter(action => action)
      .map((action: IAction) => {
        return {
          actionType: action.actionType,
          actionTarget: action.actionTarget,
          actionParams: action.actionParams,
          async: action.async,
        };
      }) as IAction[];
  }

  getActions() {
    const actions = {};
    this.scripts.forEach(item => {
      if (!actions[item.name]) {
        actions[item.name] = {};
      }
      item.rules.forEach(ruleItem => {
        if (!actions[item.name][ruleItem.eventType]) {
          actions[item.name][ruleItem.eventType] = [];
        }

        ruleItem.actions.split(',').forEach(actionName => {
          const findActionItem = this.actions.find(actionItem => actionItem.name === actionName);
          if (findActionItem) {
            actions[item.name][ruleItem.eventType].push({
              async: findActionItem.async,
              eventType: findActionItem.actionType,
              eventTarget: findActionItem.actionTarget,
              eventParams: findActionItem.actionParams,
              eventHook: ruleItem.eventType,
            });
          }
        });
      });
    });
    return actions;
  }

  init(store: Store) {
    this.store = store;
    // 注册事件
    this.scripts.forEach(item => {
      const { name, rules = [], actions = [] } = item;
      rules.forEach(rule => {
        const dispose = this.store.eventObservable.subscribe(name, rule.eventType, {
          actions: this.mergeActions(rule.actions, actions),
        });
        this.dispose.push(dispose);
      });
    });

    // 整理所有的action 根据eventHook进行分类后注入给到组件
    // 这样特殊的action会被hoc消费生成一个props
    const allActions = this.getActions();
    Object.entries(allActions).forEach(([componentName, hooksMap]: [string, Record<string, any[]>]) => {
      Object.entries(hooksMap || {}).forEach(([eventHook, allHooks]) => {
        const itemData = store.getElementData(componentName);
        if (itemData) {
          // eventType注入为null 具体的执行动作由上面注册的eventObservable触发
          store.changeElementData(
            {
              actions: [...(itemData.actions || []), { eventHook, eventType: null }],
            },
            componentName,
          );
        }
      });
    });
  }

  destroy() {
    this.dispose.forEach(dispose => typeof dispose === 'function' && dispose());
  }
}

function ActionPlugin(options: IEventReaction) {
  return new EventReaction(options);
}

export default ActionPlugin;
