import { toJS } from 'mobx';
import { Tools, Plugins } from '@alife/dada-template-core';

const { getPlugin } = Plugins;

const explainObj = (data) =>
  toJS(data, {
    recurseEverything: true,
  });

const getParams = (store) => {
  const formData = store.getFormData();
  const formDataClone = explainObj(formData);
  Object.keys(formDataClone).forEach((itemKey) => {
    if (Tools.isArray(formDataClone[itemKey])) {
      formDataClone[itemKey] = JSON.stringify(formDataClone[itemKey]);
    }
    if (Tools.isObject(formDataClone[itemKey])) {
      formDataClone[itemKey] = JSON.stringify(formDataClone[itemKey]);
    }
  });
  return Object.assign({}, formDataClone);
};

// TODO: 这个插件建议提取到dada-template-core中
function AutoSave(config) {
  const { interval, request } = config;

  return {
    name: 'AutoSave',
    init(store) {
      setInterval(() => {
        this.autoSave(store);
      }, interval);
      window.onbeforeunload = () => {
        this.autoSave(store);
      };
    },
    autoSave(store) {
      const requestData = explainObj(request);
      const storeParams = getParams(store);
      store.runAction([
        {
          eventType: 'sendRequest',
          request: {
            ...requestData,
            data: { ...storeParams, ...requestData.data },
          },
        },
      ]);
    },
  };
}

export default AutoSave;
