import { compose } from '@alife/intl-util';
import wrapStore from './store-hoc';
import wrapLabel from './label-hoc';
import wrapWhen from './when-hoc';
import wrapRegister from './register-hoc';
import wrapErrorBoundary from './error-hoc';
import wrapLocale from './locale-hoc';
import wrapMemo from './memo-hoc';
import wrapDebug from './debug-hoc';
import wrapInView from './view-hoc';

export { wrapStore, wrapLabel, wrapWhen, wrapRegister, wrapErrorBoundary, wrapLocale, wrapMemo, wrapDebug, wrapInView };

export default compose(
  wrapMemo,
  wrapLocale,
  wrapErrorBoundary,
  wrapStore,
  wrapLabel,
  wrapWhen,
  wrapDebug,
  wrapRegister,
);
