import { goldlog } from '@alife/intl-util';
import { replaceObjectToken } from './regex';
import get from 'lodash/get';

const sendLog = (config, ctx?: any) => {
  if (!config) {
    console.warn('There is no config matched in goldlog plugin');
    return;
  }
  const replacedParams = ctx ? replaceObjectToken(ctx)(config.data) : config.data;
  const defaultParams = get(window, 'dadaConfig.defaultGoldlogParams') || {};
  const windowRegex = /window\./;
  Object.entries(defaultParams).forEach(([key, value]) => {
    defaultParams[key] =
      typeof value === 'string' && windowRegex.test(value) ? get(window, value.replace(windowRegex, '')) : value;
  });
  if (config) {
    goldlog({
      key: config.key,
      method: config.method,
      type: config.type,
      data: { ...defaultParams, ...replacedParams },
    });
  }
};

const tryToSendLog = (...args) => {
  try {
    sendLog.apply(null, args);
  } catch (e) {
    console.error('failed to send log:', e);
  }
};

export default tryToSendLog;
