import { IAction } from 'src/interfaces/eventReaction';
import { replaceTokenWithExpress, cloneJson, wrapCatch } from '@alife/intl-util';

interface TAction extends IAction {
  actionName: string;
  actionId: string;
}

interface TConncetion {
  fromId: string;
  fromName?: string;
  toId: string;
  toName?: string;
  condition: string;
}

interface Store {
  runAction: (TAction) => Promise<any>;
}

interface WorkflowConfig extends TAction {
  actions: TAction[];
  connections: TConncetion[];
}

const logError = (error, errorType = 'Workflow Exception') => {
  console.error(errorType, error);
};

const logAction = (action: TAction, message = 'Workflow: ') => {
  try {
    console.warn(message, cloneJson(action));
  } catch (e) {
    console.warn('Failed to log action:', e);
  }
};

const isMatch = wrapCatch((connection, scope) => {
  const condition = replaceTokenWithExpress({ scope })(connection.condition);
  const isMatched = eval(condition);
  return isMatched;
});

class Workflow {
  actionMap: Map<string, TAction>;
  connectionMap: Map<string, TConncetion[]>;
  store: Store;
  waitingMap: Map<string, TAction> = new Map();
  config: WorkflowConfig;

  constructor(config: WorkflowConfig, store) {
    const { actions, connections } = config;
    const actionMap = new Map();
    const connectionMap = new Map();
    connections.forEach(connection => {
      const list = connectionMap.get(connection.fromId) || [];
      list.push(connection);
      connectionMap.set(connection.fromId, list);
    });
    actions.forEach(action => {
      actionMap.set(action.actionId, action);
    });
    this.store = store;
    this.actionMap = actionMap;
    this.connectionMap = connectionMap;
    this.config = config;
  }

  runAction(connection: TConncetion, scope) {
    if (!isMatch(connection, scope)) {
      return;
    }
    const action = this.actionMap.get(connection.toId);
    if (!action) {
      logError(action);
      return false;
    }
    return this.store
      .runAction({
        ...action,
        actionParams: undefined,
        eventType: action.actionType,
        eventTarget: action.actionTarget,
        eventParams: {
          ...action.actionParams,
          workflowConfig: this.config,
        },
        ctx: { scope },
      })
      .then((newScope = {}) => {
        if (newScope._workflow_stop === true) {
          return;
        }
        return this.run(action, { success: true, ...newScope });
      })
      .catch(error => {
        logError(error, `Action Break at ${action.actionName}`);
        return this.run(action, { success: false, error });
      });
  }

  run(action: TAction, scope) {
    const { actionId } = action;
    const connectionList = this.connectionMap.get(actionId);
    if (!connectionList) {
      logError(connectionList);
      return;
    }
    const resultArr = connectionList.map(connection => {
      logAction(action);
      return this.runAction(connection, scope);
    });
    const hasNext = resultArr.every(result => result === false);
    if (!hasNext) {
      logAction(action, `Workflow ${this.config.actionId}:`);
    }
  }

  wait(actionData) {
    const { actionParams } = actionData;
    const { workflowId } = actionParams;
    this.waitingMap.set(workflowId, actionData);
  }

  goOn(actionData) {
    const { actionParams, workflowConfig } = actionData;
    const action = this.waitingMap.get(workflowConfig.actionId);
    if (!action) {
      return;
    }
    this.run(action, actionParams);
  }
}

function WorkflowPlugin(config: WorkflowConfig) {
  let workflow;
  return {
    name: 'RemoveError',
    init(store) {
      workflow = new Workflow(config, store);
      if (!config) {
        return;
      }
      store.waitWorkflowFinished = () => Promise.resolve({ _workflow_stop: true });
      store.finishWorkflow = scopeData => Promise.resolve(scopeData);
      store.pluginfyMethod(store, 'startWorkflow');
      store.pluginfyMethod(store, 'finishWorkflow');
      store.pluginfyMethod(store, 'waitWorkflowFinished');
    },
    waitWorkflowFinished: (store, next, option) => {
      const [actionData] = option.args;
      workflow.wait(actionData);
      return next();
    },
    finishWorkflow: (store, next, option) => {
      const [actionData] = option.args;
      workflow.goOn(actionData);
      return next();
    },
    startWorkflow: (store, next, option) => {
      const [actionData] = option.args;
      const { workflows } = actionData;
      const matchedWorkflow = workflows.find(w => w.workflowId === config.actionId);
      if (matchedWorkflow) {
        workflow.run(config, matchedWorkflow.scope);
      }
      return next();
    },
  };
}

export default WorkflowPlugin;
