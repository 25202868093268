import { Env, loadScript, loadStyle } from '@alife/intl-util';
import Tools from './../tools';

// hfType: lzd|lsc|taiwan|cb 都走 lazada-landing-pages
// 新增 biz 判断，如果存在 biz，先判断 biz
/**
 * biz 定义
 * 1. ae
 * 2. lazada
 */
const isLazada = (hfType: string) => /^lazada|lzd|lsc|taiwan|cb$/.test(hfType);

const getLandingPageNameBySchema = schema => {
  const { biz, hfType } = schema;
  return isLazada(biz || hfType) ? 'lazada-landing-pages' : 'ae-landing-pages';
};

/**
 * http://g-assets.daily.taobao.net/intl-dada/ae-landing-pages/888.88.0/dashboard.css
 */
function LandingPage() {
  return {
    name: 'LandingPage',
    init: async store => {
      try {
        // Wait for next Task，In order to guarantee the "Store.schema" is the latest
        await Tools.promiseTimeout();
        const { pageId, dadaConfig = {} } = store.schema;
        const { landingPageName, landingPageVersion = '999.99.0' } = dadaConfig;

        if (!pageId) {
          return;
        }

        const businessScope = landingPageName || getLandingPageNameBySchema(store.schema);

        const jsSrc = `//${Env.getPrefix()}/intl-dada/${businessScope}/${landingPageVersion}/${pageId}.js`;
        const cssSrc = `//${Env.getPrefix()}/intl-dada/${businessScope}/${landingPageVersion}/${pageId}.css`;
        const existJs = document.querySelector(`script[src*="${jsSrc}"`);
        const existCss = document.querySelector(`link[href*="${cssSrc}"`);
        if (!existJs) {
          loadScript(jsSrc);
        }
        if (!existCss) {
          loadStyle(cssSrc);
        }
        console.warn('LandingPage loaded', jsSrc);
      } catch {
        console.warn(`Failed to load by schema: ${store.schema}`);
      }
    },
  };
}

export default LandingPage;
