import { cloneJson, replaceObjectToken } from '@alife/intl-util';
import LayoutStore from 'src/stores';
import { getErrorMessage } from './getErrorMsg';
import { ValidateInfo } from './validateInfo';
import requestFunc from '../../tools/request';
import spreadOutValidateComps from '../../tools/spreadOutComps';

const DEFAULT_VALIDATE_INFO = {
  map: {},
  list: [],
};

const scrollById = id => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }
};

const VALIDATE_INFO_KEY = '_validateInfo';

export class Validator {
  store: LayoutStore;
  ruleMap = new Map<string, any[]>();
  autoScroll?: boolean;
  constructor(store, config: any = {}) {
    this.store = store;
    const rules = config.rules || config.scripts || [];
    this.autoScroll = config.autoScroll || true;
    rules.forEach(rule => {
      const exist = this.ruleMap.get(rule.name) || [];
      exist.push(rule);
      this.ruleMap.set(rule.name, exist);
    });
    store.changeElementData({
      name: VALIDATE_INFO_KEY,
      list: [],
      map: {},
    });
  }
  updateErrorData = (itemData, updateValidate) => {
    const store = this.store;
    const { componentKey } = itemData;
    if (!componentKey) {
      return;
    }
    const globalValidateInfo = store.getElementData(VALIDATE_INFO_KEY) || cloneJson(DEFAULT_VALIDATE_INFO);
    const validateMap = globalValidateInfo.map;
    const validateInfo: ValidateInfo = validateMap[componentKey] || new ValidateInfo(itemData);
    updateValidate(validateInfo);
    validateMap[componentKey] = validateInfo;
    const errorMessage = typeof validateInfo.message === 'string' ? validateInfo.message : 'error';
    store.changeElementDataWithoutHookBatch({
      [VALIDATE_INFO_KEY]: {
        activeKey: componentKey,
        map: validateMap,
        list: [...Object.values(validateMap)],
      },
      [componentKey]: {
        error: errorMessage,
        validateError: errorMessage,
        state: validateInfo.errorCount > 0 ? 'error' : '',
      },
    });
  };

  // {
  //   "name": "email",
  //   "messages": [
  //       {
  //           "type": "error",
  //           "content": "邮箱格式不正确"
  //       },
  //       {
  //           "type": "warning",
  //           "content": "请优先使用阿里邮箱"
  //       }
  //   ]
  // }
  updateRemoteValidateInfo = (remoteData: any = {}) => {
    const { name, messages } = remoteData;
    if (!name || !messages || messages.length < 1) {
      return;
    }
    const itemData = this.store.getElementData(name);
    if (!itemData) {
      return;
    }
    this.updateErrorData(itemData, (validateInfo: ValidateInfo) => validateInfo.addRemoteMessages(messages));
  };

  validateSingleField = itemData => {
    if (!itemData || !itemData.name || itemData.visible === false) {
      return;
    }
    const comp = this.store.componentMap.get(itemData.name);
    if (!comp) {
      return;
    }
    const customValidate = this.store.getComponentMethod(comp, 'validate');
    const rules = this.ruleMap.get(itemData.name);
    const hasRequired = rules && rules.find(rule => rule.required);
    const isRequiredValid = hasRequired ? this.store.validateItemRequired(itemData) : true;
    if (!isRequiredValid) {
      this.updateErrorData(itemData, (validateInfo: ValidateInfo) => validateInfo.addLocalErrorMessage(itemData.error));
      return;
    }
    const errorInfo = customValidate
      ? customValidate(itemData, rules)
      : getErrorMessage(itemData, rules || itemData.validateRules);
    const message =
      typeof errorInfo === 'string' ? errorInfo : errorInfo.validated === undefined ? errorInfo : errorInfo.message;
    this.updateErrorData(itemData, (validateInfo: ValidateInfo) => validateInfo.addLocalErrorMessage(message));
    if (!rules || message) {
      return;
    }
    const remoteRules = rules.filter(rule => rule.request);
    const replaceWithElementObj = replaceObjectToken(this.store.elementDataObj);
    remoteRules.forEach(rule => {
      const { request } = rule;
      const option = replaceWithElementObj(request);
      this.store.changeElementData({
        name: itemData.componentKey,
        state: 'loading',
      });
      requestFunc(option).then(res => {
        this.store.changeElementData({
          name: itemData.componentKey,
          state: '',
        });
        if (!res || !res.success || !res.data) {
          return;
        }
        const { data } = res;
        Array.isArray(data) ? data.forEach(this.updateRemoteValidateInfo) : this.updateRemoteValidateInfo(data);
      });
    });
  };

  validateAllFields = () => {
    this.store.elementDataMap.forEach(itemData => {
      this.validateSingleField(itemData);
    });
    const { list } = this.store.getElementData(VALIDATE_INFO_KEY);
    const firstError: ValidateInfo = list.find(item => item.errorCount > 0);
    if (firstError && this.autoScroll) {
      scrollById(firstError.elementId);
    }
    const firstLocalError = list.find(item => item.localErrorCount > 0);
    return firstLocalError;
  };

  validateCompsFields = (validateCompNames: string[]) => {
    const validateComps = spreadOutValidateComps(this.store.elementDataObj, validateCompNames);

    validateComps.forEach(itemData => {
      this.validateSingleField(itemData);
    });

    const { list } = this.store.getElementData(VALIDATE_INFO_KEY);

    const vlist = list
      .map((item: any) => {
        if (validateComps.find(comp => (comp.name || comp.componentKey) === (item.name || item.elementId))) {
          return item;
        }
        return null;
      })
      .filter(Boolean);

    const firstError: ValidateInfo = vlist.find(item => item.errorCount > 0);
    if (firstError) {
      scrollById(firstError.elementId);
    }
    const firstLocalError = vlist.find(item => item.localErrorCount > 0);
    return firstLocalError;
  };
}

export default Validator;
