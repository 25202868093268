import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { TypeItemData } from '../interfaces/index';
import { locale } from '../i18n';
import { observer } from 'mobx-react';
import { getDadaExpression } from '../tools/expression';
import { useLazyRender } from './lazy-render';

const localeCommonText = locale.dada.common;

const Container = subProps => {
  const { inited, onRef } = useLazyRender(subProps);
  const children = inited ? subProps.children : null;
  const { style } = subProps;
  return subProps.href ? (
    <a {...subProps} children={children} ref={onRef} style={style} />
  ) : (
    <div {...subProps} children={children} ref={onRef} style={style} />
  );
};

const labelStyleMap = {
  top: {
    margin: '0 0 4px 0',
  },
  right: {
    margin: '0 0 0 4px',
  },
  bottom: {
    margin: '4px 0 0 0',
  },
  left: {
    margin: '0 4px 0 0',
  },
};

interface StoreHocProps {
  itemData: TypeItemData;
  label: string;
  className: string;
  clazzName?: string;
  labelPosition: string;
  uiType: string;
  type: string;
  name: string;
  visible: boolean;
  containerHref?: string;
  containerTarget?: string;
  subLabel?: string;
  required?: boolean;
  formField?: boolean;
  tipsNode?: ReactNode;
  flex?: number;
  width?: string;
  labelSuffix?: ReactNode;
  labelLines: number;
  onClickFromStore?: (e, item) => void;
  noLabel: boolean;
}

const LabelPositionFlexMap = {
  top: {
    flexDirection: 'column',
  },
  bottom: {
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  right: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
};

const getLabelContainerStyle = props => ({
  display: props.visible ? 'flex' : 'none',
  ...LabelPositionFlexMap[props.labelPosition],
  ...props,
});

const RequiredMarkWhiteMap = {
  Input: true,
  TextArea: true,
  Select: true,
  TimePicker: true,
  DatePicker: true,
  DateRangePicker: true,
  LzdOssUpload: true,
  LzdNormalUpload: true,
  RichText: true,
  RadioGroup: true,
  CheckboxGroup: true,
  CheckboxGroupRichText: true,
  CheckBox: true,
  CascaderSelectDynamic: true,
  OssVideo: true,
  OssUpload: true,
  SuggestSelect: true,
};

const wrapLabel = TargetComponent => {
  const LabelHoc = (props: StoreHocProps) => {
    const { itemData, tipsNode, labelSuffix } = props;

    const {
      label,
      labelPosition = 'top',
      labelLines = 3, // max line to display
      name,
      type,
      uiType = '',
      visible: visibleExpression = true,
      containerHref,
      containerTarget = '_blank',
      clazzName = '',
      subLabel,
      required,
      requiredMarkType, // (symbolStart | symbolEnd | optionalStart | optionalEnd | none) for container
      formField, // mark component as a form field
      noLabel,
      componentKey,
    } = itemData;

    const visibleFunc = getDadaExpression(visibleExpression);
    const visible = typeof visibleFunc === 'function' ? visibleFunc({}, itemData) : visibleExpression;

    // filter hide
    const className = (props.className || '').replace(/ hide ?/, '');

    const isRequiredMarkEnabled = formField || RequiredMarkWhiteMap[uiType]; // newest required mark was make by pseudo elements

    return noLabel ? (
      <TargetComponent
        {...props}
        id={componentKey}
        className={classnames(clazzName, { hide: !visible }, componentKey)}
        // 大部分组件不消费id属性， componentKey作为标记添加到className中
        label={false}
      />
    ) : (
      <Container
        id={componentKey}
        uiType={uiType || type}
        className={classnames(
          `ui-item label-hoc parent-${name} clazzName-${clazzName} ${uiType ||
            type} ${className} hoc-label-${labelPosition}`,
          {
            isComponent: uiType,
            isContainer: !uiType,
            'with-link': containerHref,
            'empty-label': !label,
            hide: !visible,
            [`container-label-required-${requiredMarkType}`]: requiredMarkType,
          },
        )}
        style={getLabelContainerStyle({ labelPosition, label, uiType, visible })}
        href={containerHref}
        target={containerTarget}
      >
        {label && (
          <span
            className={classnames(`label-${labelPosition} label overflow-lines-${labelLines}`)}
            title={typeof label === 'string' ? label : undefined}
            style={
              label
                ? {
                    ...labelStyleMap[labelPosition],
                    lineHeight: '16px',
                  }
                : null
            }
          >
            <span
              className={classnames(
                'label-inner',
                isRequiredMarkEnabled
                  ? required
                    ? 'label-required-mark required'
                    : 'label-required-mark optional'
                  : '',
              )}
              data-text-optional={isRequiredMarkEnabled && `( ${(localeCommonText.optional || '').trim()} )`}
            >
              {typeof label === 'string' && (labelPosition === 'top' || labelPosition === 'bottom')
                ? label.replace(/(:|：)/, '')
                : label}
              {isRequiredMarkEnabled && required && <span className="dada-required" />}
              {isRequiredMarkEnabled && !required && (
                <span className="dada-optional"> ( {(localeCommonText.optional || '').trim()} ) </span>
              )}
            </span>
            {tipsNode}
            {subLabel ? <div className="dada-sub-label">{subLabel}</div> : null}
          </span>
        )}
        <TargetComponent {...props} className={`${clazzName} ${componentKey}`} label={false} />
        {labelSuffix}
      </Container>
    );
  };
  return observer(LabelHoc);
};

export default wrapLabel;
