import storeAction from './store-action';
import eventReaction from './event-reaction';
import windowAction from './window-action';
import postActionMessage from './post-action';

export default {
  eventReaction,
  storeAction,
  windowAction,
  postActionMessage,
};

export const actionsBuildinPlugins = [
  // {
  //   type: 'storeAction',
  // },
  {
    type: 'postActionMessage',
  },
];
