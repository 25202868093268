import LayoutStore from 'src/stores';

interface IOption {
  name: string;
  value: number;
}

interface StepConfig {
  nextAction: string;
  prevAction: string;
  options: IOption[];
  initValue: number;
  dataName: string;
}

const getStepData = (options: IOption[], newValue) => {
  const index = options.findIndex(i => i.value === newValue);
  return {
    hasPrev: index > 0,
    hasNext: index < options.length - 1,
    value: newValue,
    length: options.length,
  };
};

function StepPlugin(config: StepConfig) {
  const { nextAction, prevAction, options = [], initValue = 1, dataName } = config;
  return {
    name: 'step',
    init(store: LayoutStore) {
      store.pluginfyMethod(store, 'runAction');
      const stepData = getStepData(options, initValue);
      store.changeElementData({
        ...stepData,
        name: dataName,
      });
    },
    runAction(store, next, { args }) {
      const itemData = store.getElementData(dataName);
      const { value, noNext, noPrev } = itemData;
      const [actions] = args;
      const actionData = Array.isArray(actions) ? actions[0] : actions;
      if (!actionData) {
        return next();
      }
      const { eventType } = actionData;
      let newValue = value;
      if (eventType === nextAction && !noNext) {
        newValue = value + 1;
      } else if (eventType === prevAction && !noPrev) {
        newValue = value - 1;
      } else {
        return next();
      }
      const patch: any = {};
      patch[dataName] = getStepData(options, newValue);
      options.forEach(item => {
        patch[item.name] = { visible: item.value === newValue };
      });
      store.changeElementDataWithoutHookBatch(patch);
      next();
    },
  };
}

export default StepPlugin;
