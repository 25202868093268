import { computed } from 'mobx';
import { TypePlugin } from '../interfaces';

function PageDuration(): TypePlugin {
  return {
    name: 'PageDuration',
    init: store => {
      store.pageDuration = computed(() => Date.now() - performance.timing.navigationStart);
    },
  };
}

export default PageDuration;
