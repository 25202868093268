import helper from '../tools/helper';
import Tools from '../tools';
import { locale } from '../i18n';
import { toJS } from 'mobx';

const localeCommonText = locale.dada.common;

const enhanceStore = store => {
  store.scrollToError = () => {
    helper.scrollToElement('.has-error');
  };
  store.setItemError = (error = {}) => {
    Object.keys(error).forEach(key => {
      store.changeElementData({ error: error[key] }, key);
    });
    store.scrollToError();
  };
  store.validateItemRequired = itemData => {
    const item = toJS(itemData);
    const value = store.formData[item.name];
    const isEmpty = (!value && value !== 0 && value !== false) || (value && value.length === 0);
    const isValidated = !isEmpty || !item.required || item.readOnly || item.visible === false;
    item.error = isValidated ? '' : Tools.get(item, 'locale.required', localeCommonText.required);
    store.changeElementData(item);
    return isValidated;
  };
  store.checkRequired = () => {
    let hasError = false;
    for (const [, item] of store.elementDataMap) {
      const isValidated = store.validateItemRequired(item);
      hasError = hasError || !isValidated;
    }
    return !hasError;
  };
  store.cleanFormData = () => {
    for (const [, item] of store.schemaNode.nodeMap) {
      item.value = '';
      item.values = [];
    }
  };
};

/**
 * handle common action module
 * @param config
 */
function ActionHandler(config) {
  return {
    name: 'ActionHandler',
    init: store => {
      enhanceStore(store);
    },
  };
}
export default ActionHandler;
