function pathUrlParams(config) {
  return {
    name: 'pathUrlParams',
    init: store => {
      const { target } = config;

      const searchParams: any = new URL(location.href).searchParams;
      const searchParamsEntries = searchParams.entries();
      const urlParams = [...searchParamsEntries].reduce(
        (obj, [key, value]) => (key && value ? { ...obj, [key]: value } : obj),
        {},
      );

      if (target && Array.isArray(target)) {
        target.forEach(componentKey => {
          const newData = urlParams[componentKey];
          if (newData) {
            store.changeElementData({ value: newData }, componentKey);
          }
        });
      } else {
        Object.keys(urlParams).forEach(componentKey => {
          const newData = urlParams[componentKey];
          if (newData) {
            store.changeElementData({ value: newData }, componentKey);
          }
        });
      }
    },
  };
}

export default pathUrlParams;
