class Helper {
    scrollToTop() {
        requestAnimationFrame(() => {
            document.documentElement.scrollTop = 0;
        });
    }
    scroll(dom) {
        requestAnimationFrame(() => {
            dom.scrollIntoView();
        });
    }
    scrollToError() {
        requestAnimationFrame(() => {
            const hasError = document.querySelector('.has-error');
            if (hasError) {
                this.scroll(hasError);
            }
        });
    }
}

const helper = new Helper();

export default helper;
export { Helper };
