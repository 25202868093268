import { IGlobalEvents } from 'src/interfaces/eventReaction';
import { Store } from 'src/interfaces';
import DadaEvent from '../../stores/dada-event';
// import { inject } from '../../stores/container';
// import { CONST_IOC } from '../../constants/ioc';

/**
 * eg:
 * events: ["onresize", 'beforeunload]
 */
class WindowAction {
  store: Store;

  name = 'windowAction';

  events: string[];

  constructor(options: IGlobalEvents) {
    const { events = [] } = options;
    this.events = events;
  }

  init(store: Store) {
    this.store = store;
    this.events.forEach(event => {
      window.addEventListener(event, e => {
        this.store.eventObservable.dispatch(new DadaEvent(this.store, 'window', e.type));
      });
    });
  }
}

function WindowPlugin(options) {
  return new WindowAction(options);
}

export default WindowPlugin;
