import get from 'lodash/get';

/**
 *
 *    {
 *     "uiType": "Button",
 *     "type": "primary",
 *     "text": "submit"
 *     "name": "button"
 *     },
 *     {
 *     "type": "DialogContainer",
 *     "label": "submit",
 *     "trigger": "button",
 *     "style": "secondary",
 *     "title": "选择奖励",
 *     "height": "600px",
 *     "width": "1000px",
 *     "name": "dialog",
 *     "contentType": "search-page"
 *     },
 * @param config
 */
function DialogButtonElementReducer(config) {
  const dialogButtonMap = {};
  return {
    name: 'DialogReaction',
    init: store => {
      store.pluginfyMethod(store, 'onClick');
      store.pluginfyMethod(store, 'registerComponent');
    },
    registerComponent: (store, next, option) => {
      const component = get(option, 'args[0]') || {};

      next();

      if (!component || !component.props) {
        return;
      }
      const { itemData = {} } = component.props || {};
      const { trigger, type } = itemData;
      if (/Dialog/.test(type) && trigger) {
        dialogButtonMap[trigger] = itemData.name;
      }
    },
    onClick: (store, next, option) => {
      const componentName = get(option, 'args[1].name');
      // 数据回填
      const componentValue = get(option, 'args[1].value');
      const dialogName = dialogButtonMap[componentName];
      const dialogData = dialogName && store.getElementData(dialogName);
      if (dialogData) {
        dialogData.visible = !dialogData.visible;
        dialogData.formData = componentValue;
      }
      next();
    },
  };
}

export default DialogButtonElementReducer;
