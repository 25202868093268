import { computed, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { LoadingState } from '../constants';
import { TypeElement, TypeItemData } from '../interfaces';
import LayoutStore from '../stores/index';
import isEqual from 'lodash/isEqual';
import { isAnonymousNode } from '../tools/node';

interface StoreHocProps {
  layoutStore: LayoutStore;
  itemData: TypeItemData;
  label: string;
  className: string;
  TargetComponent: any;
  eventType?: string;
  eventParams?: any;
  loadingState?: LoadingState;
  hotload?: boolean;
  locale?: object;
  onChange?: (value) => void;
  elements?: TypeElement[];
  virtualSchema?: Record<string, any>;
  renderComponent?: any;
}

@inject('layoutStore')
@observer
class StoreHoc extends React.Component<StoreHocProps> {
  itemData;
  children;
  disposeArr: Array<() => void> = [];
  constructor(props: StoreHocProps) {
    super(props);
    const { itemData, layoutStore } = props;
    this.itemData = layoutStore.registerData(itemData);
    this.disposeArr.push(() => layoutStore.unRegisterData(this.itemData.componentKey));
    this.monitorChildrenUpdate(this.itemData);
  }

  componentWillReceiveProps(nextProps: StoreHocProps) {
    const { itemData, layoutStore } = nextProps;
    const { componentKey } = this.itemData;
    if (!isEqual(itemData, this.itemData)) {
      layoutStore.changeElementData(itemData, componentKey, false);
      const refData = layoutStore.getElementData(componentKey);
      this.itemData = refData || this.itemData; // itemData maybe controlled by parent component
      if (!this.itemData) {
        throw new Error(
          `The ${itemData.componentKey} is invalid, please check the duplated 'name' in dada schema`,
        );
      }
    }
  }

  componentWillUnmount() {
    const itemData = this.itemData;
    if (!isAnonymousNode(itemData)) {
      // 非匿名组件不注销，防止对话框内的表单输入数据丢失
      return;
    }
    let dispose: any;
    // tslint:disable-next-line:no-conditional-assignment
    while ((dispose = this.disposeArr.pop())) {
      dispose();
    }
  }

  monitorChildrenUpdate = itemData => {
    if (!itemData.elements) {
      return;
    }
    this.children = this.getChildren(itemData.elements);
    const cleanReaction = reaction(
      () => itemData.elements,
      newSchema => (this.children = this.getChildren(newSchema)),
    );
    this.disposeArr.push(cleanReaction);
  };

  getFormData = () => {
    const { layoutStore } = this.props;
    return layoutStore.getFormData();
  };

  renderComponent = (...args) => {
    const { itemData, layoutStore } = this.props;
    const renderComponentMethod = itemData.renderComponent || layoutStore.renderComponent;
    return renderComponentMethod ? renderComponentMethod.apply(this, args) : null;
  };

  @computed get locale() {
    const { locale, itemData } = this.props;
    return { ...locale, ...itemData.locale };
  }

  getChildren(schema) {
    const renderComponent = this.props.layoutStore.renderComponent;
    return Array.isArray(schema) ? schema.map(renderComponent) : renderComponent(schema, 0);
  }

  getComponentMapOrigin = componentName => this.props.layoutStore.componentMapOrgin[componentName];

  render() {
    const itemData = this.itemData; // 使用本地对象代替属性，确保itemData被监听，并且不会被父组件重置
    const { layoutStore, TargetComponent } = this.props;
    const { appDom } = layoutStore;
    const { uiType, type, className } = itemData;

    return (
      <TargetComponent
        componentKey={itemData.componentKey || itemData.name}
        _dadaCoreVersion={layoutStore.version}
        children={this.children}
        popupContainer={appDom}
        locale={this.locale}
        itemData={itemData}
        uiType={uiType || type}
        className={className}
        renderComponent={this.renderComponent}
        render={layoutStore.renderComponent}
        layoutStore={layoutStore}
        runAction={layoutStore.runAction}
        getComponentByUiType={this.getComponentMapOrigin}
        // formData如果直接在这里调用，会遍历使用到elementMap中的所有引用，导致任意一个组件的值改变都会触发其他组件渲染
        getFormData={this.getFormData}
        showMessage={layoutStore.showMessage}
      />
    );
  }
}

const wrapStore = TargetComponent => {
  const ObComp = observer(TargetComponent);
  return props => <StoreHoc {...props} TargetComponent={ObComp} />;
};

export default wrapStore;
