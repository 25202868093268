import { set } from '@alife/intl-util';
import { EmptyObj } from '../constants/index';
import { get, wrapCatch } from '@alife/intl-util';
import { getDadaExpression } from '../tools/expression';

export const getValueByPathRaw = (elementDataObj, valuePath, self) => {
  if (!elementDataObj) return;
  const type = typeof valuePath;
  if (type === 'string') {
    return get(elementDataObj, valuePath);
  } else if (type === 'function') {
    return valuePath(elementDataObj, self);
  } else if (Array.isArray(valuePath)) {
    return valuePath.map(subPath => get(elementDataObj, subPath)).join('') || valuePath;
  }
  return valuePath;
};

export const getValueByPath = wrapCatch(getValueByPathRaw, undefined);

const pathRegex = /\$\{([\w|\.]+)\}/;

const EXPRESSION_FUNC_MAP = {
  string: expression => {
    if (!pathRegex.test(expression)) return;
    const allPathTokens = expression.split(pathRegex).filter(token => token.length > 0);
    return elementData => allPathTokens.map(subPath => get(elementData, subPath)).join('') || expression;
  },
  object: expression => {
    return getDadaExpression(expression);
  },
};

const getExpressionRaw = expression => {
  const func = EXPRESSION_FUNC_MAP[typeof expression];
  return func ? func(expression) : undefined; // 未匹配返回空表示不订阅
};

export const getExpression = wrapCatch(getExpressionRaw, false);

type TypeFunc = (data: any) => any;

export const getRefParams = (refParamsPathMap: Map<string, string | TypeFunc>, elementDataObj): any => {
  if (!refParamsPathMap || refParamsPathMap.size === 0) {
    return EmptyObj;
  }
  const refParams = {};
  refParamsPathMap.forEach((valuePath, keyPath) => {
    if (typeof keyPath !== 'string') return;
    const value = getValueByPath(elementDataObj, valuePath);
    set(refParams, keyPath, value);
  });
  return refParams;
};

export const getRefParamsPathMap = itemData => {
  const pathMap = new Map();
  const { paramsMapping, uiType, ...rest } = itemData;
  if (!uiType) return pathMap; // 非组件属性暂时不监听
  if (paramsMapping && Object.keys(paramsMapping).length > 0) {
    Object.entries(paramsMapping).forEach(([keyPath, valuePath]) => {
      if (typeof keyPath === 'string') {
        pathMap.set(keyPath, valuePath);
      }
    });
  }
  Object.entries(rest).forEach(([keyPath, valuePath]) => {
    if (typeof keyPath !== 'string') return;
    const runTimeExpression = getExpression(valuePath);
    if (typeof runTimeExpression === 'function') {
      pathMap.set(keyPath, runTimeExpression);
    }
  });
  return pathMap;
};
