import i18n from '@alife/mcms_dada_common';
import { getCurrentLanguage, getCookieLanguage, flatObj, treeObj } from '@alife/intl-util';

const locale = {
  // copy from next
  momentLocale: 'en',
  Timeline: {
    expand: 'Expand',
    fold: 'Fold',
  },
  Balloon: {
    close: 'Close',
  },
  Card: {
    expand: 'Expand',
    fold: 'Fold',
  },
  Calendar: {
    today: 'Today',
    now: 'Now',
    ok: 'OK',
    clear: 'Clear',
    month: 'Month',
    year: 'Year',
    prevYear: 'Previous Year',
    nextYear: 'Next Year',
    prevMonth: 'Previous Month',
    nextMonth: 'Next Month',
    prevDecade: 'Previous Decade',
    nextDecade: 'Next Decade',
    yearSelectAriaLabel: 'Select Year',
    monthSelectAriaLabel: 'Select Month',
  },
  DatePicker: {
    placeholder: 'Select Date',
    datetimePlaceholder: 'Select Date And Time',
    monthPlaceholder: 'Select Month',
    yearPlaceholder: 'Select Year',
    now: 'Now',
    selectTime: 'Select Time',
    selectDate: 'Select Date',
    ok: 'OK',
    clear: 'Clear',
    startPlaceholder: 'Start Date',
    endPlaceholder: 'End Date',
    hour: 'H',
    minute: 'M',
    second: 'S',
  },
  Dialog: {
    close: 'Close',
    ok: 'OK',
    cancel: 'Cancel',
  },
  Message: {
    closeAriaLabel: 'Close',
  },
  Pagination: {
    prev: 'Previous',
    next: 'Next',
    goTo: 'Go to',
    page: 'Page',
    go: 'View',
    total: 'Page {current}, {total} pages',
    labelPrev: 'Previous page, current page {current}',
    labelNext: 'Next page, current page {current}',
    inputAriaLabel: 'Please enter what page to skip to',
    selectAriaLabel: 'Please select how many items are displayed on each page',
    pageSize: 'Items per page:',
  },
  Input: {
    clear: 'Clear',
  },
  Select: {
    selectPlaceholder: 'Please Select',
    autoCompletePlaceholder: 'Please Input',
    notFoundContent: 'No Options',
  },
  Table: {
    empty: 'No Data',
    ok: 'OK',
    reset: 'Reset',
    asc: 'Ascending Order',
    desc: 'Descending Order',
    expanded: 'Expanded',
    folded: 'Folded',
    filter: 'Filter',
    selectAll: 'Select All',
  },
  TimePicker: {
    placeholder: 'Select Time',
    clear: 'Clear',
    hour: 'H',
    minute: 'M',
    second: 'S',
  },
  Transfer: {
    items: 'items',
    item: 'item',
    moveAll: 'Move All',
    searchPlaceholder: 'Please Input',
    moveToLeft: 'Uncheck Selected Elements',
    moveToRight: 'Submit Selected Elements',
  },
  Upload: {
    card: {
      cancel: 'Cancel',
      addPhoto: 'Add Picture',
    },
    drag: {
      text: 'Click or Drag the file to this area to upload',
      hint: 'Support docx, xls, PDF, rar, zip, PNG, JPG and other files upload',
    },
  },
  Search: {
    buttonText: 'Search',
  },
  Tag: {
    delete: 'Delete',
  },
  Rating: {
    description: 'Rating Options',
  },
};

const windowLocale = ((window as any).dada_common || {})[getCookieLanguage()];
const localeLanguage = i18n[getCurrentLanguage()];

const flatLocale = flatObj(locale);
const mergedLocale = { ...flatLocale, ...(windowLocale || localeLanguage) };
const treeLocale = treeObj(mergedLocale);

export default treeLocale;
