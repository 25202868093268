import { Store, TypeElement } from 'src/interfaces';
import DadaEvent, { DadaEventTypes } from '../../stores/dada-event';
import catchException from '../../tools/trycatch';

class EmitAction {

  name = 'emitAction';

  store: Store;

  init(store: Store) {
    this.store = store;
    Object.keys(DadaEventTypes).forEach(key => this.addEvent(DadaEventTypes[key]));
  }

  private addEvent(eventType) {
    this.store.eventObservable.subscribe('store', eventType, {
      listener: this.postMessage,
    });
  }


  @catchException()
  private postMessage(e: DadaEvent) {
    const { target, eventType, params = [] } = e;
    if (!target) {
      return;
    }

    const { props } = target as React.Component<TypeElement>;
    let payload = params.reduce((prev, next) => {
      return Object.assign({}, prev, next);
    }, {})

    if (props && props.itemData) {
      payload = JSON.parse(JSON.stringify(props.itemData || {}));
      window.postMessage({
        event: eventType,
        payload
      }, '/');
    }
  }
}

function emitActionPlugin() {
  return new EmitAction();
}

export default emitActionPlugin;
