import { createSandBox, isJSFunction, registerRequestFilter, registerResponseFilter } from '@alife/intl-util';

const callTransform = (func: any) => {
  if (typeof func === 'function') {
    return func;
  }

  if (isJSFunction(func)) {
    return createSandBox({})(func);
  }

  return func;
};

// {
//   type: 'requestFilter',
//   requestFilter: [(request) => { console.log(request); return request; }],
//   responseFilter: [(response) => { console.log(response); return response; }],
// }
function requestFilerPlugin(options = {}) {
  return {
    name: 'requestFiler',
    init: async () => {
      const { requestFilter = [], responseFilter = [] } = options as { requestFilter?: any[]; responseFilter?: any[] };
      if (requestFilter && requestFilter.length) {
        requestFilter.forEach(item => {
          registerRequestFilter(callTransform(item));
        });
      }

      if (responseFilter && responseFilter.length) {
        responseFilter.forEach(item => {
          registerResponseFilter(callTransform(item));
        });
      }
    },
  };
}

export default requestFilerPlugin;
