import { toJS } from 'mobx';

function ConvertData(config, func) {
  const updateStoreFunction = (store, itemData = null, componentKey = null) => {
    try {
      const newData = toJS(itemData || store.schema, { recurseEverything: true });
      const hasChanged = func(newData);
      if (!hasChanged) {
        return;
      }
      if (componentKey) {
        store.changeElementData(newData, componentKey);
      } else {
        store.mergeSchema(newData);
      }
    } catch (e) {
      console.error('ConvertFunc Failed', e);
    }
  };
  const getNewData = (store, itemData) => {
    const newData = toJS(itemData, { recurseEverything: true });
    try {
      func(newData);
    } catch (e) {
      return itemData;
    }
    return newData;
  };
  return {
    name: 'ConvertFunc',
    init: store => {
      store.pluginfyMethod(store, 'setData');
      store.pluginfyMethod(store, 'mergeSchema');
      store.pluginfyMethod(store, 'changeElementData');
      updateStoreFunction(store);
    },
    setData(store, next, option) {
      next();
      updateStoreFunction(store);
    },
    mergeSchema(store, next, option) {
      next();
      updateStoreFunction(store);
    },
    changeElementData(store, next, option) {
      const { args } = option;
      const [itemData] = args;
      const newData = getNewData(store, itemData);
      Object.assign(itemData, newData);
      next();
    },
  };
}

export default ConvertData;
