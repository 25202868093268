import { Plugins } from '@alife/dada-template-core';
import HistoryDataConvert from './history-data-convert';
import AutoSave from './auto-save';
import goldlogParams from './goldlogParams';
import removeError from './removeError';

export const PluginMap = { ...Plugins.PluginMap };
PluginMap.elementReducer = PluginMap.elementChange;
PluginMap.historyDataConvert = HistoryDataConvert;
PluginMap.AutoSave = AutoSave;
PluginMap.goldlogParams = goldlogParams;
PluginMap.removeError = removeError;

export const buildinPlugins = Plugins.mergePluginUniq(
  ...Plugins.buildinPlugins,
  {
    type: 'historyDataConvert',
  },
);
