import { TypePlugin } from '../interfaces';
import Tools from '../tools';
import Url from '@alife/intl-util/lib/pure-functions/urls';
interface IPagination {
  type: 'pagination';
  target: string;
  paginationName?: string;
  extendParams?: () => any;
}

interface IPageInfoResponse {
  current?: number;
  pageSize?: number;
  total?: number;
}

function Pagination(config: IPagination): TypePlugin {
  const { target, extendParams } = config;
  let { paginationName } = config;
  let pageStore: any = null;

  return {
    name: 'pagination',
    init: store => {
      store.pluginfyMethod(store, 'changeElementData');
      store.pluginfyMethod(store, 'reloadComponent');
      pageStore = store;

      if (!paginationName) {
        const { elementDataObj } = store;
        const foundKey = Object.keys(elementDataObj).find(key => elementDataObj[key].uiType === 'Pagination');
        if (foundKey) {
          paginationName = foundKey;
        }
      }
    },
    getPaginationParams: store => {
      const pageItemData = store.getElementData(paginationName) || ({} as any);
      const { pageSize, current, total, isAppend } = pageItemData;

      const pageParams = { pageSize, current, total, isAppend };

      try {
        const pageUrlParams = JSON.parse(Url.getQueryString(paginationName));
        Object.assign(pageParams, pageUrlParams);
      } catch (e) {
        // no exception
      }

      return pageParams;
    },

    changePagination(pageInfo: IPageInfoResponse) {
      pageInfo = Tools.pickBy(
        {
          pageSize: pageInfo.pageSize,
          current: pageInfo.current,
          total: pageInfo.total,
        },
        val => val !== undefined,
      );
      if (!Object.keys(pageInfo).length) {
        return;
      }
      // update page info, if request return pageInfo
      pageStore.changeElementDataWithoutHook(pageInfo, paginationName);
    },

    handlerReloadResponse(res: { data: any; pageInfo?: IPageInfoResponse }) {
      const pageInfo: IPageInfoResponse = Tools.get(res, 'pageInfo', Tools.get(res, 'data.pageInfo', {}));

      return this.changePagination(pageInfo);
    },

    // @Inject to store
    async reloadComponent(store, next, { args }) {
      const [itemData, params = {}] = args;
      const { componentKey } = itemData;
      if (componentKey !== target) {
        return await next();
      }

      const requestParams = this.getPaginationParams(store);

      Object.assign(requestParams, params);
      Tools.copy(params, requestParams);

      const tableReloadResult = await next();

      this.handlerReloadResponse(tableReloadResult);

      return tableReloadResult;
    },

    // @Inject to store
    async changeElementData(store, next, { args = [] }) {
      const [newData, componentKey] = args;
      const pageItemData = store.getElementData(componentKey) || ({} as any);
      const { uiType } = pageItemData;
      await next();
      // only hook the change of pagination component
      if (uiType !== 'Pagination' || componentKey !== paginationName) {
        return;
      }

      // filter first value change
      // @ts-ignore
      const { value = {}, ...nextProps } = newData || {};
      if (!Object.values(nextProps).length) {
        return;
      }

      const listItemData = store.getElementData(target);
      if (!listItemData) {
        console.warn('not found the pagination target');
        return;
      }

      // update page info before request
      store.changeElementDataWithoutHook(nextProps, componentKey);

      const paginationParams = this.getPaginationParams(store);
      const isAppend = paginationParams.isAppend;

      delete paginationParams.isAppend;

      const reloadParams = Object.assign(paginationParams, extendParams ? extendParams() : {}, {
        isAppend,
        fromPagination: true,
      });

      await store.reloadComponent(listItemData, reloadParams);
    },
  };
}
export default Pagination;
