import React from 'react';
import get from 'lodash/get';
import { ErrorBoundary, wrapCatch } from '@alife/intl-util';
import DadaEvent, { DadaEventTypes } from '../stores/dada-event';
import { inject } from 'mobx-react';
import { Store } from 'src/interfaces';
import { logEvent } from '../tools/loginfo';
import { EventType } from '../constants/event';

interface IProps {
  TargetComponent: any;
  layoutStore: Store;
}

const stringify = wrapCatch(JSON.stringify);

@inject('layoutStore')
class Error extends React.Component<IProps> {
  onError = (err, props) => {
    const { layoutStore } = this.props;
    const itemData = get(props, ['children', 'props', 'itemData'], {});
    const logError = get(window, ['DadaAplus', 'goldlogError'], console.error);
    logError({
      type: itemData.uiType || itemData.type,
      name: itemData.name,
      error: stringify(err),
    });
    logEvent({
      eventType: EventType.onComponentError,
      eventTarget: itemData.name || itemData.componentKey,
      c1: err,
      c2: stringify(itemData),
      extra: {
        k: 'e',
        arms: itemData.arms,
      },
    });
    if (!layoutStore || !layoutStore.eventObservable) {
      return;
    }
    layoutStore.eventObservable.dispatch(
      new DadaEvent(layoutStore, itemData.componentKey, DadaEventTypes.onComponentError),
    );
  };

  render() {
    const { TargetComponent } = this.props;
    return (
      <ErrorBoundary onError={this.onError}>
        <TargetComponent {...this.props} />
      </ErrorBoundary>
    );
  }
}

const wrapErrorBoundary = WrappedComponent => props => <Error {...props} TargetComponent={WrappedComponent} />;

export default wrapErrorBoundary;
