import pluginfy from './pluginfy';
import pageDuration from './page-duration';
import elementChange from './element-reducer';
import ElementDataReducer from './element-change-beta';
import buttonReaction from './button-reaction';
import historyDataConvert from './history-data-convert';
import elementValuesSync from './element-values-sync';
import actionHandler from './actions';
import dataChangeReaction from './data-change-reaction';
import dialogReaction from './dialog-reaction';
import watchReaction from './watch-reaction';
import landingPage from './landing-page';
import convertFunction from './convert-func';
import convertNumber from './convert-number';
import goldlog from './goldlog-hook';
import form from './form';
import validator from './validator';
import scrollbar from './scrollbar';
import replaceLink from './replace-link';
import lazyLoad from './lazy-load';
import pagination from './pageination';
import tableLogic from './table-logic';
import patchRemoteSchema from './remote-patch';
import virtualComponentRegister from './virtual-component-register';
import confirmHook from './confirm-hook';
import reaction, { actionsBuildinPlugins } from './reaction';
import pathUrlParams from './patch-url-params';
import requestFilter from './request-filter';
import removeError from './removeError';
import CustomAction from './custom-actions';
import workflow from './workflow';
import pageInfo from './page-info';
import step from './step';

export type IPlugin = {
  type: string;
} & Record<string, any>;

const PluginMap = {
  customActions: CustomAction,
  pluginfy,
  pageDuration,
  pagination,
  elementChange,
  ElementDataReducer,
  buttonReaction,
  historyDataConvert,
  actionHandler,
  elementValuesSync,
  dataChangeReaction,
  dialogReaction,
  watchReaction,
  landingPage,
  convertFunction,
  convertNumber,
  goldlog,
  form,
  validator,
  scrollbar,
  replaceLink,
  lazyLoad,
  tableLogic,
  initData: patchRemoteSchema,
  pathUrlParams,
  virtualComponentRegister,
  confirmHook,
  requestFilter,
  workflow,
  ...reaction,
  removeError,
  pageInfo,
  step,
};

const registerPlugin = (name, plugin) => {
  PluginMap[name] = plugin;
};

const getPlugin = () => {
  return PluginMap;
};

export const buildinPlugins = [
  {
    type: 'pluginfy',
  },
  {
    type: 'historyDataConvert',
  },
  {
    type: 'dialogReaction',
  },
  {
    type: 'landingPage',
  },
  {
    type: 'actionHandler',
  },
  {
    type: 'scrollbar',
  },
  {
    type: 'pageInfo',
  },
  {
    type: 'validator',
  },
  ...actionsBuildinPlugins,
];

export const mergePluginUniq = (...plugins: IPlugin[]) => {
  const existPlugin: string[] = [];
  return plugins
    .reverse()
    .filter(plugin => !existPlugin.includes(plugin.type) && existPlugin.push(plugin.type))
    .reverse();
};

export const mergePluginsOverwriteDefault = (optionsPlugins: IPlugin[] = [], defaultPlugins: IPlugin[] = []) => {
  const pluginNames = optionsPlugins.map(plugin => plugin.type);
  const result = [...optionsPlugins];
  defaultPlugins.forEach(defaultPlugin => {
    const { type } = defaultPlugin;
    if (!pluginNames.includes(type)) {
      result.push(defaultPlugin);
    }
  });
  return result;
};

export { registerPlugin, getPlugin, PluginMap };
