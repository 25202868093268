import debounce from 'lodash/debounce';

function DataChangeReaction(config) {
  const { include = [], exclude = [], action, actions = [], delay = false } = config;
  if (actions.length === 0 && action) {
    actions.push(action);
  }

  let doRunActions = store => store.runAction(actions);

  if (delay !== false) {
    doRunActions = debounce(doRunActions, delay);
  }

  return {
    name: 'DataChangeReaction',
    init: store => {
      store.pluginfyMethod(store, 'changeElementData');
    },
    changeElementData: async (store, next, option) => {
      await next();
      const { args } = option;
      const componentKey = args[1];
      if (
        (include.length > 0 && !include.includes(componentKey)) ||
        (exclude.length > 0 && exclude.includes(componentKey))
      ) {
        return;
      }
      doRunActions(store);
    },
  };
}

export default DataChangeReaction;
