import { ERROR, SUCCESS, WARNING } from '../../constants/index';
import { toJS } from 'mobx';
import sendRequest from './send-request';
import openUrl from './open-url';
import showMessage from './show-message';
import request from './request';
import validator from './validator';
import checkRequired from './required';
import sendLog from './log';
import { getChildNames } from '../util';
import { logDumpedInfo } from './logDumpedInfo';
import { recordTimeStart, recordTimeEnd } from './log';
import { registerAction } from './register-action';
import { submit } from './submit';
import Tools from '../../tools';

export const ActionMap = {
  /**
   * Warning Message
   */
  warn: (store, data) => {
    logDumpedInfo('warn', 'toast', {
      eventType: 'toast',
      type: 'warn',
    });
    store.setGlobalMessage(data.content || data.value, WARNING, '');
  },
  /**
   * Error Message
   */
  error: (store, data) => {
    logDumpedInfo('error', 'toast', {
      eventType: 'toast',
      type: 'error',
    });
    store.setGlobalMessage(data.content || data.value, ERROR, '');
  },
  success: (store, data) => {
    logDumpedInfo('success', 'toast', {
      eventType: 'toast',
      type: 'success',
    });
    store.setGlobalMessage(data.content || data.value, SUCCESS, '');
  },
  redirect: (store, data) => {
    logDumpedInfo('redirect', 'openUrl', {
      eventType: 'openUrl',
      title: 'redirecting',
      duration: 3000,
      href: 'https://www.taobao.com',
    });
    return showMessage(store, data).then((res: any) => openUrl(store, res.data));
  },
  link: (store, data) => {
    logDumpedInfo('link', 'openUrl', {
      eventType: 'openUrl',
      href: 'https://www.taobao.com',
    });
    return openUrl(store, data);
  },
  reset(store, data) {
    const { names = '*' } = data;
    let componentKeys: string[] = [];
    if (names === '*') {
      componentKeys = componentKeys.concat([...store.elementDataMap.keys()]);
    } else {
      componentKeys = componentKeys.concat(names);
    }

    componentKeys = componentKeys.reduce(
      (previousValue, currentValue) => previousValue.concat(getChildNames(store.elementDataMap.get(currentValue))),
      [],
    );

    componentKeys.forEach(key => {
      store.changeElementData({ value: store.elementDataMap.get(key).defaultValue }, key);
    });
  },
  openUrl: (store, data) => openUrl(store, { ...data, target: data.target || '_blank' }),
  successLink: (store, data) => {
    logDumpedInfo('successLink', 'openUrl', {
      eventType: 'openUrl',
      title: 'Success!',
      duration: 3000,
      href: 'https://www.taobao.com',
    });
    store.setGlobalMessage(data.value, SUCCESS, '');
    return openUrl(store, data);
  },
  refresh: (store, data) => showMessage(store, data).then((res: any) => openUrl(store, res.data)),
  render: (store, data) => {
    logDumpedInfo('render', 'patchSchema');
    store.setData(data.render);
  },
  closeDialog: (store, data, callbackSubmit) => {
    logDumpedInfo('closeDialog', 'patchSchema');
    return callbackSubmit(data);
  },
  toastError: (store, data) => {
    logDumpedInfo('toastError', 'toast', {
      eventType: 'toast',
      type: 'error',
      title: 'Something goes wrong!',
      duration: 3000,
    });
    return showMessage(store, { type: 'error', value: data.value || data.content || 'Error' });
  },
  toast: (store, data) =>
    showMessage(store, {
      type: data.type || 'success',
      value: data.value || data.content || 'Success',
      hasMask: false,
      duration: data.duration || 1000,
    }),
  itemError: (store, data) => {
    logDumpedInfo('itemError', 'patchSchema');
    return store.setItemError(data.errors);
  },
  elementError: (store, data) => {
    logDumpedInfo('elementError', 'patchSchema');
    return store.setItemError(data.errors);
  },
  none: () => null,
  elementChange: (store, data) => {
    logDumpedInfo('elementChange', 'patchSchema');
    data.elements.forEach(element => {
      store.changeElementData(element, element.name);
    });
    return Promise.resolve();
  },
  patchSchema: (store, data) => {
    if (!data || !Array.isArray(data.patchs)) {
      return Promise.reject();
    }
    data.patchs.forEach(patch => {
      store.changeElementData(patch, patch.name);
    });
    return Promise.resolve();
  },
  back: (store, data) => {
    logDumpedInfo('back', 'goBack', {
      eventType: 'goBack',
      href: 'https://www.taobao.com',
    });
    if (history.length > 1) {
      history.back();
    } else {
      location.href = '/';
    }
  },
  goBack: (store, data) => {
    if (history.length > 1) {
      history.back();
    } else {
      location.href = data.href || '/';
    }
  },
  request: (store, data) => {
    logDumpedInfo('request', 'sendRequest', {
      eventType: 'sendRequest',
      request: {
        url: 'https://www.taobao.com',
      },
    });
    return request(store, data);
  },
  sendRequest,
  submit,
  patchParentSchema: (store, data) => {
    if (!data || !Array.isArray(data.patchs)) {
      return;
    }
    data.patchs.forEach(patch => {
      store.changeParentElementData(toJS(patch), patch.name);
    });
  },
  parentElementChange: (store, data) => {
    logDumpedInfo('parentElementChange', 'patchParentSchema');
    const elements: any = data.eventParams ? data.eventParams.elements : data.elements;
    elements.forEach(element => {
      store.changeParentElementData(toJS(element), element.name);
    });
  },
  checkRequired: (store, data) => checkRequired(store, data),
  validator: (store, data) => validator(store, data),
  sendLog: (store, data) => sendLog(data),
  recordTimeStart,
  recordTimeEnd,
  registerAction,
  wait: (store, data) => Tools.promiseTimeout(data.duration || data.delay),
};
