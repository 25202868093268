import { IReactionDisposer, IReactionOptions, reaction } from 'mobx';
import get from 'lodash/get';
import { createSandBox, ISandBoxCodeBlock } from '@alife/intl-util';

interface IScript {
  expression: string | ISandBoxCodeBlock;
  effect: string | ISandBoxCodeBlock;
  options?: IReactionOptions;
}

export interface IWatchReaction {
  type: 'watchReaction';
  scripts: IScript | IScript[];
}

/**
 * Plugin what can reaction any Mobx observable variable, and effect scripts
 *
 * "plugins": [
 *     {
 *      "type": "watchReaction",
 *      "scripts": [
 *        {
 *          "expression": "store.getElementData('echarts').option.series[0].data.length === 0",
 *          "effect": "store.getElementData('message').visible = effectResult"
 *        },
 *        {
 *          "expression": "store.getElementData('echarts').option.series[0].data.length > 0",
 *          "effect": "store.getElementData('echarts').visible = effectResult"
 *        }
 *      ]
 *    }
 * ]
 */
function WatchReaction(config: IWatchReaction) {
  let { scripts } = config;
  const disposeArr: IReactionDisposer[] = []; // reaction should be add to disposeArr
  return {
    name: 'watchReaction',
    init: store => {
      // support array scripts
      if (!(scripts instanceof Array)) {
        scripts = [scripts];
      }

      const { elementDataObj } = store;

      disposeArr.push(
        ...scripts.map(script => {
          const { expression = '', effect = '', options } = script;

          const sandBoxRunner = createSandBox({ store, elementDataObj, model: elementDataObj });

          const expressionFn =
            typeof expression === 'string'
              ? new Function('model', 'store', 'elementDataObj', `return ${expression}`)
              : sandBoxRunner(expression);

          const effectFn =
            typeof effect === 'string'
              ? new Function('effectResult', 'model', 'store', 'elementDataObj', effect)
              : sandBoxRunner(effect);

          return reaction(
            () => expressionFn(elementDataObj, store, elementDataObj),
            effectResult => effectFn(effectResult, elementDataObj, store, elementDataObj),
            {
              fireImmediately: true,
              onError: error => {
                // if store modules didn't ready ,skip warning
                if (!get(store, 'schema.modules.length')) {
                  return;
                }
                console.warn('[plugin][watchReaction] run script error \n', error);
              },
              ...options,
            },
          );
        }),
      );
    },
    destroy: () => {
      while (disposeArr.length) {
        const disposeFn = disposeArr.pop();
        // tslint:disable-next-line:no-unused-expression
        disposeFn && disposeFn();
      }
    },
  };
}

export default WatchReaction;
