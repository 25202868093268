import { Tools } from '@alife/dada-template-core';

const fixFormDataToValue = schema => {
  if (!schema || !schema.formData) {
    return schema;
  }
  const { formData } = schema;
  Tools.forEachDadaElement(schema, elementData => {
    if (formData.hasOwnProperty(elementData.name)) {
      elementData.value = formData[elementData.name];
      delete formData[elementData.name];
    }
  });
  if (!Object.keys(schema.formData).length) {
    delete schema.formData;
  }

  return schema;
};

export default fixFormDataToValue;
