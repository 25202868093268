import { TypeElement } from '../interfaces';
import LayoutStore from '../stores';
import * as React from 'react';
import Tools from '../tools';
import { replaceObjectTokenWithExpress } from '../tools/regex';

type IVirtualComponents = Record<string, TypeElement | TypeElement[]>;

interface IPluginProps {
  components: IVirtualComponents;
}

declare module '../interfaces' {
  interface TypeSchema {
    virtualComponents?: IVirtualComponents;
  }
}

const DEFAULT_OPTIONS: IPluginProps = {
  components: {},
};

/**
 * eg.
 * {
 *   type: "virtualComponentRegister",
 *   targetName: "table",
 *    "components": {
 *       "ProductInfo": {
 *         "uiType": "InlineContainer",
 *         "elements": [
 *           {
 *             "uiType": "Image",
 *             "content": "${img}"
 *           },
 *           {
 *             "uiType": "Text",
 *             "content": "${productName}"
 *           },
 *           {
 *             "uiType": "Text",
 *             "label": "lasterOrder",
 *             "content": "${lasterOrder.id}"
 *           }
 *         ]
 *       }
 *     }
 * }
 */
class VirtualComponentRegister {
  name: 'VirtualComponentRegister';
  private options: IPluginProps;
  // @ts-ignore
  private store: LayoutStore;
  private virtualComponents: IVirtualComponents;

  constructor(options: IPluginProps) {
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
  }

  init = store => {
    this.store = store;
    this.virtualComponents = this.getVirtualComponents();
    // this.appendComponents();
    store.pluginfyMethod(store, 'registerComponentMap');
    // store.pluginfyMethod(store, 'reloadComponent');
  };

  // @Inject to store
  async registerComponentMap(store, next, { args }) {
    this.extendVirtualComponent(args[0] || {});

    await next();
  }

  private extendVirtualComponent = Tools.once(async componentMap => {
    Object.entries(this.virtualComponents).forEach(([key, item]) => {
      componentMap[key] = this.createVirtualReactComponent(key, item);
    });
  });

  private getVirtualComponents() {
    // const { virtualComponents = {} } = this.store.schema;

    // return Object.assign({}, this.options.components, virtualComponents);
    return Object.assign({}, this.options.components);
  }

  private createVirtualReactComponent(key, itemData) {
    const virtualComponent = props => {
      const { renderComponent } = props;

      if (Tools.isArray(itemData)) {
        return React.createElement(
          React.Fragment,
          {},
          itemData.map(() => virtualComponent(props)),
        );
      }

      const replacer = replaceObjectTokenWithExpress(props);
      const fns = Tools.pickBy(props, Tools.isFunction);

      return renderComponent(Object.assign(fns, replacer(itemData)));
    };

    virtualComponent.displayName = key;

    return virtualComponent;
  }
}

function virtualComponentRegister(options: IPluginProps) {
  return new VirtualComponentRegister(options);
}

export default virtualComponentRegister;
