import { toJS } from 'mobx';
import { pipe } from '@alife/intl-util';
import { flatEventParams } from '../../tools/flatAction';
import sendRequest from './send-request';
import { TypeItemData, IAction } from '../../interfaces';
import { IRunTimeAction } from '../../interfaces';
import { logEvent } from '../../tools/loginfo';
import { logDumpedInfo } from './logDumpedInfo';
import { ActionMap } from './ActionMap';
import { replaceObjectTokenWithExpress } from './../../tools/regex';

type TypeMixedAction = TypeItemData | IAction;

function executeAction(store, eventOptionOb: TypeMixedAction): Promise<any> {
  const { ctx = {}, ...optionOb } = eventOptionOb;
  const eventOption = toJS(optionOb, { recurseEverything: true });
  const { eventType, eventTarget, ...restOptions } = eventOption || ({} as any);
  if (!eventType) {
    return Promise.resolve();
  }
  const eventParams = flatEventParams(store, { ...restOptions, ctx });

  logEvent({
    actionType: eventType,
    actionTarget: eventTarget || 'Store',
    c1: eventParams,
    extra: {
      k: 'a',
      arms: eventParams && eventParams.arms,
    },
  });

  const actionMap = Object.assign({}, ActionMap, store.actionMap);

  if (eventTarget) {
    const component = store.componentMap.get(eventTarget);
    if (!component) {
      return Promise.resolve();
    }
    const func = component[eventType];
    if (!func || typeof func !== 'function') {
      return Promise.resolve();
    }
    return func.call(component, eventParams);
  } else if (actionMap[eventType]) {
    return actionMap[eventType].call(null, store, { ...eventOption, ...eventParams });
  } else if (store[eventType]) {
    return store[eventType](eventParams);
  } else {
    logDumpedInfo(eventType, '');
    console.warn(`[runAction] Can't find eventType: ${eventType}`);
    return Promise.resolve();
  }
}

async function runActions(store, actions, options?) {
  const actionResults: any = [];
  options && options.presetData && actionResults.push(options.presetData);
  try {
    for (let actionData of actions) {
      try {
        const ctxBackup = actionData.ctx;
        actionData = replaceObjectTokenWithExpress(
          // 之前的actions返回值替换下次的, 后面的覆盖前面的
          actionResults.reduce((pre, next) => ({ ...pre, ...next }), {}),
        )({ ...actionData, ctx: null });
        actionData.ctx = ctxBackup;
      } catch (error) {
        console.warn(error);
      }

      const sync = actionData.async === false || actionData.async === 'false';
      let goWrong = false;
      let actRes: any = {};

      try {
        actRes = sync ? await executeAction(store, actionData) : executeAction(store, actionData);
      } catch (error) {
        // 保持原逻辑，后面抛错，阻止同数组的下一个action执行。
        goWrong = true;
        actRes = error;
      }

      const conditionActions = (actionData.conditionActions || [])
        .map(action => {
          const func = new Function('ctx', `return ${action.condition}`);

          if (!func(actRes)) {
            return;
          }

          return {
            ...action,
            conditionActions: [], // 只开放一层
          };
        })
        .filter(Boolean);

      await runActions(store, conditionActions, { presetData: actRes });

      if (!(actRes instanceof Error)) {
        actionResults.push(actRes);
      }

      if (goWrong) {
        throw actionData.eventType;
      }
    }
  } catch (e) {
    console.error('actions stoped cause by:', e);
  }
  return actionResults;
}

const runActionStore = store => (actions: any[] = []) => {
  const runActions$ = pipe(
    actions$ => actions$.filter(action => !action.eventHook),
    actions$ => runActions(store, actions$),
  );
  return Array.isArray(actions) ? runActions$(actions) : executeAction(store, actions);
};

declare module '../../stores/index' {
  export default interface LayoutStore {
    actionMap: Record<string, IRunTimeAction>;
  }
}

/**
 * handle common action module
 * @param config
 */
function ActionHandler(config) {
  return {
    name: 'ActionHandler',
    init: store => {
      store.actionMap = {};
      store.runAction = runActionStore(store);
      store.sendRequest = option => sendRequest(store, option);
    },
  };
}
export default ActionHandler;
