import { generateUid } from '@alife/intl-util';
import { observable } from 'mobx';

const INNER_KEY_REGEX = /^_\w{16}_$/;

const BUILDIN_KEY_REGEX = /^_[a-z|A-Z]+$/;

/**
 * 判断是否是引擎生成Key的匿名节点
 * @param data
 */
export const isAnonymousNode = data => data && INNER_KEY_REGEX.test(data.componentKey);
/**
 * 判断是否是引擎内置的全局节点
 * @param data
 */
export const isBuildinNode = data => data && BUILDIN_KEY_REGEX.test(data.componentKey);

/**
 * 判断是否是Dada监控的节点
 * @param data
 */
export const isDadaNode = data => data.uiType || data.type || data.pageType || data.componentKey;

/**
 * 统一收口节点名称生成
 * @param itemData
 */
export const generateComponentKey = () => `_${generateUid()}_`;

class DadaNode {
  @observable
  data: object;

  parentNode: object;

  componentKey: string;

  constructor(data, parentNode) {
    this.componentKey = generateComponentKey();
    this.data = data;
    this.parentNode = parentNode;
  }

  isAnonymous() {
    return isAnonymousNode(this.data);
  }
}

export default DadaNode;
