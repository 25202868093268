import React from 'react';
import { Upload, Icon } from '@alife/next';

export const Uploader = (props) => {
  const { onChange } = props;

  const onReaderLoad = (event) => {
    console.log(event.target.result);
    const obj = JSON.parse(event.target.result);
    onChange(obj);
  };
  const uploadData = (objects) => {
    const [first] = objects;
    const { originFileObj } = first;
    const reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(originFileObj);
  };
  return (
    <Upload
      // action="https://www.easy-mock.com/mock/5b713974309d0d7d107a74a3/alifd/upload"
      // beforeUpload={beforeUpload}
      onChange={uploadData}
      accept="json"
      // onSuccess={onSuccess}
    >
        <Icon className="clickable" type="upload"/>
    </Upload>
  );
};
