(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("Next"), require("DadaComponents"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "Next", "DadaComponents"], factory);
	else if(typeof exports === 'object')
		exports["DadaForm"] = factory(require("React"), require("ReactDOM"), require("Next"), require("DadaComponents"));
	else
		root["DadaForm"] = factory(root["React"], root["ReactDOM"], root["Next"], root["DadaComponents"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__48__, __WEBPACK_EXTERNAL_MODULE__83__, __WEBPACK_EXTERNAL_MODULE__488__) {
return 