let lazyLoadObserver: IntersectionObserver;

const elementMap = new Map();

type callbackType = (inView: boolean, observer?: IntersectionObserver) => void;

if ('IntersectionObserver' in window) {
  lazyLoadObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const target = entry.target;
        const callback = elementMap.get(target);
        callback(true, lazyLoadObserver);
        // lazyLoadObserver.unobserve(target);
      }
    });
  });
}

export const observeElement = (ele, callback: callbackType) => {
  if (!lazyLoadObserver) {
    callback(true);
    return;
  }
  lazyLoadObserver.observe(ele);
  elementMap.set(ele, callback);
};
