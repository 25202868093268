import request from '../tools/request';
const checkAndUpdateElement = (store, option) => {
  const { elementLoadMap = {} } = option;
  Object.keys(elementLoadMap).forEach(key => {
    const element = store.elementDataMap.get(key);
    if (!element) {
      return;
    }
    const elementLoadInfo = elementLoadMap[key];
    if (!elementLoadInfo || !elementLoadInfo.request) {
      return;
    }
    const { request: requestOption } = elementLoadInfo;
    if (elementLoadInfo.loadOnMount) {
      request(requestOption).then(res => {
        if (!res.success) {
          return;
        }
        store.changeElementData(res.data);
      });
    }
  });
};
/**
 * http://g-assets.daily.taobao.net/intl-dada/ae-landing-pages/888.88.0/dashboard.css
 */

function LazyLoadPlugin(option) {
  return {
    name: 'LazyLoad',
    init: store => {
      setTimeout(() => {
        checkAndUpdateElement(store, option);
      }, 100);
    },
  };
}
export default LazyLoadPlugin;
