import { Adapters } from '@alife/dada-template-core';
import { compose } from '@alife/intl-util';
import wrapTips from './tips';
import './index.scss';

export default compose(
  // Adapters.wrapMemo,
  Adapters.wrapLocale,
  Adapters.wrapErrorBoundary,
  Adapters.wrapStore,
  Adapters.wrapInView,
  wrapTips,
  Adapters.wrapLabel,
  Adapters.wrapDebug,
  Adapters.wrapRegister,
);
