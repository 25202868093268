import { TypeRequestOptions } from '@alife/intl-util/lib/interfaces';
import { IAction } from './action';
import LayoutStore from '../stores';

export * from './action';

export interface TypePluginConfig {
  type: string;
  [propName: string]: any;
}

export interface TypeLabelData {
  label: string;
  value: string;
}

export interface TypeSchema {
  success: boolean;
  status: number;
  redirectUrl?: string;
  error?: string;
  breadcrumb?: TypeLabelData[];
  pageName?: TypeLabelData;
  componentMap?: object;
  plugins?: TypePluginConfig[];
  request?: TypeRequestOptions;
  modules?: TypeElement[];
}

export type TypeItemData = {
  label?: string;
  labelPosition?: 'top' | 'left' | 'right' | 'bottom';
  tips?: string;
  uiType?: string;
  type?: string;
  className?: string;
  name: string;
  componentKey: string;
  loadingState?: string;
  value?: any;
  actions?: IAction[];
  eventType?: string;
  eventTarget?: string;
} & any;

export interface TypePlugin {
  name: string;

  [propName: string]: any;
}

export interface TypeElement {
  name?: string;
  componentKey?: string;
  type?: string;
  uiType?: string;
  elements?: TypeElement[];
  itemData?: TypeItemData;
}

export interface IToast {
  content: string;
  type?: string;
  duration?: number;
}

export type Store = LayoutStore;

export type DadaComponnet = React.Component<TypeElement> & {
  getSubmitValue: () => void;
};
