import { toJS } from 'mobx';
import sendRequest from './send-request';
import { get } from '@alife/intl-util';
import spreadOutValidateComps from '../../tools/spreadOutComps';

const explainObj = data => toJS(data, { recurseEverything: true });

const getParams = (store, itemData) => {
  const formData = store.getFormData();
  const formDataClone = explainObj(formData);
  return store.getSendParams(formDataClone, itemData?.request?.paramStringify);
};

const submitAction = (store, itemData): Promise<any> => {
  if (!itemData.request) {
    console.warn(`Can't find 'request' by itemData: ${itemData.name} ${itemData.uiType}`, itemData);
    return Promise.reject();
  } else {
    const requestData = explainObj(itemData.request);
    const storeRequestParams = getParams(store, itemData);

    let data: any = {};
    const validateCompNames = get(itemData, 'eventParams.validateCompNames', []);

    if (Array.isArray(validateCompNames) && validateCompNames.length > 0) {
      spreadOutValidateComps(store.elementDataObj, validateCompNames).forEach(comp => {
        const name = comp.name || comp.componentKey;

        if (typeof storeRequestParams[name] !== 'undefined') {
          data[name] = storeRequestParams[name];
        }
      });
    } else {
      data = storeRequestParams;
    }

    return sendRequest(store, {
      ...itemData,
      request: {
        ...requestData,
        data: { ...data, ...requestData.data },
      },
    });
  }
};

export const submit = (store, itemData) => {
  const validateCompNames = get(itemData, 'eventParams.validateCompNames', []);
  const autoScroll = get(itemData, 'autoScroll', true);
  let validateComps: any[] = [];

  if (Array.isArray(validateCompNames) && validateCompNames.length > 0) {
    validateComps = spreadOutValidateComps(store.elementDataObj, validateCompNames);
  }

  if ((store.triggered || !store.checkRequired(validateComps)) && store.scrollToError) {
    autoScroll && store.scrollToError();
    return Promise.reject();
  } else if (itemData.extData && itemData.extData.content) {
    const { title, content } = itemData.extData;
    return store
      .showMessage({
        needWrapper: false,
        content,
        title,
      })
      .then(() => submitAction(store, itemData));
  } else {
    return submitAction(store, itemData);
  }
};

export default submitAction;
