import React from 'react';
import { DebugBoundary } from '@alife/intl-util';

const isDebug = location.search.includes('dada_switch');

const wrapDebug = TargetComponent => props =>
  isDebug ? (
    <DebugBoundary {...props}>
      <TargetComponent {...props} />
    </DebugBoundary>
  ) : (
    <TargetComponent {...props} />
  );

export default wrapDebug;
