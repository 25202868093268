import { toJS } from 'mobx';
import { createComputedExpression, updateTargetElementsThunk } from './util';
import { IElementChangePlugin } from './element-reducer';

export type IElementDataReducerPlugin = IElementChangePlugin & {
  type: 'ElementDataReducer';
};

function ElementDataReducer(config: IElementDataReducerPlugin) {
  const { scripts: rules = [] } = config;
  const ruleCompares = new WeakMap();
  rules.forEach(rule => ruleCompares.set(rule, createComputedExpression(rule)));
  return {
    name: 'Reducer-beta',
    init: store => {
      store.pluginfyMethod(store, 'changeElementData');
    },
    changeElementData: async (store, next) => {
      const prevFormData = toJS(store.formData);
      await next();
      const { elementDataObj, formData } = store;
      const elementMap = toJS(elementDataObj);
      const currFormData = toJS(formData);
      const shouldUpdate = rule =>
        ruleCompares.get(rule) && ruleCompares.get(rule)(prevFormData, currFormData, elementDataObj);
      const updateTargetElements = updateTargetElementsThunk(store, elementMap);
      rules.filter(shouldUpdate).forEach(updateTargetElements);
    },
  };
}

export default ElementDataReducer;
