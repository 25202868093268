const spreadOutComps = (elementDataObj: any, validateCompName: string) => {
  const comp = elementDataObj[validateCompName];

  if (!comp) {
    return [];
  }

  const elements = comp.elements;

  if (Array.isArray(elements)) {
    let compArray: string[] = [];

    elements.map(element => {
      const name = element.name;
      const compArrayCache: string[] = spreadOutComps(elementDataObj, name);

      compArray = [...compArray, ...compArrayCache];
    });

    return compArray;
  }

  return [comp];
};

const spreadOutValidateComps = (elementDataObj: any, validateCompNames: string[]) => {
  let validateComps: any[] = [];

  validateCompNames.forEach((validateCompName: string) => {
    validateComps = [...validateComps, ...spreadOutComps(elementDataObj, validateCompName)];
  });

  return validateComps;
};

export default spreadOutValidateComps;
