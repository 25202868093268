import { Store } from 'src/interfaces';
import { IPluginMeta } from 'src/interfaces/plugin';

interface PluginManagerOpts {
  store: Store;
}

export interface IPlugin {
  pluginName: string;
  instance: IPluginMeta;
}

interface PluginOpts {
  name: string;
  instance: IPluginMeta;
}

export default class PluginManager {
  // private store: Store;
  private plugins: IPlugin[];

  constructor({ store }: PluginManagerOpts) {
    // this.store = store;
    this.plugins = [];
  }

  install = ({ name, instance }: PluginOpts): void => {
    this.plugins.push({
      pluginName: name,
      instance,
    });
  };

  get = (name: string): IPlugin[] => this.plugins.filter(({ pluginName }) => pluginName === name);

  getAllMeta = (): IPluginMeta[] => this.plugins.map(({ instance }) => instance);

  reset(options: PluginOpts[] = []) {
    this.destroyAll();
    options.forEach(option => {
      this.install(option);
    });
  }

  destroyAll() {
    const { plugins } = this;
    while (plugins.length) {
      const { instance } = plugins.pop() as IPlugin;
      const { destroy } = instance;
      if (typeof destroy === 'function') {
        destroy.apply(instance);
      }
    }
  }
}
