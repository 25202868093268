import { toJS } from 'mobx';
import { shouldUpdateElementThunk, updateTargetElementsThunk } from './util';

export interface IElementChangePlugin {
  type: 'elementChange';
  scripts: Array<{
    expression: string; // ==> boolean
    expressionParams: Record<string, any>;
    targetElements: Record<string, any>;
  }>;
}

function ElementReducer(config) {
  const { scripts: rules = [] } = config;
  return {
    name: 'Reducer',
    init: store => {
      store.pluginfyMethod(store, 'changeElementData');
    },
    changeElementData: async (store, next) => {
      const prevFormData = toJS(store.formData, { recurseEverything: true });
      const prevElementData = toJS(store.elementDataObj, { recurseEverything: true });
      await next();
      const elementData = store.elementDataObj;
      const elementMap = toJS(elementData);
      for (const rule of rules) {
        const shouldUpdate = shouldUpdateElementThunk(prevFormData, store.formData, prevElementData, elementMap)(rule);
        if (shouldUpdate) {
          const updateTargetElements = updateTargetElementsThunk(store, elementMap);
          updateTargetElements(rule);
        }
      }
    },
  };
}

export default ElementReducer;
