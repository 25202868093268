import { request, isMtop, isAdminGW, replaceObjectToken, createSandBox, isJSFunction } from '@alife/intl-util';
import Url from 'url';
import Tools from './index';
// import { format } from './format';

const whiteList = ['mocks.alibaba-inc.com'];

const { csrfToken } = window as any;

const callTransform = (func: any) => {
  if (typeof func === 'function') {
    return func;
  }

  if (isJSFunction(func)) {
    return createSandBox({})(func);
  }

  return func;
};

const dadaRequest = async options => {
  try {
    const { headers: optionHeaders = {}, url, delay, withTimeStamp } = options || ({} as any);
    const headers = {};
    const urlObject = Url.parse(url || '');
    const isWhiteList = urlObject && urlObject.hostname && whiteList.indexOf(urlObject.hostname) > -1;
    if (!isMtop(url) && !isAdminGW(url) && csrfToken && csrfToken.tokenName && !isWhiteList) {
      const tokenName = csrfToken.tokenName;
      headers[tokenName] = csrfToken.tokenValue;
    }
    await Tools.promiseTimeout(delay);
    if (withTimeStamp) {
      options.data = { _timestamp: Date.now(), ...options.data };
    }

    const finalOptions = {
      contentType: 'application/x-www-form-urlencoded;charset=utf-8',
      ...options,
      headers: {
        ...headers,
        ...optionHeaders,
      },
      withCredentials: true,
      crossOrigin: true,
    };

    const { requestFilter, responseFilter } = finalOptions;

    finalOptions.requestFilter = callTransform(requestFilter);
    finalOptions.responseFilter = callTransform(responseFilter);

    let res = await request(finalOptions);

    if (options.format) {
      res = replaceObjectToken(res)(options.format);
    }

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export default dadaRequest;
