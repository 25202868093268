import { ISubscribeProps } from '../interfaces/eventReaction';
import { Store } from 'src/interfaces';
import DadaEvent from './dada-event';
// import { injectable, inject } from './container';
// import { CONST_IOC } from '../constants/ioc';

class EventObservable {
  eventObs: Map<string, Map<string, ISubscribeProps[]>> = new Map();

  store: Store;

  constructor(store: Store) {
    this.store = store;
  }

  /**
   * bubble to parent node until window/store
   */
  bubble(e: DadaEvent) {
    // Todo find parent node and dispatch event
    if (e.currentTarget === this.store) {
      e.done = true;
      return;
    }
    e.setCurrentTarget('store');
    this.dispatch(e);
  }

  subscribe(name: string, eventType: string, options: ISubscribeProps = { actions: [] }) {
    if (!this.eventObs.has(name)) {
      this.eventObs.set(name, new Map());
    }
    const observers = this.eventObs.get(name) as Map<string, ISubscribeProps[]>;
    let observer = observers.get(eventType);
    if (!observer) {
      observer = [];
    }
    observer.push(options);
    observers.set(eventType, observer);
    return this.disposeSubscribe(name, eventType, options);
  }

  disposeSubscribe = (name: string, eventType: string, options: ISubscribeProps) => () => {
    if (!this.eventObs.has(name)) {
      return;
    }
    const observers = this.eventObs.get(name) as Map<string, ISubscribeProps[]>;
    const observer = observers.get(eventType);
    if (!observer) {
      return;
    }
    observer.splice(observer.indexOf(options), 1);
  };

  async dispatch(evt: DadaEvent) {
    const { eventType, currentTargetName } = evt;
    if (!this.eventObs.has(currentTargetName) || !this.eventObs.get(currentTargetName)!.get(eventType)) {
      this.bubble(evt);
      return;
    }
    const observers = this.eventObs.get(currentTargetName);
    const subscribes = observers!.get(eventType) as ISubscribeProps[];

    if (!subscribes) {
      return;
    }

    subscribes
      .sort(({ priority = 1000 }, { priority: nextPriority = 1000 }) => priority - nextPriority)
      .forEach(subscribe => {
        evt.addSubscribe(subscribe);
      });
    await this.runCallBacks(evt);
    this.runAction(evt);
  }

  runAction = (e: DadaEvent) => {
    const { actions, done, eventType } = e;
    if (actions && actions.length && done) {
      const validateActions = actions.filter(actionData => !actionData.eventHook || actionData.eventHook === eventType);
      this.store.runAction(
        validateActions.map(action => {
          const { actionType, actionTarget, actionParams, async = false } = action;
          return {
            eventType: actionType,
            eventTarget: actionTarget,
            eventParams: actionParams,
            async,
          };
        }),
      );
    }
  };

  runCallBacks = async (e: DadaEvent) => {
    try {
      await e.listener();
    } catch (err) {
      console.error('runCallBacks', err);
    } finally {
      this.bubble(e);
    }
  };
}

export default EventObservable;
