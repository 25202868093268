import { locale } from '../../i18n';

const commonText = locale.dada.common;

const showMessage = (store, data: any = {}) =>
  new Promise(resolve => {
    const { type = 'success', hasMask = true, value, content } = data;
    store.showMessage({
      ...data, // using option
      type,
      content: value || content || commonText.success,
      hasMask, // display mask as default
      afterClose: () => resolve({ store, data }),
    });
  });

export default showMessage;
