import React, { ReactNode, ComponentType } from 'react';
import { getCurry, get, Maybe } from '@alife/intl-util';

type ComponentMapType = Map<string, ComponentType>;
interface TItem {
  name: string;
  uiType: string;
  type?: string;
  className: string;
  [propName: string]: any;
}

export interface TypeComponentHandler {
  getComponentMap: (namespace: string) => ComponentMapType;
  renderComponent: (namespace: string) => (itemData: object, index: number) => ReactNode;
  registerComponentMap: (componentMapObj: object, namespace?: string) => void;
  resetAll: () => void;
}

const lowerCaseRegex = /[a-z]/;
const slotRegex = /__slot__\w+/;

export const EmptyContainer = props => {
  const uiType = get(props, 'itemData.type', props.type);
  return <div className={`empty-container ${uiType}`}>Can't find compoent by type {uiType}</div>;
};

export const EmptyComponent = props => {
  const uiType = get(props, 'itemData.uiType', props.uiType);
  return <div className={`empty-component ${uiType}`}>Can't find compoent by UI type {uiType}</div>;
};

const getClassNameByItemData = (itemData: TItem) => {
  const { uiType, type, className = '' } = itemData;
  return `${uiType || type} ${uiType ? 'element' : 'container'} ${className}`;
};

const checkAndGetUiType = itemData => {
  const { uiType } = itemData;
  if (!uiType) {
    return uiType;
  }
  const firstChar = uiType.charAt(0);
  if (lowerCaseRegex.test(firstChar)) {
    const newType = `${firstChar.toUpperCase()}${uiType.slice(1)}`;
    return newType;
  } else {
    return uiType;
  }
};

const getElementComponent = (localMap, itemData: any = {}) => {
  return Maybe.of(itemData)
    .map(checkAndGetUiType)
    .map(getCurry(localMap))
    .getOr(localMap.defaultComponent || EmptyComponent);
};

const getContainerComponent = (localMap: any = {}, itemData) => {
  return Maybe.of(itemData.type)
    .map(getCurry(localMap))
    .getOr(localMap.defaultContainer || EmptyContainer);
};

const getComponentByItemData = (itemData, componentMap) => {
  const component = itemData.uiType
    ? getElementComponent(componentMap, itemData)
    : getContainerComponent(componentMap, itemData);
  return component;
};

const getSlotData = (componentMap, itemData) => {
  const slotProps = {};
  Object.entries(itemData).forEach(([key, value]) => {
    if (!slotRegex.test(key)) {
      return;
    }
    if (Array.isArray(value)) {
      const propName = key.replace('__slot__', '');
      slotProps[propName] = (
        <span className={`slot-container slot-${propName}`}>{value.map(renderComponentThunk(componentMap))}</span>
      );
    } else {
      console.error('Invalidate Slot decription');
    }
  });
  return slotProps;
};

export const renderComponentThunk = (componentMap, onError = console.error) => (itemData: TItem, index) => {
  if (!itemData) {
    return <div style={{ display: 'none' }} />;
  }
  const TargetComponent = getComponentByItemData(itemData, componentMap);
  const mergedClassName = getClassNameByItemData(itemData);
  const slotProps = getSlotData(componentMap, itemData);
  return (
    <TargetComponent
      // {...itemData}
      {...slotProps}
      key={itemData.name}
      index={index}
      className={mergedClassName}
      onError={onError}
      itemData={itemData}
    />
  );
};
