import {
    computed
} from 'mobx';

function goldlogParams() {
    return {
        name: 'goldlogParams',
        init(store) {
            store.unfinishedFields = computed(() => {
                const formData = store.getFormData();
                return Object.keys(formData).filter(name => !formData[name]);
            });
        }
    };
}

export default goldlogParams;
