import requestFunc from '../tools/request';
import { pipe, replaceObjectTokenWithExpress } from '@alife/intl-util';
import { handleResponse } from './actions/send-request';
import { getExpression, getValueByPath } from '../tools/ref-params';

const patch = store =>
  pipe(
    (res$: any) => handleResponse(store, res$),
    (res$: any) => {
      if (res$ && !res$.type && Array.isArray(res$.data)) {
        res$.data.forEach(itemData => {
          store.changeElementDataWithoutHook(itemData);
        });
      }
      return res$;
    },
    // 支持key: value形式的initData数据
    (res$: any) => {
      if (res$ && !res$.type && Object.prototype.toString.call(res$.data) === '[object Object]') {
        const result = {};
        Object.entries(res$.data || {}).forEach(([key, value]) => {
          if (store.getElementData(key)) {
            result[key] = {
              value,
            };
          }
        });
        store.changeElementDataWithoutHookBatch(result);
      }
      return res$;
    },
  );

const shouldHotload = (hotload, elementDataObj) => {
  // 判断hotload包含条件表达式是否满足
  const shouldHotloadExpression = getExpression(hotload);
  const expressionMatched = getValueByPath(elementDataObj, shouldHotloadExpression);
  return expressionMatched;
};

function RemotePatch(config) {
  if (!config || !config.request) {
    console.error('PatchRemoteSchema, can not find "request" in config');
    return;
  }
  return {
    name: 'RequestPatch',
    init: store => {
      const { hotload, request, responseFilter, requestFilter } = config;
      const patch$ = patch(store);
      try {
        const { formData, elementDataObj } = store;
        store.patchRemoteSchema = params => {
          const requestParams = { ...request.data, ...params };
          const ctx = { store, formData, ...elementDataObj };
          const runtimeParams = replaceObjectTokenWithExpress(ctx)(requestParams);
          requestFunc({
            ...request,
            data: typeof requestFilter === 'function' ? requestFilter(runtimeParams) : runtimeParams,
          }).then(res => {
            patch$(typeof responseFilter === 'function' ? responseFilter(res) : res);
          });
        };
        if (hotload === true || shouldHotload(hotload, elementDataObj)) {
          store.patchRemoteSchema();
        }
      } catch (err) {
        console.warn(`Failed to load style by schema: ${store.schema}`);
        console.error(err);
      }
    },
  };
}

export default RemotePatch;
