export const EventType = {
  onClick: 'onClick',
  onChange: 'onChange',
  onFocus: 'onFocus',
  onBlur: 'onBlur',
  onExposure: 'onExposure',
  onDadaEvent: 'onDadaEvent',
  onComponentError: 'onComponentError',
  pageStay: 'pageStay',
};
