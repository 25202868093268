import React, { useState } from 'react';
import { observeElement } from './observer';
import { EmptyFunc } from '../../constants';
import classNames from 'classnames';

const defaultStyle = {
  minHeight: 32,
};

const isContainer = str => /Container$/.test(str);

const Container = subProps =>
  subProps.href ? <a {...subProps} ref={subProps.onRef} /> : <div {...subProps} ref={subProps.onRef} />;

export const useLazyRender = props => {
  const { lazyRender, wrapperStyle = defaultStyle, uiType, type } = props;
  if (!isContainer(uiType || type) && !lazyRender) {
    return { inited: true, onRef: EmptyFunc, wrapperStyle };
  }
  const [inited, setInited] = useState(false);
  const onRef = ele => {
    const isElement = ele instanceof Element;
    if (!isElement) return;
    observeElement(ele, (inView, observer) => {
      if (inView) {
        setInited(true);
        if (!observer) return;
        observer.unobserve(ele);
      }
    });
  };
  return { inited, onRef, wrapperStyle };
};

const getClassName = props => {
  const { clazzName, uiType, type, className, containerHref, label, labelPosition, visible, requiredMarkType } = props;
  const mergedClassName = classNames(
    `ui-item label-hoc parent-${name} clazzName-${clazzName} ${uiType || type} ${className} hoc-label-${labelPosition}`,
    {
      isComponent: uiType,
      isContainer: !uiType,
      'with-link': containerHref,
      'empty-label': !label,
      hide: !visible,
      [`container-label-required-${requiredMarkType}`]: requiredMarkType,
    },
  );
  return mergedClassName;
};

const wrapLazyRender = TargetComp => props => {
  const { inited, onRef, wrapperStyle } = useLazyRender(props);
  const className = getClassName(props);
  const { componentKey, containerHref, containerTarget } = props;
  return (
    <Container
      onRef={onRef}
      className={className}
      style={wrapperStyle}
      id={componentKey}
      href={containerHref}
      target={containerTarget}
    >
      {inited ? <TargetComp {...props} /> : null}
    </Container>
  );
};

export default wrapLazyRender;
