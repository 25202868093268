import { IOldDataAdaptor } from './interface';

type IBefore = {
  uiType: 'Message';
  label: string;
  style: string;
} & any;

const checkAndFixMessageData = (elements: IBefore[]) => {
  if (!elements || !Array.isArray(elements)) {
    return elements;
  }
  elements.forEach((itemData: any, index) => {
    // TODO update schema and warn user
    if (itemData.uiType === 'Message' && typeof itemData.style === 'string') {
      itemData.messageStyle = itemData.style;
      itemData.style = {};
      console.warn(`props 'style' of component 'Message', has been expired， please use 'messageStyle' insead `);
    }
    checkAndFixMessageData(itemData.elements);
  });

  return elements;
};

const fixMessageData: IOldDataAdaptor = schema => {
  const { modules } = schema;
  if (modules) {
    checkAndFixMessageData(modules);
  }

  return schema;
};

export default fixMessageData;
