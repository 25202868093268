import i18n from '@alife/mcms_dada_common';
import { getCurrentLanguage, getCookieLanguage, translate, get } from '@alife/intl-util';
import set from 'lodash/set';
import nextLocale from './next';

const locale = {
  dada: {
    common: {
      required: 'Required',
      search: 'Search',
      reset: 'Reset',
      total: 'Total',
      more: 'More',
      page: 'Page',
      error: 'Error',
      networkError: 'Network Error',
      systemError: 'System Error',
      selectedLimit: 'The maximum number has been reached.',
      days: 'days',
      success: 'Success',
      failed: 'Failed',
      validator: {
        email: 'EMail format error',
        postcode_zh: 'Postcode format error',
        mobile_zh: 'Mobile number format error',
        phone_zh: 'Phone format error',
      },
      copy: {
        success: 'Copy success',
      },
      optional: 'Optional',
    },
  },
};

const schemaLocaleMap = get(window, 'lzdCommonData.localeMap');
const schemaLocale = get(window, 'lzdCommonData.locale') || {};
const windowLocale = ((window as any).dada_common || {})[getCookieLanguage()];
const localeLanguage = i18n[getCurrentLanguage()];
Object.assign(locale, schemaLocale);
translate(locale, { ...localeLanguage, ...windowLocale }, i18n.appName);
if (schemaLocaleMap) {
  Object.keys(schemaLocaleMap).forEach(name => {
    set(locale, name, schemaLocaleMap[name]);
  });
}

export { locale, nextLocale };
