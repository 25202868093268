import { replaceObjectTokenWithExpress } from '../tools/regex';
import { TypeItemData } from '../interfaces';

interface MixedParams {
  eventParams: Record<string, any>;
}

type TypeMixedParams = TypeItemData | MixedParams;

export function flatEventParams(store, restOptions: TypeMixedParams) {
  let { ctx = {}, ...eventParams } = { ...restOptions };

  try {
    ctx = {
      store,
      formData: store.getFormData ? store.getFormData() : store.formData,
      ...store.elementDataObj,
      ...ctx,
    };
    eventParams = replaceObjectTokenWithExpress(ctx)(eventParams);

    // 替换后的evenParams flat到params层级，并保留eventParams
    const { eventParams: replacedEventParams, ...restParams } = eventParams;
    if (replacedEventParams) {
      eventParams = Object.assign(eventParams, replacedEventParams);
    } else {
      eventParams = restParams;
    }
  } catch (e) {
    console.error('replace Object Token failed:', eventParams, e);
  }

  return eventParams;
}
