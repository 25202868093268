import { get } from '@alife/intl-util';
import Tools from '../../tools/index';

// const hasHost = str => /^http/.test(str);

const getHrefWithSpm = (data: any = {}) => {
  try {
    const hrefStr = data.href || location.href;
    const ignoreSpm = document.querySelector('meta[name="data-spm"][data-spm-protocol="i"]');
    // spm ingore mark, see: https://log.alibaba-inc.com/track/help
    if (ignoreSpm) {
      return hrefStr;
    }
    const url = new URL(hrefStr, window.location.href);
    const spmTokens = get(window, 'goldlog.spm_ab', []);
    url.searchParams.append('spm', spmTokens.join('.'));
    return url.toString();
  } catch (err) {
    console.error(err);
    return data.href || location.href;
  }
};

const jump = (store, data: any = {}) =>
  Tools.promiseTimeout(data.duration || 500).then(() => {
    const href = getHrefWithSpm(data);
    if (data.target === '_blank') {
      window.open(href);
    } else {
      if (location.href === href) {
        location.reload();
      } else {
        location.href = href;
      }
    }
    return Promise.resolve();
  });
export default jump;
