import { updateTargetElementsThunk } from './util';

/**
 * {
 *  "type": "buttonElementChange",
 *  "scripts": [{
 *    "buttonName": "testButton",
 *    "targetElements": {
 *      "testDialog": {
 *        "visible": {true}
 *      }
 *    }
 *  }]
 * }
 * @param config
 */
function ButtonElementReducer(config) {
  const { scripts: rules = [] } = config;
  const ruleMap = rules.reduce(
    (map, rule) => ({
      ...map,
      [rule.buttonName]: rule,
    }),
    {},
  );
  return {
    name: 'ButtonElementReducer',
    init: store => {
      store.pluginfyMethod(store, 'onClick');
    },
    onClick: (store, next, option) => {
      const { args } = option;
      const [, itemData] = args;
      if (!itemData || !itemData.name) {
        return next();
      }
      const rule = ruleMap[itemData.name];
      if (rule) {
        const { elementDataObj } = store;
        elementDataObj.currentElement = itemData;
        updateTargetElementsThunk(store, elementDataObj)(rule);
      }
      next();
    },
  };
}

export default ButtonElementReducer;
