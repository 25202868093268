import { IAction } from '../../interfaces/action';

export interface IHookAction extends IAction {
  eventHook: string;
}

export type THookMap = Map<string, IHookAction[]>;

export const getHookMap = actions => {
  const hookMap: THookMap = new Map();
  if (!Array.isArray(actions)) {
    return hookMap;
  }
  actions
    .filter(action => action.eventHook)
    .forEach(action => {
      const { eventHook } = action;
      const hookActions = hookMap.get(eventHook) || [];
      hookActions.push(action);
      hookMap.set(eventHook, hookActions);
    });
  return hookMap;
};
