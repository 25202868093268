import { arrToObj } from '@alife/intl-util';
import { observable, toJS } from 'mobx';
import { MergeStrategy } from '../constants';
import Tools from './index';
import { TypeSchema } from 'src/interfaces';
import { generateComponentKey, isBuildinNode, isDadaNode } from '../tools/node';

type CustomMergeFunc = ({
  newValue,
  currValue,
  startComponentKey,
  mergeNodeRecusive,
}: {
  newValue: object;
  currValue: object;
  startComponentKey: string;
  mergeNodeRecusive: (newData, startComponentKey) => object;
}) => object;

class MapNode {
  @observable root;
  @observable nodeMap: Map<string, any>;
  autoNameMap: object;
  customMergeMap: Map<string, (CustomMergeFunc) => object>;
  ignoreList: object;
  defaultNode: object;
  mergeStrategy: MergeStrategy;
  mergeScope: any;
  MergeStrategyMap;

  constructor({
    autoNameMap = {},
    customMergeMap = new Map(),
    ignoreList = {},
    defaultNode = {},
    mergeStrategy = MergeStrategy.remoteFirst,
  }) {
    this.autoNameMap = autoNameMap;
    this.customMergeMap = customMergeMap;
    this.defaultNode = defaultNode;
    this.ignoreList = ignoreList;
    this.mergeStrategy = mergeStrategy;
    this.MergeStrategyMap = {};
    this.MergeStrategyMap[MergeStrategy.remoteFirst] = this.mergedNodePropsRemote;
    this.MergeStrategyMap[MergeStrategy.localFirst] = this.mergedNodePropsLocal;
    this.root = this.defaultNode;
    this.nodeMap = new Map([['root', this.root]]);
  }

  getNode = (componentKey: string, subNodeId?: string) => {
    const node = this.nodeMap.get(componentKey);
    if (Tools.isArray(node) && subNodeId) {
      return node.find(subNode => subNode.subNodeId === subNodeId);
    } else {
      return node;
    }
  };

  deleteNode = (componentKey: string, subNodeId?: string) => {
    const node = this.nodeMap.get(componentKey);
    node.componentKey = undefined;
    if (Tools.isArray(node) && subNodeId) {
      const subIndex = node.findIndex(subNode => subNode.subNodeId === subNodeId);
      node.splice(subIndex, 1);
    } else {
      this.nodeMap.delete(componentKey);
    }
  };

  addNode = (componentKey: string, data) => {
    const node = this.nodeMap.get(componentKey);
    const realKey = node ? generateComponentKey() : componentKey;
    data.componentKey = realKey;
    data.name = realKey; // 确保事件更新的索引正确
    return this.nodeMap.set(realKey, data).get(realKey);
  };

  updateNode = (componentKey: string, newData) => {
    const existNode = this.nodeMap.get(componentKey);
    return existNode ? Object.assign(existNode, { ...newData, componentKey }) : null;
  };

  reset = (schema: TypeSchema = this.defaultNode as TypeSchema) => {
    this.nodeMap.forEach((value, key) => {
      // keep inside global value
      if (isBuildinNode(value)) return;
      this.nodeMap.delete(key);
    });
    this.nodeMap.set('root', this.root);
    if (schema) {
      const cacheStrategy = this.mergeStrategy;
      this.mergeStrategy = MergeStrategy.remoteFirst;
      this.merge(schema);
      this.mergeStrategy = cacheStrategy;
    }
  };

  merge = rootNode => {
    return this.mergeNode({ ...rootNode, componentKey: 'root' }, 'root');
  };

  mergeNode(newData, startComponentKey: string, recursive = true) {
    if (recursive === false) return this.updateNode(startComponentKey || newData.name, newData);
    this.mergeScope = new Map();
    const res = this.mergeNodeRecusive(newData, startComponentKey);
    return res;
  }

  private mergeNodeRecusive = (newData, startComponentKey: string, depth = 0) => {
    if (!newData || typeof newData !== 'object') return newData;

    newData.componentKey =
      newData.componentKey || newData.name || this.autoNameMap[newData.componentKey] || generateComponentKey();
    let currData = this.nodeMap.get(newData.componentKey); // create mobx object
    const duplicate = this.mergeScope.get(newData.componentKey);
    const isComponentUpdate =
      startComponentKey === newData.componentKey ||
      (!duplicate && currData && currData.componentKey === newData.componentKey);
    if (!isDadaNode(newData)) {
      return currData;
    } else if (!currData) {
      currData = this.nodeMap.set(newData.componentKey, {}).get(newData.componentKey);
      this.mergedNodeProps(newData, currData, startComponentKey, depth);
    } else if (isComponentUpdate) {
      currData = this.mergedNodeProps(newData, currData, startComponentKey, depth);
    } else {
      // duplicate name
      let index = 0;
      let duplicateKey = '';
      do {
        index++;
        const token = `${index}`.padStart(3, '0');
        duplicateKey = `${newData.name}-${token}`;
      } while (this.nodeMap.get(duplicateKey) && index < 100);
      newData.componentKey = duplicateKey;
      currData = this.nodeMap.set(duplicateKey, {}).get(duplicateKey);
      this.mergedNodeProps(newData, currData, startComponentKey, depth);
    }
    this.mergeScope.set(newData.componentKey, true);
    return currData;
  };

  mergedNodeProps = (newData, currData, startNode, depth) => {
    const func = this.MergeStrategyMap[this.mergeStrategy];
    return func.call(this, newData, currData, startNode, depth);
  };

  private mergedNodePropsRemote = (newData, currData, startComponentKey, depth) => {
    newData = toJS(newData, { recurseEverything: true });
    Object.entries(newData).forEach(([key, value]: [string, any]) => {
      const customFunc = this.customMergeMap.get(key);
      if (this.ignoreList[key]) {
        return;
      } else if (customFunc) {
        currData[key] = customFunc({
          newValue: value,
          currValue: currData[key],
          currData,
          startComponentKey,
          mergeNodeRecusive: this.mergeNodeRecusive,
          depth,
        });
      } else if (Tools.isArray(value)) {
        currData[key] =
          key === 'elements' || key === 'modules'
            ? value.map(itemData => this.mergeNodeRecusive(itemData, startComponentKey, depth + 1))
            : value;
      } else if (typeof value === 'object' && value && Object.keys(value).length > 0) {
        const exist = currData[key] || {};
        if (exist.componentKey) {
          value.componentKey = exist.componentKey;
        }
        currData[key] =
          value.name || (value.elements && value.name)
            ? this.mergeNodeRecusive(value, startComponentKey, depth + 1)
            : (currData[key] = { ...currData[key], ...value });
      } else {
        currData[key] = value;
      }
    });
    return currData;
  };

  private mergedNodePropsLocal = (newData, currData, startComponentKey, depth) => {
    if (!newData) {
      return currData;
    }
    const { elements, modules, page, ...restOpt } = newData;
    const isModule = currData.hasOwnProperty('modules');
    const childElementsName = isModule ? 'modules' : 'elements';
    let children = [];
    if (isModule) {
      const currentDataValueObj = arrToObj(newData[childElementsName] || [], 'name');
      // need keep exist module
      children = (currData[childElementsName] || []).map(existNode => {
        const newItemData = currentDataValueObj[existNode.componentKey];
        return newItemData ? this.mergeNodeRecusive(newItemData, startComponentKey, depth + 1) : existNode;
      });
    } else {
      // need remove exist element
      children = (newData[childElementsName] || []).map(newItemData => {
        return this.mergeNodeRecusive(newItemData, startComponentKey, depth + 1);
      });
    }

    const mergeField =
      currData.hasOwnProperty(childElementsName) || newData.hasOwnProperty(childElementsName)
        ? { [childElementsName]: children }
        : {};

    if (page) {
      restOpt.page = { ...toJS(currData.page), ...page };
    }
    Object.assign(currData, restOpt, mergeField);

    return currData;
  };
}

export default MapNode;
