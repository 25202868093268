import checkAndFixMessageData from './history-adaptors/message-data';
import fixDialogData from './history-adaptors/dialog-data';

function HistoryDataConvert() {
  return {
    name: 'HistoryDataConvert',
    rules: [fixDialogData, checkAndFixMessageData],
    init(store) {
      store.pluginfyMethod(store, 'setData');
      store.pluginfyMethod(store, 'mergeSchema');
      const newSchema = this.convertData(store.schema);
      store.mergeSchema(newSchema);
    },
    setData(store, next, option: any = {}) {
      const { args } = option;
      const [schema] = args;
      this.convertData(schema);
      next();
    },
    mergeSchema(store, next, option: any = {}) {
      const { args } = option;
      const [schema] = args;
      this.convertData(schema);
      next();
    },
    /**
     * 通过rules 迭代执行schema转换
     * @returns {(schema) => any}
     */
    convertData(schema) {
      return this.rules.reduceRight((prev, next) => next(prev), schema);
    },
  };
}

export default HistoryDataConvert;
