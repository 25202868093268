import { LoadingState } from '../../constants';
export const reload = (props, params: any = {}) => {
  const { TargetComponent, loadingState = LoadingState.none, layoutStore, itemData } = props;
  const { hocOptions = {} } = TargetComponent;
  const disableHotload = hocOptions && hocOptions.hotload === false;
  if (!disableHotload && loadingState !== LoadingState.runing) {
    const insideParams = (hocOptions.getReloadParams && hocOptions.getReloadParams(props)) || {};
    params = { ...params.eventParams, ...insideParams };
    return layoutStore.reloadComponent(itemData, params);
  } else {
    return Promise.resolve();
  }
};
