import { wrapCatch } from '@alife/intl-util';

export const getDadaExpression = expression => {
  if (!expression) {
    return expression;
  }
  if (expression.type === 'JSExpression') {
    const func = new Function('elementData', 'self', `return ${expression.value}`);
    return wrapCatch((...args) => func.apply(null, args), expression.fallback);
  } else {
    return undefined;
  }
};
