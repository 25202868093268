import { Store } from 'src/interfaces';
import { compose } from '@alife/intl-util';

export type IAdapterMeta = (TargetComponent: React.ComponentClass) => React.ComponentClass;

interface IAdaptersManagerOpts {
  store: Store;
  adapters: IAdapterMeta;
}

export default class PluginManager {
  // private store: Store;
  private adapters: IAdapterMeta;

  constructor({ store, adapters = cmp => cmp }: IAdaptersManagerOpts) {
    // this.store = store;
    this.adapters = adapters;
  }

  install = (instance: IAdapterMeta): void => {
    this.adapters = compose(this.adapters, instance);
  };

  get = (component: React.ComponentClass): React.ComponentClass => this.adapters(component);
}
