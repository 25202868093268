import goldlog from '../../tools/goldlog';
export const logDumpedInfo = (eventType, recommandedEventType, demo?: object) => {
  console.error(`eventType "${eventType}" has been dumped, please using "${recommandedEventType}" instead`);
  console.error(`事件 "${eventType}" 已经废弃, 请使用 "${recommandedEventType}" 替代`);
  goldlog(
    {
      key: '/dada.error.action',
      data: {
        eventType,
        recommandedEventType,
        url: window.location.href,
      },
    },
    {},
  );
  if (demo) {
    console.warn(demo);
  }
};
