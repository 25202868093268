export interface IConfirmHookPlugin {
  type: 'confirmHook';
  rules: Array<{
    name: string;
    title?: string;
    content?: string;
  }>;
}

/**
 * {
 *   type:'confirmHook',
 *   rules:[
 *     {
 *       name: "switch",
 *       title: "确认切换吗？"
 *     }
 *   ]
 * }
 * @param config
 * @constructor
 */
function ConfirmHook(config) {
  const { rules = [] } = config;
  return {
    name: 'ConfirmHook',
    init: store => {
      store.pluginfyMethod(store, 'changeElementData');
    },
    changeElementData: async (store, next, option) => {
      const { args = [] } = option;
      const [item = {}, name] = args;
      const { value } = item;
      if (!name) {
        return;
      }
      const preValue = store.getElementData(name).value;
      const rule = rules.find(ruleOpt => ruleOpt.name === name);
      try {
        // tslint:disable-next-line:no-unused-expression
        !!rule &&
          value !== undefined &&
          JSON.stringify(value) !== JSON.stringify(preValue) &&
          (await store.showConfirm(rule));
        return await next();
      } catch (e) {
        throw new Error('UserCancel');
      }
    },
  };
}
export default ConfirmHook;
