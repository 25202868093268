export interface ValidateMessage {
  content: string;
  type: ValidateType;
  local: boolean;
}

export const enum ValidateType {
  error = 'error',
  warning = 'warning',
}

/**
 * validate 计算属性，由于会被强制转化成对象，不使用 get 属性
 */
export class ValidateInfo {
  messages: ValidateMessage[];
  type: ValidateType;
  elementId: string;
  label: string;
  name: string;
  message: string = '';
  item: any;
  failedCount: number = 0;
  errorCount: number;
  localErrorCount: number = 0;
  static from(item) {
    return new ValidateInfo(item);
  }
  constructor(item) {
    this.elementId = item.componentKey; // 通用协议，非dada引擎，elementId和componentKey不一致
    this.label = item.label;
    this.item = item;
    this.name = item.componentKey;
    this.messages = [];
  }

  addMessage(data: ValidateMessage) {
    this.messages.push(data);
  }

  updateState() {
    const errorList = this.messages.filter(msg => msg.type === ValidateType.error);
    this.errorCount = errorList.length;
    const firstError = errorList[0];
    this.message = firstError ? firstError.content : '';
  }

  addLocalErrorMessage(content: string) {
    const remoteMessages = this.messages.filter(msg => !msg.local);
    if (content) {
      this.localErrorCount = 1;
      remoteMessages.unshift({
        content,
        type: ValidateType.error,
        local: true,
      });
      this.failedCount++;
    }
    else {
      this.localErrorCount = 0;
    }
    this.messages = remoteMessages;
    this.updateState();
  }

  addRemoteMessages(remoteMessages) {
    if (!remoteMessages) {
      return;
    }
    const localMessages = this.messages.filter(msg => msg.local);
    let hasError = false;
    remoteMessages.forEach(message => {
      localMessages.push(message);
      hasError = hasError || message.type === ValidateType.error;
    });
    this.failedCount += hasError ? 1 : 0;
    this.messages = localMessages;
    this.updateState();
  }
}
